define('mx/models/atk-recipe-ingredient', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /** Attributes */
    // Currently unknown; appears to be null on all recipes provided.
    measurement: (0, _attr.default)('string'),
    // E.g. 'minced' or 'trimmed'.
    postText: (0, _attr.default)('string'),
    // E.g. 'ounces ' or 'pounds '.
    preText: (0, _attr.default)('string'),
    // E.g. '1 1/2' or '2'.
    qty: (0, _attr.default)('string'),
    /** Associations */
    ingredient: (0, _relationships.belongsTo)('atk-ingredient')
  });
});