define('mx/components/past-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sharePrice: null,
    specialsPrice: null,
    didInsertElement: function didInsertElement() {
      try {
        if (!this.get('share.legacy')) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = this.get('share').get('transactions').toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var t = _step.value;

              if (t.get('status') === 'paid' && t.get('type') === 'share') {
                this.set('sharePrice', this.getPrettyPrice(t.get('amount')));
              } else if (t.get('type') === 'specials') {
                if (t.get('status') === 'pending') {
                  this.set('specialsPrice', 'TBD');
                } else if (t.get('status') === 'paid') {
                  this.set('specialsPrice', this.getPrettyPrice(t.get('amount')));
                }
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        } else {
          var legacy = this.get('share.legacy');
          if (legacy.subscription_billing_details) {
            var sub_details = legacy.subscription_billing_details;
            if (sub_details.total) {
              this.set('sharePrice', this.getPrettyPrice(sub_details.total));
            }
          }
          if (legacy.addons_billing_details) {
            var addon_details = legacy.addons_billing_details;
            console.log(addon_details);
            if (addon_details.total) {
              this.set('specialsPrice', this.getPrettyPrice(addon_details.total));
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    getPrettyPrice: function getPrettyPrice(amount) {
      try {
        if (amount > 0) {
          return (parseInt(amount) / 100).toFixed(2);
        } else {
          return 0;
        }
      } catch (err) {
        console.log(err);
      }
    }
  });
});