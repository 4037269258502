define('mx/models/v2-cut-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Attributes
    name: _emberData.default.attr('string'),

    // Associations
    cutCategories: _emberData.default.hasMany('v2-cut-category'),
    shareType: _emberData.default.belongsTo('v2-share-type')
  });
});