define('mx/components/next-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  var $ = Ember.$;
  exports.default = Ember.Component.extend({
    waldenPrice: Ember.inject.service('waldenPrice'),
    didInsertElement: function didInsertElement() {
      var self = this;
      $('#deliveryDateModal').on('show.bs.modal', function (e) {
        self.set('selectedDate', null);
      });
    },

    activeCartAddons: Ember.computed('share.addons', function () {
      var _this = this;

      // Get addons where quantity > 0
      try {
        var addons = this.get('share.addons').filter(function (addon) {
          _newArrowCheck(this, _this);

          return addon.get('share_addon.quantity') !== 0;
        }.bind(this));
        return addons;
      } catch (err) {
        return [];
      }
    }),
    sharePrice: Ember.computed(function () {
      var waldenPrice = Ember.get(this, 'waldenPrice');
      var newShareConfig = Ember.get(this, 'newShareConfig');
      return waldenPrice.getTotalSharePrice(newShareConfig, true);
    }),
    selectedDate: null,
    actions: {
      viewPastShares: function viewPastShares() {
        this.sendAction('viewPastShares');
      },
      setDeliveryDate: function setDeliveryDate(date) {
        if (date) {
          this.set('selectedDate', date);
        }
      },
      changeDeliveryDate: function changeDeliveryDate() {
        var date = parseInt(this.get('selectedDate'));
        if (!isNaN(date)) {
          this.sendAction('changeDeliveryDate', date);
        }
      },
      skipShare: function skipShare() {
        this.sendAction('skipShare');
      }
    },
    getAddonPrice: function getAddonPrice(addon) {
      var quantity = addon.get('share_addon').get('quantity');
      var price = addon.get('price');
      var dm = addon.get('discount_map');

      var discount = 0;
      if (dm) {
        if (typeof dm === 'string') {
          try {
            dm = JSON.parse(dm.replace(/\s+/g, ''));
            discount = dm[quantity];
          } catch (err) {}
        }
      }

      return quantity * price - discount;
    }
  });
});