define('mx/v2/components/mx/tab-manager/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The identifier of the currently selected tab.
     *
     * @type {string}
     * @default null
     * @memberof Components/Mx/TabManager
     */
    selectedTabId: null
  });
});