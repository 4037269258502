define('mx/v2/constants/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Application constants.
   *
   * @module constants
   */

  // The MX phone number.
  var MX_PHONE_NUMBER = exports.MX_PHONE_NUMBER = '978.362.8642';
  // The Butcher Shop phone number.
  var BUTCHER_PHONE_NUMBER = exports.BUTCHER_PHONE_NUMBER = '857.277.0773';
  // The ATK content credit display.
  var ATK_RECIPE_CREDIT = exports.ATK_RECIPE_CREDIT = "Cook's Illustrated";
  // The S3 bucket containing statics assets, typically images and JSON files.
  var S3_STATIC_ASSETS_BUCKET = exports.S3_STATIC_ASSETS_BUCKET = 'walden-static-assets';
  // The bacon and eggs promotional text for sign-up.
  var BACON_AND_EGGS_PROMO_TEXT = exports.BACON_AND_EGGS_PROMO_TEXT = 'FREE BACON and EGGS in your first share!';
  var BREAKFAST_SAUSAGE_PROMO_TEXT = exports.BREAKFAST_SAUSAGE_PROMO_TEXT = 'FREE BREAKFAST SAUSAGE in your first share!';
});