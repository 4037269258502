define('mx/models/v2-share-size', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Attributes
    display: _emberData.default.attr('string'),
    grind: _emberData.default.attr('number'),
    displayOrder: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    weightLower: _emberData.default.attr('number'),
    weightUpper: _emberData.default.attr('number'),
    // Associations
    shareType: _emberData.default.belongsTo('v2-share-type')
  });
});