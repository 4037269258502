define('mx/models/atk-recipe', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'mx/v2/util/cloudinary'], function (exports, _model, _attr, _relationships, _cloudinary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /** Attributes */
    // Description of the recipe.
    description: (0, _attr.default)('string'),
    // URL for recipe image.
    photo: (0, _attr.default)('string'),
    // Used to derive the protein groupings, e.g. 'Beef'.
    proteinName: (0, _attr.default)('string'),
    // Set of responsive cloudinary images.
    responsivePhotos: Ember.computed('photo', function () {
      return (0, _cloudinary.makeCloudinaryUrisForType)('recipe', Ember.get(this, 'photo'));
    }),
    // E.g. '5372-beef-pot-pie'.
    slug: (0, _attr.default)('string'),
    // E.g. 'Beef Pot Pie'.
    title: (0, _attr.default)('string'),
    // HTML string, e.g. '<p>Beef pot pie is...</p>'.
    whyThisWorks: (0, _attr.default)('string'),
    // Servings, e.g. 'Serves 6 to 8'.
    yields: (0, _attr.default)('string'), // Servings.
    /** Associations */
    ingredientGroups: (0, _relationships.hasMany)('atk-recipe-ingredient-group'),
    instructions: (0, _relationships.hasMany)('atk-recipe-instruction'),
    tags: (0, _relationships.hasMany)('atk-recipe-tag')
  });
});