define('mx/v2/components/sign-up-review/share/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The V2 Share Config model to summarize.
     *
     * @type {Models/V2ShareConfig}
     * @default null
     * @memberof Components/SignUpReview/Share
     */
    shareConfig: null,
    /**
     * Promotional text to display above the share price.
     *
     * @type {string}
     * @default null
     * @memberof Components/SignUpReview/Share
     */
    promoText: null
  });
});