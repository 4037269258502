define('mx/models/addon-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    display: _emberData.default.attr('string'),
    display_order: _emberData.default.attr('number'),
    active: _emberData.default.attr('boolean'),
    group_addons: _emberData.default.hasMany('addon', { async: false })
  });
});