define('mx/components/mx/cut-group-proxy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * When the component is updated, make the necessary
     * changes to inclusions and exclusions to remove
     * redundancies
     *
     * @function lifecycle:didUpdate
     * @memberof Components/MX/CutGroupProxy
     */
    didUpdate: function didUpdate() {
      var inclusions = Ember.get(this, 'targetInclude');
      var exclusions = Ember.get(this, 'targetExclude');
      var shareSize = Ember.get(this, 'shareSize');
      // Only remove items from inclusions and exclusions if the cutGroups have meatTypes
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Ember.get(this, 'models').toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var model = _step.value;

          if (Ember.get(model, 'displaySection') === 0) {
            var meatTypeExcluded = this._meatTypeExcluded(model);
            if (!meatTypeExcluded) {
              var includedInstance = inclusions.findBy('id', Ember.get(model, 'id'));
              if (includedInstance) {
                inclusions.removeObject(includedInstance);
              }
            } else {
              var excludedInstance = exclusions.findBy('id', Ember.get(model, 'id'));
              if (excludedInstance) {
                exclusions.removeObject(excludedInstance);
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },

    /**
     *  Array of objects each wrapping a
     *  'models' object, introducing a 'selected'
     *  boolean array (selected-proxy-items)
     *
     *  @computed
     *  @type {Array.<Ember.Object|Object>}
     *  @memberof Components/MX/CutGroupProxy
     */
    items: Ember.computed('meatTypes.[]', 'shareSize', function () {
      return this._buildItemSet();
    }),
    /**
     *  Check if a groups meatType has been excluded
     *
     *  @function _meatTypeExcluded
     *  @param {Model/CutGroup} group - the cutGroup model instance
     *  @returns {boolean} has the meatType for this group been excluded
     *  @memberof Components/MX/CutGroupProxy
     */
    _meatTypeExcluded: function _meatTypeExcluded(group) {
      var meatTypes = Ember.get(this, 'meatTypes');
      var excluded = meatTypes.findBy('id', Ember.get(group, 'meatType.id'));
      return excluded ? true : false;
    },

    /**
     *  Set the initial selected values of the
     *  item set.
     *
     *  @function _buildItemSet
     *  @returns {Array.<Ember.Object|Object>} The built item set
     *  @memberof Components/MX/CutGroupProxy
     */
    _buildItemSet: function _buildItemSet() {
      var items = [];
      // Get the shareConfig cut group inclusions and exclusions
      var inclusions = Ember.get(this, 'targetInclude');
      var exclusions = Ember.get(this, 'targetExclude');
      var shareSize = Ember.get(this, 'shareSize');

      // Remove any unnecessary inclusions and exclusions
      // If a meatType is included, all inclusions of that type can be removed
      // If a meatType is excluded, all exclusions of that type can be removed
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = Ember.get(this, 'models').filterBy('active', true).toArray()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var model = _step2.value;

          var selected = false;
          var meatTypeExcluded = this._meatTypeExcluded(model);
          if (Ember.get(model, 'displaySection') === 0) {
            selected = !meatTypeExcluded;
          }

          // After initial checks, if a cutGroup item exists in inclusions, it
          // will always be selected, and always deselected for exclusions
          if (inclusions.findBy('id', Ember.get(model, 'id'))) {
            selected = true;
          } else if (exclusions.findBy('id', Ember.get(model, 'id'))) {
            selected = false;
          }
          if (Ember.get(model, 'isRoast') && Ember.get(shareSize, 'weightLower') < 10) {
            var roastInclusion = inclusions.findBy('id', Ember.get(model, 'id'));
            if (roastInclusion) inclusions.removeObject(roastInclusion);
            selected = false;
            continue;
          }
          // Save the cutGroup and its selected state, and add to the item set
          items.pushObject(Ember.Object.create({
            model: model,
            selected: selected
          }));
        }

        // Return the calculated item set
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return items;
    },

    actions: {
      /** Update a cutGroup's selected value, as well as its place in
       * the shareConfigs inclusion/exclusion arrays, if necessary
       *
       * @function action:updateCutSelection
       * @param {Models/CutGroup} group - the cutGroup model instance
       * @param {boolean} selected - the proxy item's selected state
       * @memberof Components/MX/CutGroupProxy
       */
      updateCutSelection: function updateCutSelection(group, selected) {
        var groupHasMeatType = Ember.get(group, 'displaySection') === 0;
        var inclusions = Ember.get(this, 'targetInclude');
        var exclusions = Ember.get(this, 'targetExclude');

        var meatTypeExcluded = false;
        if (groupHasMeatType) {
          meatTypeExcluded = this._meatTypeExcluded(group);

          // If the item is selected and has a meatType, remove any exclusion references to it
          // If the item's meatType is excluded, and the item is selected, add
          // it to the shareConfig.cutGroupInclusions array. If not, attempt to
          // remove the item from the inclusions array. If the item is not selected,
          // do the same for shareConfig.cutGroupExclusions.
          if (selected) {
            exclusions.removeObject(group);
            if (meatTypeExcluded) {
              inclusions.pushObject(group);
            } else {
              inclusions.removeObject(group);
            }
          } else {
            inclusions.removeObject(group);
            if (meatTypeExcluded) {
              exclusions.removeObject(group);
            } else {
              exclusions.pushObject(group);
            }
          }
        } else {
          // If there is no meatType for this item, manage its place
          // exclusively in the shareConfig.cutGroupInclusions array
          if (selected) {
            inclusions.pushObject(group);
          } else {
            inclusions.removeObject(group);
          }
        }

        // Find this group in the item set, and set its selected value
        var itemForGroup = Ember.get(this, 'items').findBy('id', Ember.get(group, 'id'));
        if (itemForGroup) Ember.set(itemForGroup, 'selected', selected);
      }
    }
  });
});