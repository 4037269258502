define('mx/v2/components/mx/x-step/header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Whether or not to display an edit button alongside the header title.
     *
     * @type {boolean}
     * @default true
     * @memberof Components/Mx/XStep/Header
     */
    allowEdit: true,
    /**
     * Closure to invoke when the edit button is clicked.
     *
     * @type {function}
     * @default null
     * @memberof Components/Mx/XStep/Header
     */
    onClickEdit: null,
    /**
     * The title to display within the header.
     *
     * @type {string}
     * @default null
     * @memberof Components/Mx/XStep/Header
     */
    title: null
  });
});