define('mx/helpers/mult', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mult = mult;
  function mult(params /*, hash*/) {
    return (params[0] * params[1] / 100).toFixed(2);
  }

  exports.default = Ember.Helper.helper(mult);
});