define('mx/v2/components/atk-content/group/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The item category of the content group to display.
     * This essentially determines whether or not the group should display
     * recipes, or cut guides.
     *
     * @type {AtkContentCategory}
     * @default null
     * @memberof Components/AtkContent/Group
     */
    category: null,
    /**
     * The atk content group.
     *
     * @type {AtkContentGroup}
     * @default null
     * @memberof Components/AtkContent/Group
     */
    group: null,
    /**
     * Closure to invoke when a guide is clicked.
     *
     * @type {Function}
     * @default null
     * @memberof Components/AtkContent/Group
     */
    onClickGuide: null,
    /**
     * Closure to invoke when a recipe is clicked.
     *
     * @type {Function}
     * @default null
     * @memberof Components/AtkContent/Group
     */
    onClickRecipe: null,
    /**
     * Whether or not to show recipes.
     *
     * @function showRecipes
     * @returns {Boolean} If true, show recipes.
     * @memberof Components/AtkContent/Group
     */
    shouldShowRecipes: Ember.computed('category', 'group', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'category', 'group'),
          category = _EmberGetProperties.category,
          group = _EmberGetProperties.group;

      return Ember.get(category, 'type') === 'Recipes' && Ember.get(group, 'recipes.length');
    }),
    /**
     * Whether or not to show cut guides.
     *
     * @function showGuides
     * @returns {Boolean} If true, show cut guides.
     * @memberof Components/AtkContent/Group
     */
    shouldShowGuides: Ember.computed('category', 'group', function () {
      var _EmberGetProperties2 = Ember.getProperties(this, 'category', 'group'),
          category = _EmberGetProperties2.category,
          group = _EmberGetProperties2.group;

      return Ember.get(category, 'type') === 'Cut Guides' && Ember.get(group, 'guides.length');
    })
  });
});