define('mx/helpers/varprice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.varprice = varprice;
  function varprice(params /*, hash*/) {
    var total = parseInt(params[0]);
    if (isNaN(total)) {
      total = 0;
    }

    var map = params[1];
    if (map) {
      if (typeof map === 'string') {
        try {
          map = JSON.parse(map.replace(/\s+/g, ''));
        } catch (err) {
          map = null;
        }
      }
    }
    var price = params[2];
    var discount = 0;

    if (map) {
      discount = map[total];
    }
    return ((price * total - discount) / 100).toFixed(2);
  }

  exports.default = Ember.Helper.helper(varprice);
});