define('mx/components/dashboard-builder/header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'h4',
    classNames: ['dashboard-builder-header']
  });
});