define('mx/models/atk-cut-guide', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'mx/v2/util/cloudinary'], function (exports, _model, _attr, _relationships, _cloudinary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /** Attributes */
    photo: (0, _attr.default)('string'),
    // Used to derive the protein groupings, e.g. 'Beef'.
    proteinName: (0, _attr.default)('string'),
    // Set of responsive cloudinary images.
    responsivePhotos: Ember.computed('photo', function () {
      return (0, _cloudinary.makeCloudinaryUrisForType)('guide', Ember.get(this, 'photo'));
    }),
    title: (0, _attr.default)('string'),
    text: (0, _attr.default)('string'),
    /** Associations */
    recipes: (0, _relationships.hasMany)('atk-recipe')
  });
});