define('mx/v2/components/mx/validated-form/message/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'small',
    classNameBindings: ['themeVariant'],
    /**
     * The bootstrap text color to apply.
     *
     * @type {string}
     * @default 'muted'
     * @memberof Components/Mx/ValidatedForm/Message
     */
    themeVariant: Ember.computed('variant', function () {
      var variant = Ember.get(this, 'variant') || 'muted';
      return 'text-' + String(variant);
    }).readOnly()
  });
});