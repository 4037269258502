define('mx/v2/components/x-recipe/card/text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The maximium length the text can be.
     *
     * @type {number}
     * @default null
     * @memberof Components/XRecipe/Card/Text
     */
    limit: null,
    /**
     * Truncates card text with ellipses if a text length limit is provided.
     *
     * @function truncated
     * @returns {string} Truncated text.
     * @memberof Components/XRecipe/Card/Text
     */
    truncated: Ember.computed('text', 'limit', function () {
      var limit = Ember.get(this, 'limit');
      var text = Ember.get(this, 'text');
      if (Number.isInteger(limit) && text && text.length > limit) {
        text = String(text.slice(0, limit)) + '...'; // Technically limit +3.
      }
      return text;
    })
  });
});