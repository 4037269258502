define('mx/serializers/v2-share-config', ['exports', 'mx/serializers/walden-api', 'ember-data'], function (exports, _waldenApi, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _waldenApi.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      shareAddons: { key: 'share-addons', serialize: 'records', deserialize: 'records' },
      shareSize: { key: 'share-size', deserialize: 'records' },
      meatExclusions: { key: 'meat-exclusions', deserialize: 'records' },
      cutGroupExclusions: { key: 'cut-group-exclusions', deserialize: 'records' },
      cutGroupInclusions: { key: 'cut-group-inclusions', deserialize: 'records' },
      dietaryRestrictions: { key: 'dietary-restrictions', deserialize: 'records' },
      shareFrequency: { key: 'share-frequency', deserialize: 'records' },
      shareType: { key: 'share-type', deserialize: 'records' },
      sharePeople: { key: 'share-peoples', deserialize: 'records' }
    }
  });
});