define('mx/adapters/share-config', ['exports', 'mx/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = _application.default.extend({
    session: Ember.inject.service('session'),
    ajaxiService: Ember.inject.service('ajax'),
    pathForType: function pathForType(type) {
      return 'share-configs';
    },
    findAll: function findAll() {
      var _this = this;

      var token = this.get('session').session.content.authenticated.token;
      var tokenData = JSON.parse(atob(token.split('.')[1]));
      var member = tokenData.userId;
      var url = 'members/' + String(member) + '/share-configs/current';

      return this.get('ajaxService').request(url, {
        method: 'GET',
        headers: {
          token: token
        }
      }).then(function (response) {
        _newArrowCheck(this, _this);

        return response;
      }.bind(this));
    }
  });
});