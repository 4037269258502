define('mx/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    metrics: Ember.inject.service('metrics'),
    activate: function activate() {
      var _this = this;

      Ember.onerror = function (err) {
        _newArrowCheck(this, _this);

        this._sendException(err);
      }.bind(this);
      Ember.RSVP.on('error', function (err) {
        _newArrowCheck(this, _this);

        this._sendException(err);
      }.bind(this));
    },
    beforeModel: function beforeModel() {
      window.addEventListener("message", tokenRequest, false);
      var token = Ember.get(this, 'session.session.content.authenticated.token');

      var responseData = {
        "type": "token",
        "loggedIn": token ? true : false,
        "token": token
      };
      function tokenRequest(event) {
        var origin = event.origin || event.originalEvent.origin;
        // if (origin === 'https://bond.waldenlocalmeat.com')
        if (true) {
          event.source.postMessage(responseData, event.origin);
        }
      }
      $(document).ready(function () {
        $('#main-nav-toggler').collapse({
          toggle: false
        });
      });
      this._super.apply(this, arguments);
    },

    actions: {
      reloadPage: function reloadPage() {
        window.location.reload();
      },
      didTransition: function didTransition() {
        $(document).ready(function () {
          if ($('#main-nav-toggler').hasClass('show')) {
            $('#main-nav-toggler').collapse('hide');
          }
        });
        return true;
      }
    },
    /**
     * send an exception message to ga
     *
     * @param {object} err error object
     * @returns {null} null
     */
    _sendException: function _sendException(err) {
      if (typeof ga === 'function') {
        ga('send', 'exception', {
          exDescription: err.message,
          exFatal: false
        });
      }
    }
  });
});