define('mx/v2/components/member-dashboards/bond-program/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service('media'),
    /**
     * Displays a link to the (external) bonds site
     *
     * @class Components/MemberDashboards/bond
     * @extends Component
     */
    bondLink: Ember.computed(function () {
      if (window.location.href.indexOf('staging') > 0) {
        return 'http://bond.staging.waldenlocalmeat.com';
      } else {
        return 'https://bond.waldenlocalmeat.com';
      }
    }).readOnly(),
    /**
     * Base image from S3
     *
     * @type function
     * @returns {string} Base image URI.
     * @memberof Components/MemberDashboards/bond
     */
    baseImage: Ember.computed(function () {
      var desktop = 'https://walden-static-assets.s3.amazonaws.com/images/Member_Page_Image_desktop.jpg';
      var mobile = 'https://walden-static-assets.s3.amazonaws.com/images/Member_Page_Image_mobile.jpg';
      return Ember.get(this, 'media.isMobile') ? mobile : desktop;
    }).readOnly()
  });
});