define('mx/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-ajax/mixins/ajax-support', 'mx/config/environment'], function (exports, _emberData, _dataAdapterMixin, _ajaxSupport, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, _ajaxSupport.default, {
    authorizer: 'authorizer:token',
    //host: 'http://10.0.0.49:10010'
    host: _environment.default.walden_api
  });
});