define('mx/v2/components/atk-guides/recipes/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The atk recipes.
     *
     * @type {Array.<Models/AtkRecipe>}
     * @default null
     * @memberof Components/AtkGuides/Recipes
     */
    recipes: null,
    /**
     * Closure to invoke when a recipe is clicked.
     *
     * @type {function}
     * @default null
     * @memberof Components/AtkGuides/Recipes
     */
    onClickRecipe: null
  });
});