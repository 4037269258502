define('mx/v2/components/member-dashboards/latest-news/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    newsLink: Ember.computed(function () {
      return 'https://waldenlocalmeat.com/latest-news';
    }).readOnly()
  });
});