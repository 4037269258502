define('mx/components/wufoo-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // import _ from 'lodash/lodash';

  var DEFAULT_HEIGHT = 14000;

  /**
   * This component builds off of Wufoo's generic script for embedding their form
   * in a page. It replaces their script loader with jQuery's getScript, but
   * otherwise should function the same.
   *
   * Reference: http://help.wufoo.com/articles/en_US/kb/Embed
   */

  /* global WufooForm */
  exports.default = Ember.Component.extend({
    username: null,
    formHash: null,
    defaultValues: {},

    height: null,
    autoResize: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('loading', true);
      var self = this;

      // Load Wufoo script
      Ember.$.getScript('https://www.wufoo.com/scripts/embed/form.js', function () {
        var wufooInstance = void 0;
        var options = {
          userName: self.get('userName'),
          formHash: self.get('formHash'),
          autoResize: self.get('autoResize'),
          async: true,
          host: 'wufoo.com',
          header: 'show',
          ssl: true,
          defaultValues: self._buildDefaultsHash(self.get('defaultValues'))
        };

        try {
          wufooInstance = new WufooForm();
          wufooInstance.initialize(options);
          wufooInstance.display();
        } catch (e) {
          Ember.Logger.error(e);
        } finally {
          self.set('loading', false);
        }
      });
    },


    /**
     * Take a hash that maps field names from Wufoo to their values and convert it
     * into a string that Wufoo's script will understand.
     * @param  {Object} hash Default values
     * @return {String}      Default value string for Wufoo script
     */
    _buildDefaultsHash: function _buildDefaultsHash(hash) {
      return _.map(hash, function (value, name) {
        return name + '=' + encodeURIComponent(value);
      }).join('&');
    }
  });
});