define('mx/helpers/diff', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.diff = diff;
  function diff(params /*, hash*/) {
    return params[0] - params[1];
  }

  exports.default = Ember.Helper.helper(diff);
});