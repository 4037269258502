define('mx/adapters/cutgroup', ['exports', 'mx/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = _application.default.extend({
    ajax: Ember.inject.service('ajax'),
    findAll: function findAll(modelName, query) {
      var _this = this;

      return this.get('ajax').request('/shareconfig/cut-groups', {
        method: 'GET'
      }).then(function (response) {
        _newArrowCheck(this, _this);

        return response;
      }.bind(this));
    },
    query: function query(store, type, query) {
      var _this2 = this;

      var cut_type = query.filter.cut_type;
      var url = 'shareconfig/cut-groups?cut_type=' + String(cut_type) + '&active=1';
      return this.get('ajax').request(url, {
        method: 'GET'
      }).then(function (response) {
        _newArrowCheck(this, _this2);

        return response;
      }.bind(this));
    }
  });
});