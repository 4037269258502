define('mx/helpers/numeq', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  /**
   * Determines if two numeric values are equal.
   * @param {number|string[]} params - Helper params
   * @param {number|string} params[].0 - First value
   * @param {number|string} params[].1 - Second value
   * @returns {boolean} Result of numeric comparison
   * @memberof helpers
   * @example
   * // returns true
   * numeq(5, '5')
   * @example
   * // returns false
   * numeq(1, 10)
   */
  var numeq = function (params) {
    _newArrowCheck(undefined, undefined);

    return parseInt(params[0]) === parseInt(params[1]);
  }.bind(undefined);
  exports.default = Ember.Helper.helper(numeq);
});