define('mx/v2/components/account/delivery-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Address model to summarize.
     *
     * @type {Models/Address}
     * @default null
     * @memberof Components/Account/DeliverySummary
     */
    address: null
  });
});