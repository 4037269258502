define('mx/serializers/share-config', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      share_addons: { serialize: 'records', deserialize: 'records' },
      meat_exclusions: { serialize: 'records', deserialize: 'records' },
      cut_group_exclusions: { serialize: 'records', deserialize: 'records' },
      cut_group_inclusions: { serialize: 'records', deserialize: 'records' },
      share_people: { serialize: 'records', deserialize: 'records' },
      share_type: { serialize: 'records', deserialize: 'records' },
      share_size: { serialize: 'records', deserialize: 'records' },
      dietary_restrictions: { serialize: 'records', deserialize: 'records' },
      share_frequency: { serialize: 'records', deserialize: 'records' }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.success) {
        delete payload.success;
        payload['share-config'] = {};
        payload['share-config'].id = new Date().getTime();
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload.share_config.dietary_restrictions = payload.share_config.dietary_exclusions;
      payload.share_config.share_addons = payload.share_config.recurring_addons;
      payload.share_config.share_type = payload.share_config.type_details;
      payload.share_config.share_frequency = payload.share_config.frequency_details;
      payload.share_config.share_size = payload.share_config.size_details;
      // payload.share_config.share_size['weightLower'] = payload.share_config.share_size.weight_lower;

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = payload.share_config.share_addons[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var addon = _step.value;

          addon.share_addon = addon.share_addons;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      payload = { 'share-configs': [payload.share_config] };
      return this._super(store, primaryModelClass, payload, id, requestType);
    },


    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      hash['share-config'] = this.serialize(record, options);
    }
  });
});