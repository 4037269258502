define('mx/models/member', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'First name'
    })],
    last_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Last name'
    })],
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      }), (0, _emberCpValidations.validator)('email-in-use', { debounce: 500 })],
      description: 'Email'
    },
    referral_type: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Select one'
    })],
    password: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 72
      })],
      description: 'Password'
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        type: 'phone'
      })],
      description: 'Phone number'
    },
    referral_note: [(0, _emberCpValidations.validator)('referral-note')]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    addresses: _emberData.default.hasMany('address'),
    active: _emberData.default.attr('boolean'),
    suspended: _emberData.default.attr('boolean'),
    legacy_id: _emberData.default.attr('string', { allowNull: true }),
    email: _emberData.default.attr('string'),
    email_confirmation: _emberData.default.attr('string'),
    stripe_token: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    password_confirmation: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    referral_code: _emberData.default.attr('string'),
    referral_type: _emberData.default.belongsTo('referral-type'),
    referral_note: _emberData.default.attr('string'),
    generation: _emberData.default.attr('number'),
    share_warning: _emberData.default.attr('boolean'),
    service_type: _emberData.default.attr('number'),
    sms: _emberData.default.attr('boolean', { defaultValue: false }),
    created_at: _emberData.default.attr('string')
  });
});