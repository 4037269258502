define('mx/controllers/members/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Controller.extend({
    media: Ember.inject.service('media'),
    member: Ember.computed.alias('model.member'),
    fresh: Ember.inject.service('fresh-pilot'),
    showFresh: Ember.computed.and('fresh.pilot', 'fresh.showNextDelivery'),
    showAlcohol: Ember.computed('member.addresses.[]', function () {
      var _this = this;

      var show = false;
      try {
        var addresses = this.get('member.addresses').toArray().sort(function (a, b) {
          _newArrowCheck(this, _this);

          return parseInt(b.get('id')) - parseInt(a.get('id'));
        }.bind(this));

        // This member has an active address which allows alcohol
        var eligible = addresses.objectAt(0).get('zip.allow_alcohol');
        // This member has a service type of delivery
        var memberIsDelivery = parseInt(this.get('member.service_type')) === 1;
        // The date of the member's sign up
        var memberCreated = (0, _moment.default)(this.get('member.created_at'), 'YYYY-MM-DD hh:mm:ss').utc();
        // The current date
        var now = (0, _moment.default)().utc();

        // If alcohol zip allowed, type is delivery, and
        // member > 3 months: show alcohol
        if (eligible && now.diff(memberCreated, 'months', true) > 3 && memberIsDelivery) {
          show = true;
        }
      } catch (err) {
        show = false;
      }

      return show;
    }),
    showBond: Ember.computed('member.addresses.[]', function () {
      var _this2 = this;

      var show = false;
      try {
        var addresses = this.get('member.addresses').toArray().sort(function (a, b) {
          _newArrowCheck(this, _this2);

          return parseInt(b.get('id')) - parseInt(a.get('id'));
        }.bind(this));

        // This member has an active address which allows alcohol
        show = addresses.objectAt(0).get('zip.allow_bond');
      } catch (err) {
        show = false;
      }

      return show;
    })
  });
});