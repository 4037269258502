define('mx/helpers/addon-sold-out', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    /**
     * Because you cannot purchase a main thanksgiving item directly, it's only sold out if all children are sold out.
     *
     * Child is sold out if it has a global limit and the available quantity is less than 51.
     *
     * @override
     * @function compute
     * @memberof Helpers/AddonSoldOut
     */
    compute: function compute(_ref) {
      var _this = this;

      var _ref2 = _slicedToArray(_ref, 1),
          addon = _ref2[0];

      if (!(addon && Ember.get(addon, 'children.length'))) return false;
      var children = Ember.get(addon, 'children');
      var soldOutChildren = children.toArray().filter(function (c) {
        _newArrowCheck(this, _this);

        return Ember.get(c, 'global_limit') && Ember.get(c, 'available') < 51;
      }.bind(this));
      return Ember.get(children, 'length') === Ember.get(soldOutChildren, 'length');
    }
  });
});