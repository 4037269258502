define('mx/v2/components/atk-guides/card-provider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * The atk cut guide.
     *
     * @type {Models/AtkCutGuide}
     * @default null
     * @memberof Components/AtkGuides/CardProvider
     */
    guide: null
  });
});