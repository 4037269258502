define('mx/models/v2-share-config', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Associations
    cutGroupExclusions: _emberData.default.hasMany('v2-cut-group'),
    cutGroupInclusions: _emberData.default.hasMany('v2-cut-group'),
    dietaryRestrictions: _emberData.default.hasMany('v2-dietary-restriction'),
    meatExclusions: _emberData.default.hasMany('v2-meat-type'),
    member: _emberData.default.attr('number'),
    shareAddons: _emberData.default.hasMany('v2-addon'),
    shareFrequency: _emberData.default.belongsTo('v2-share-frequency'),
    sharePeople: _emberData.default.belongsTo('v2-share-people'),
    shareSize: _emberData.default.belongsTo('v2-share-size'),
    shareType: _emberData.default.belongsTo('v2-share-type')
  });
});