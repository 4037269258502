define('mx/serializers/newuser', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      member: { serialize: 'records' },
      share_config: { serialize: 'records' },
      address: { serialize: 'records' }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        newuser: {
          id: 0,
          member: null,
          share_config: null,
          address: null
        }
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      hash['sign-up'] = this.serialize(record, options);
    }
  });
});