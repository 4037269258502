define('mx/components/ember-truncate/button-toggle', ['exports', 'ember-truncate/components/ember-truncate/button-toggle'], function (exports, _buttonToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _buttonToggle.default;
    }
  });
});