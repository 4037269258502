define('mx/components/fresh-pilot/marketing/faq-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The question being asked.
     *
     * @argument question
     * @type {string}
     * @default null
     * @memberof Components/Fresh-Pilot/Marketing/Faq-Item
     */
    question: null,
    /**
     * The answer to the question.
     *
     * @argument answer
     * @type {string}
     * @default null
     * @memberof Components/Fresh-Pilot/Marketing/Faq-Item
     */
    answer: null
  });
});