define('mx/v2/components/atk-content/categories-nav/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The item categories.
     *
     * @type {Array.<AtkContentCategory>}
     * @default null
     * @memberof Components/AtkContent/CategoriesNav
     */
    categories: null,
    /**
     * The currently selected category.
     *
     * @type {AtkContentCategory}
     * @default null
     * @memberof Components/AtkContent/CategoriesNav
     */
    selectedCategory: null,
    /**
     * Closure to invoke when a category nav item is clicked.
     *
     * @type {Function}
     * @class Components/AtkContent/CategoriesNav
     */
    onClickCategory: null
  });
});