define('mx/components/mx/button-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    attributeBindings: ['type', 'disabled'],
    classNames: ['btn'],
    /**
     * The type of button.
     *
     * @type {string}
     * @default button
     * @memberof Components/Mx/Button-Base
     */
    type: 'button',
    /**
     * Whether or not the button is disabled.
     *
     * @type {boolean}
     * @default false
     * @memberof Components/Mx/Button-Base
     */
    disabled: false,
    /**
     * Whether or not to show a loading spinner inside the button.
     *
     * @type {boolean}
     * @default false
     * @memberof Components/Mx/Button-Base
     */
    loading: false
  });
});