define('mx/adapters/atk', ['exports', 'mx/adapters/walden-api'], function (exports, _waldenApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _waldenApi.default.extend({
    /**
     * Remove 'atk' from the path of any requested ATK entity.
     *
     * @override
     * @function pathForType
     * @param {string} type The name of the model.
     * @returns {string} Path for ATK entity.
     * @memberof Adapters/Atk
     */
    pathForType: function pathForType(type) {
      return this._super(type.replace('atk-', ''));
    }
  });
});