define('mx/components/mx/button-fb-referral', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    fbShareLink: Ember.computed('code', function () {
      return 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmembers.waldenlocalmeat.com%2Fsign-up%3Freferral%3D' + String(Ember.get(this, 'code')) + '&amp;src=sdkpreparse&message=cool';
    })
  });
});