define('mx/services/walden-auth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    sessionId: null,
    init: function init() {
      this._super.apply(this, arguments);

      // Generate a new sessionID
      var uuid = this._generateSessionId();
      this.set('sessionId', uuid);
    },

    /**
     *	Generate a UUID
     *
     *	@method _generateSessionId;
     *	@memberof Services/WaldenAuth
     */
    _generateSessionId: function _generateSessionId() {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    }
  });
});