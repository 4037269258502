define('mx/models/address', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    address1: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 2 })],
      description: 'Address'
    },
    city: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 2 })],
      description: 'City'
    },
    notes: [(0, _emberCpValidations.validator)('presence', { presence: true, message: 'Please let us know where to leave your share' })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    zip: _emberData.default.belongsTo('zip'),
    daytime: _emberData.default.attr('boolean'),
    notes: _emberData.default.attr('string')
  });
});