define('mx/v2/classes/step', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /**
   * Step class that provides separate state for various steps in the sign-up process.
   *
   * @class Step
   * @extends EmberObject
   */
  var Step = Ember.Object.extend({
    /**
     * Whether or not this step is enabled.
     *
     * @type {boolean}
     * @default false
     * @memberof Step
     */
    enabled: false,
    /**
     * The unique identifer of this step.
     *
     * @type {string}
     * @default ''
     * @memberof Step
     */
    id: '',
    /**
     * The title of this step.
     *
     * @type {string}
     * @default ''
     * @memberof Step
     */
    title: '',
    isEqual: function isEqual(other) {
      return this.id === other.id;
    }
  });
  exports.default = Step;
});