define('mx/components/share-config/share-frequency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     *  Is the default too frequent for the member?
     *
     *  @argument
     *  @type {boolean}
     *  @memberof Components/ShareConfig/ShareFrequency
     */
    tooFrequent: false,
    /**
     *  Whether or not to show the frequency select
     *  If "too frequent" is clicked, or the member is not
     *  monthly, show
     *
     *  @computed
     *  @returns {boolean} show or hide display
     *  @memberof Components/ShareConfig/ShareFrequency
     */
    showFrequencySelect: Ember.computed('tooFrequent', 'target.id', function () {
      var targetDisplay = Ember.get(this, 'target.display');
      var tooFrequent = Ember.get(this, 'tooFrequent');
      return tooFrequent || targetDisplay.toLowerCase() !== 'monthly';
    }),
    actions: {
      /**
       *  Update the target frequency on select
       *
       *  @function action:updateShareFrequency
       *  @param {number} frequencyId - the id of the shareFrequency model instance
       *  @memberof Components/ShareConfig/ShareFrequency
       */
      updateShareFrequency: function updateShareFrequency(frequencyId) {
        var shareFrequency = Ember.get(this, 'shareFrequencies').findBy('id', frequencyId);
        Ember.set(this, 'target', shareFrequency);
      }
    }
  });
});