define('mx/models/v2-sticky-preference', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Attributes
    inclusion: _emberData.default.attr('boolean'),
    // Associations
    cut: _emberData.default.belongsTo('v2-cut'),
    member: _emberData.default.belongsTo('v2-member')
  });
});