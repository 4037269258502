define('mx/services/vr-share-config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Service.extend({
    waldenMember: Ember.inject.service('member'),
    /**
     *  The shareConfig model instance to validate against
     *  Call setShareConfig to set this member. When view rules are no
     *  longer needed, call clearShareConfig() to avoid collisions
     *
     *  @type {Models/ShareConfig}
     *  @memberof Services/VRShareConfig
     */
    shareConfig: null,
    /**
     *  Are there one or two base groups selected?
     *
     *  @type {boolean}
     *  @memberof VRShareConfig
     */
    baseGroupsValid: true,
    /**
     *  Are there at least 5 middle groups selected?
     *
     *  @type {boolean}
     *  @memberof VRShareConfig
     */
    middleGroupsInvalid: true,
    /**
     *  Are there at least 5 end groups selected?
     *
     *  @type {boolean}
     *  @memberof VRShareConfig
     */
    endGroupsInvalid: true,
    sausageSelectionBroken: true,
    /**
     *  Denote whether a member needs to be charged extra for the
     *  inclusion of any lamb. (Gen 3 Members)
     *
     *  @computed
     *  @returns {boolean}
     *  @memberof Services/VRShareConfig
     */
    lambPriceIncrease: Ember.computed('waldenMember.generation', function () {
      var memberGeneration = Ember.get(this, 'waldenMember.generation');
      return memberGeneration > 3;
    }),
    /**
     *  Denote whether a member should receive $5 off of Custom
     *  share type. (Legacy Members)
     *
     *  @computed
     *  @returns {boolean}
     *  @memberof Services/VRShareConfig
     */
    customDiscount: Ember.computed('waldenMember.generation', function () {
      var memberGeneration = Ember.get(this, 'waldenMember.generation');
      return memberGeneration === 1;
    }),
    /**
     *  Denote whether a member should see a price increase
     *  share type. (gen 2+)
     *
     *  @computed
     *  @returns {boolean}
     *  @memberof Services/VRShareConfig
     */
    priceIncrease2020: Ember.computed('waldenMember.generation', function () {
      var memberGeneration = Ember.get(this, 'waldenMember.generation');
      return memberGeneration > 2;
    }),
    /**
     *  Determine the validity of the collection of meat types on
     *  a given share config. This rule ensures that at least one
     *  meat type is selected.
     *
     *  @computed
     *  @returns {boolean}
     *  @memberof VRShareConfig
     */
    meatTypesInvalid: Ember.computed('shareConfig.{meatExclusions.[]}', function () {
      if (Ember.get(this, 'shareConfig')) {
        var meatExclusions = Ember.get(this, 'shareConfig.meatExclusions');
        return meatExclusions.toArray().length === 4;
      }
    }),
    /**
     *  Determine if the meat type selection on a given share config
     *  is sufficient.
     *
     *  @computed
     *  @returns {boolean}
     *  @memberof VRShareConfig
     */
    meatTypesInsufficient: Ember.computed('shareConfig.{meatExclusions.[]}', function () {
      if (Ember.get(this, 'shareConfig')) {
        var meatExclusions = Ember.get(this, 'shareConfig.meatExclusions');
        var hasLamb = !meatExclusions.findBy('name', 'Lamb');
        // Can't have just lamb.
        if (meatExclusions.toArray().length === 3 && hasLamb) {
          return true;
        }
        return false;
      }
    }),
    /**
     * Whether or not the share config is a chicken-only share.
     *
     * @computed
     * @returns {boolean}
     * @memberof VRShareConfig
     */
    isChickenOnly: Ember.computed('shareConfig.{meatExclusions.[]}', function () {
      var meatExclusions = Ember.get(this, 'shareConfig.meatExclusions');
      return meatExclusions.toArray().length === 3 && !meatExclusions.findBy('name', 'Chicken');
    }),
    /**
     * Whether or not the share config is a chicken and lamb only share.
     *
     * @computed
     * @returns {boolean}
     * @memberof VRShareConfig
     */
    isChickenAndLambOnly: Ember.computed('shareConfig.{meatExclusions.[]}', function () {
      var meatExclusions = Ember.get(this, 'shareConfig.meatExclusions');
      return meatExclusions.toArray().length === 2 && !meatExclusions.findBy('name', 'Chicken') && !meatExclusions.findBy('name', 'Lamb');
    }),
    /**
     * Given a share type, determine if it should be available for selection.
     *
     * @function isShareTypeAvailable
     * @param {Models/V2ShareType} type Share Type.
     * @returns {boolean} Whether or not the share type is available.
     * @memberof VRShareConfig
     */
    isShareTypeAvailable: function isShareTypeAvailable(type) {
      var _EmberGetProperties = Ember.getProperties(this, 'isChickenOnly', 'isChickenAndLambOnly'),
          isChickenOnly = _EmberGetProperties.isChickenOnly,
          isChickenAndLambOnly = _EmberGetProperties.isChickenAndLambOnly;

      var avail = true;
      if ((isChickenOnly || isChickenAndLambOnly) && ['grind', 'custom'].includes(Ember.get(type, 'slug'))) {
        avail = false;
      }
      return avail;
    },

    /**
     * Given a share type, derive the description based on the state of the share config.
     *
     * @function descriptionForShareType
     * @param {Models/V2ShareType} type Share Type.
     * @returns {string} Description.
     * @memberof VRShareConfig
     */
    descriptionForShareType: function descriptionForShareType(type) {
      var _this = this;

      var _EmberGetProperties2 = Ember.getProperties(this, 'isChickenOnly', 'isChickenAndLambOnly'),
          isChickenOnly = _EmberGetProperties2.isChickenOnly,
          isChickenAndLambOnly = _EmberGetProperties2.isChickenAndLambOnly;

      var desc = Ember.get(type, 'description');
      var slug = Ember.get(type, 'slug');
      var makeDescription = function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        _newArrowCheck(this, _this);

        return '<ul>' + String(args.map(function (a) {
          _newArrowCheck(this, _this);

          return '<li>' + String(a) + '</li>';
        }.bind(this)).join('')) + '</ul>';
      }.bind(this);
      // Basic and Complete share types have custom descriptions for chicken only, chicken and lamb only.
      var disabledMessage = 'Select Beef or Pork to try this share.';
      if (isChickenOnly) {
        if (slug === 'basic') {
          desc = makeDescription('Whole Chicken(s)');
        } else if (slug === 'complete') {
          desc = makeDescription('Whole Chicken(s) PLUS', 'Breasts and/or Legs');
        } else {
          desc = disabledMessage;
        }
      } else if (isChickenAndLambOnly) {
        if (slug === 'basic') {
          desc = makeDescription('Whole Chicken(s) PLUS', 'Ground Lamb');
        } else if (slug === 'complete') {
          desc = makeDescription('Basics PLUS', 'Chicken and Lamb Cuts');
        } else {
          desc = disabledMessage;
        }
      }
      return desc;
    },

    /**
     *  Determine whether the member's custom configuration is valid,
     *  and set service properties based on the following rules:
     *  - Must have 1 or 2 Base cuts
     *  - Must have at least 5 End cuts
     *  - Must have at least 5 Middle cuts
     *  - If lamb is included, track how many groups are selected
     *
     *  @returns {boolean}
     *  @computed
     *  @returns {boolean}
     *  @memberof VRShareConfig
     */
    customInvalid: Ember.computed('shareConfig.{shareType,meatExclusions.[],cutGroupInclusions.[],cutGroupExclusions.[]}', function () {
      if (Ember.get(this, 'shareConfig')) {
        var shareConfig = Ember.get(this, 'shareConfig');
        var inclusions = Ember.get(shareConfig, 'cutGroupInclusions');
        var exclusions = Ember.get(shareConfig, 'cutGroupExclusions');
        var meatExclusions = Ember.get(shareConfig, 'meatExclusions');
        var shareType = Ember.get(shareConfig, 'shareType');

        // The validations in the function are only indended for the custom shareType
        if (Ember.get(shareType, 'name') !== 'Custom') return false;

        var configGroupCounts = {};
        var lambCount = 0;

        /**
         * Calculate the numver of selected cuts per cutType. Since there is no
         * access to a selected value on cutgroup models, selected state can be inferred
         * based on the rules outlined below
         */
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Ember.get(shareType, 'cutTypes').toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var cutType = _step.value;

            var cutTypeId = Ember.get(cutType, 'id');
            configGroupCounts[cutTypeId] = 0;

            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = Ember.get(cutType, 'cutCategories').toArray()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var cat = _step2.value;
                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                  for (var _iterator3 = Ember.get(cat, 'cutGroups').filterBy('active', true).toArray()[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                    var group = _step3.value;

                    // If this group is a cutGroupInclusion, it is selected
                    if (inclusions.findBy('id', Ember.get(group, 'id'))) {
                      configGroupCounts[cutTypeId] = configGroupCounts[cutTypeId] + 1;
                      if (Ember.get(group, 'meatType.name') === 'Lamb') {
                        // While there is access to this data, store the count of lamb cuts
                        // for informational data used in other calculations.
                        lambCount++;
                      }
                      continue;
                    }

                    /**
                     *  If a cutGroup does not exist in cutGroupExclusions, and there is no
                     *  meatExclusion for it's meatType, the group is assumed selected. This is
                     *  only true for groups with a displaySection of 0, to account for inclusion
                     *  only base substitution cutGroups
                     */
                    if (group.get('displaySection') === 0) {
                      if (!exclusions.findBy('id', Ember.get(group, 'id'))) {
                        if (!meatExclusions.findBy('id', Ember.get(group, 'meatType.id'))) {
                          configGroupCounts[cutTypeId] = configGroupCounts[cutTypeId] + 1;
                          if (Ember.get(group, 'meatType.name') === 'Lamb') {
                            lambCount++;
                          }
                        }
                      }
                    }
                  }
                } catch (err) {
                  _didIteratorError3 = true;
                  _iteratorError3 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion3 && _iterator3.return) {
                      _iterator3.return();
                    }
                  } finally {
                    if (_didIteratorError3) {
                      throw _iteratorError3;
                    }
                  }
                }
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }
          }
          // Will the lamb count may be a problem in the future? (see 'showLambQuantityWarning')
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return this.validateCustomGroupCounts(configGroupCounts, Ember.get(shareType, 'cutTypes'));
      }
    }),
    /**
    *  Determine whether Custom shares are broken due to sausage restriction.
    *  Sometime a member selects sausage as a dietary restriction then selects sausage as their only base.
    *  This is inconsistent.
    *
    *  @returns {boolean}
    *  @computed
    *  @returns {boolean}
    *  @memberof VRShareConfig
    */
    customSelectionInvalid: Ember.computed('shareConfig.{shareType,dietaryRestrictions.[],meatExclusions.[],cutGroupInclusions.[],cutGroupExclusions.[]}', function () {
      if (Ember.get(this, 'shareConfig')) {
        var shareConfig = Ember.get(this, 'shareConfig');
        var inclusions = Ember.get(shareConfig, 'cutGroupInclusions');
        var exclusions = Ember.get(shareConfig, 'cutGroupExclusions');
        var meatExclusions = Ember.get(shareConfig, 'meatExclusions');
        var shareType = Ember.get(shareConfig, 'shareType');
        var restriction = Ember.get(shareConfig, 'dietaryRestrictions');

        // The validations in the function are only indended for the custom shareType
        if (Ember.get(shareType, 'name') !== 'Custom') return false;

        var selectedList = [];
        var sausageNotSelected = !restriction.findBy('id', '9');
        var sausageInvalid = false;
        var sausageBroken = false;

        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = Ember.get(shareType, 'cutTypes').toArray()[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var cutType = _step4.value;

            var _cutType$getPropertie = cutType.getProperties('id', 'name'),
                id = _cutType$getPropertie.id,
                name = _cutType$getPropertie.name;

            if (name === 'Base') {
              var _iteratorNormalCompletion5 = true;
              var _didIteratorError5 = false;
              var _iteratorError5 = undefined;

              try {
                for (var _iterator5 = Ember.get(cutType, 'cutCategories').toArray()[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                  var category = _step5.value;
                  var _iteratorNormalCompletion6 = true;
                  var _didIteratorError6 = false;
                  var _iteratorError6 = undefined;

                  try {
                    for (var _iterator6 = Ember.get(category, 'cutGroups').filterBy('active', true).toArray()[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                      var group = _step6.value;

                      // If this group is a cutGroupInclusion, it is selected
                      if (inclusions.findBy('id', Ember.get(group, 'id'))) {
                        selectedList.push(Ember.get(group, 'id'));
                        continue;
                      }

                      if (group.get('displaySection') === 0) {
                        if (!exclusions.findBy('id', Ember.get(group, 'id'))) {
                          if (!meatExclusions.findBy('id', Ember.get(group, 'meatType.id'))) {
                            selectedList.push(Ember.get(group, 'id'));
                          }
                        }
                      }
                    }
                  } catch (err) {
                    _didIteratorError6 = true;
                    _iteratorError6 = err;
                  } finally {
                    try {
                      if (!_iteratorNormalCompletion6 && _iterator6.return) {
                        _iterator6.return();
                      }
                    } finally {
                      if (_didIteratorError6) {
                        throw _iteratorError6;
                      }
                    }
                  }
                }
              } catch (err) {
                _didIteratorError5 = true;
                _iteratorError5 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion5 && _iterator5.return) {
                    _iterator5.return();
                  }
                } finally {
                  if (_didIteratorError5) {
                    throw _iteratorError5;
                  }
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }

        for (var i = 0; i < selectedList.length; i++) {
          if (selectedList[i] === '2' || selectedList[i] === '125') {
            if (!sausageNotSelected) {
              sausageBroken = true;
            } else {
              sausageBroken = false;
            }
          }
        }

        Ember.set(this, 'sausageSelectionBroken', sausageBroken);
        return sausageInvalid || sausageBroken;
      }
    }),
    /**
     * Parse the data collected above to determine validity by cutType
     * This is explicit by cutType.name, to allow for future rules or until
     * these rules are standardized in the API or database.
     *
     * Store these validity values to allow for the targeting of cutType specific
     * error messages.
     *
     * @function vallidateCustomGroupCounts
     * @param {Object} typeCounts - total selected group counts by type
     * @param {Array.<Models/CutType>} cutTypes - Ember array of cutType model instances
     * @returns {boolean} - true if any selections are invalid
     * @memberof VRShareConfig
     */
    validateCustomGroupCounts: function validateCustomGroupCounts(typeCounts, cutTypes) {
      var baseInvalid = false;
      var middleInvalid = false;
      var endInvalid = false;

      var _iteratorNormalCompletion7 = true;
      var _didIteratorError7 = false;
      var _iteratorError7 = undefined;

      try {
        for (var _iterator7 = cutTypes.toArray()[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
          var type = _step7.value;

          var _type$getProperties = type.getProperties('id', 'name'),
              id = _type$getProperties.id,
              name = _type$getProperties.name;

          if (name === 'Base') {
            // Must have 1 or 2 base cuts
            baseInvalid = typeCounts[id] < 1 || typeCounts[id] > 2;
            Ember.set(this, 'baseGroupsInvalid', baseInvalid);
          } else if (name === 'Middle') {
            // Must have at least 5 middle cuts
            middleInvalid = typeCounts[id] < 5;
            Ember.set(this, 'middleGroupsInvalid', middleInvalid);
          } else if (name === 'End') {
            // Must have at least 5 end cuts
            endInvalid = typeCounts[id] < 5;
            Ember.set(this, 'endGroupsInvalid', endInvalid);
          }
        }
      } catch (err) {
        _didIteratorError7 = true;
        _iteratorError7 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion7 && _iterator7.return) {
            _iterator7.return();
          }
        } finally {
          if (_didIteratorError7) {
            throw _iteratorError7;
          }
        }
      }

      return baseInvalid || middleInvalid || endInvalid;
    },

    /**
     *  Whether or not to show a lamb quantity warning. If a member is
     *  of generation 3, and lamb is included, ensure that at least 3 lamb
     *  groups are in the shareConfig inclusively
     *
     *  @computed
     *  @returns {boolean}
     *  @memberof VRShareConfig
     */
    shareConfigInvalid: Ember.computed.or('customInvalid', 'meatTypesInvalid', 'meatTypesInsufficient'),
    /**
     *  Whether or not to show a share configuration warning.
     *
     *  @computed
     *  @returns {boolean}
     *  @memberof VRShareConfig
     */
    shareConfigBroken: Ember.computed.or('shareConfigInvalid', 'customSelectionInvalid'),
    /**
     *  Set the target shareConfig model instance on the service
     *  against which to validate.
     *
     *  @function setShareconfig
     *  @param {Models/ShareConfig} shareConfig - a shareConfig model instance
     *  @memberof VRShareConfig
     */
    setShareConfig: function setShareConfig(shareConfig) {
      Ember.set(this, 'shareConfig', shareConfig);
    },

    /**
     * Set the share types available for share configuration.
     *
     * @function setShareTypes
     * @param {Array.<Models/V2ShareType>} types Share types.
     * @memberof VRShareConfig
     */
    setShareTypes: function setShareTypes(types) {
      Ember.set(this, 'shareTypes', types);
    },

    /**
     *  Remove the target shareConfig from the service
     *
     *  @function clearConfig
     *  @memberof VRShareConfig
     */
    clearConfig: function clearConfig() {
      Ember.set(this, 'shareConfig', null);
    },

    /**
     * Update the selected share type of the share config.
     *
     * @function _updateShareType
     * @param {Models/V2ShareType} shareType Share Type to select.
     * @memberof VRShareConfig
     */
    _updateShareType: function _updateShareType(shareType) {
      var targetSize = Ember.get(this, 'shareConfig.shareSize');
      var targetPortion = Ember.get(this, 'shareConfig.sharePeople');
      var sizes = Ember.get(shareType, 'shareSizes');
      var portions = Ember.get(shareType, 'sharePeoples');
      var target = Ember.get(this, 'shareConfig.shareType');

      if (Ember.get(shareType, 'id') !== Ember.get(target, 'id')) {
        // sort sizes from high to low
        var sortedSizes = sizes.sortBy('weightLower');
        var sortedPortions = portions.sortBy('peopleLower');

        // Set to closest size in new share type
        var goalWeight = Ember.get(targetSize, 'weightLower');
        var closestSize = sortedSizes.objectAt(0);
        var _iteratorNormalCompletion8 = true;
        var _didIteratorError8 = false;
        var _iteratorError8 = undefined;

        try {
          for (var _iterator8 = sortedSizes.toArray()[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
            var size = _step8.value;

            if (Math.abs(goalWeight - Ember.get(size, 'weightLower')) < Math.abs(goalWeight - Ember.get(closestSize, 'weightLower'))) {
              closestSize = size;
            }
          }
        } catch (err) {
          _didIteratorError8 = true;
          _iteratorError8 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion8 && _iterator8.return) {
              _iterator8.return();
            }
          } finally {
            if (_didIteratorError8) {
              throw _iteratorError8;
            }
          }
        }

        Ember.set(this, 'shareConfig.shareSize', closestSize);

        // set portion target to similar portion size
        if (portions.toArray().length && Ember.get(targetPortion, 'peopleLower')) {
          var goalPortion = Ember.get(targetPortion, 'peopleLower');
          var closestPortion = sortedPortions.objectAt(0);
          var _iteratorNormalCompletion9 = true;
          var _didIteratorError9 = false;
          var _iteratorError9 = undefined;

          try {
            for (var _iterator9 = sortedPortions.toArray()[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
              var portion = _step9.value;

              if (Math.abs(goalPortion - Ember.get(portion, 'peopleLower')) < Math.abs(goalPortion - Ember.get(closestPortion, 'peopleLower'))) {
                closestPortion = portion;
              }
            }
          } catch (err) {
            _didIteratorError9 = true;
            _iteratorError9 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion9 && _iterator9.return) {
                _iterator9.return();
              }
            } finally {
              if (_didIteratorError9) {
                throw _iteratorError9;
              }
            }
          }

          Ember.set(this, 'shareConfig.sharePeople', closestPortion);
        } else if (portions.toArray().length) {
          Ember.set(this, 'shareConfig.sharePeople', Ember.get(portions, 'firstObject'));
        } else {
          Ember.set(this, 'shareConfig.sharePeople', null);
        }

        if (!Ember.get(shareType, 'cutTypes').toArray().length) {
          var exclusions = Ember.get(this, 'shareConfig.cutGroupExclusions');
          var inclusions = Ember.get(this, 'shareConfig.cutGroupInclusions');
          var _iteratorNormalCompletion10 = true;
          var _didIteratorError10 = false;
          var _iteratorError10 = undefined;

          try {
            for (var _iterator10 = inclusions.toArray()[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
              var group = _step10.value;

              inclusions.removeObject(group);
            }
          } catch (err) {
            _didIteratorError10 = true;
            _iteratorError10 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion10 && _iterator10.return) {
                _iterator10.return();
              }
            } finally {
              if (_didIteratorError10) {
                throw _iteratorError10;
              }
            }
          }

          var _iteratorNormalCompletion11 = true;
          var _didIteratorError11 = false;
          var _iteratorError11 = undefined;

          try {
            for (var _iterator11 = exclusions.toArray()[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
              var _group = _step11.value;

              exclusions.removeObject(_group);
            }
          } catch (err) {
            _didIteratorError11 = true;
            _iteratorError11 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion11 && _iterator11.return) {
                _iterator11.return();
              }
            } finally {
              if (_didIteratorError11) {
                throw _iteratorError11;
              }
            }
          }
        }

        // set the share type
        Ember.set(this, 'shareConfig.shareType', shareType);
      }
    },

    actions: {
      /**
       * After selecting a meat type, update the selected share type if the current is no longer eligible.
       *
       * @function selectMeatType
       * @memberof VRShareConfig
       */
      selectMeatType: function selectMeatType() {
        if (!this.isShareTypeAvailable(Ember.get(this, 'shareConfig.shareType'))) {
          this._updateShareType(Ember.get(this, 'shareTypes').findBy('slug', 'complete'));
        }
      },

      /**
       * Update the selected share type if it's elgible for selection.
       *
       * @function selectShareType
       * @memberof VRShareConfig
       */
      selectShareType: function selectShareType(shareType) {
        if (!this.isShareTypeAvailable(shareType)) return;
        this._updateShareType(shareType);
      }
    }
  });
});