define('mx/models/pilot', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    active_start: _emberData.default.attr('string'),
    active_end: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    wufoo_config: _emberData.default.attr('raw')
  });
});