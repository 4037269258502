define('mx/v2/components/atk-content/photo-uri-for/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    /**
     * Original cloudinary photo URI.
     *
     * @type {string}
     * @default null
     * @memberof Components/AtkContent/PhotoUriFor
     */
    photo: null,
    /**
     * ATK content type. Currently support 'recipe' and 'guide'.
     *
     * @type {string}
     * @default null
     * @memberof Components/AtkContent/PhotoUriFor
     */
    type: null,
    /**
     * @function _scaledPhotos
     * @returns {object} Object containing cloudinary URIs for various image sizes.
     * @memberof Components/AtkContent/PhotoUriFor
     */
    scaledPhotos: Ember.computed('photo', 'type', 'height', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'type', 'photo'),
          type = _EmberGetProperties.type,
          photo = _EmberGetProperties.photo;

      return this._makeScaledPhotos(type, photo);
    }),
    /**
     * Create a set of scaled photos using cloudinary params.
     * @see {@link https://cloudinary.com/documentation/image_transformation_reference}
     *
     * @function _makeScaledPhotos
     * @param {string} type ATK content type.
     * @param {string} photo Photo URI.
     * @returns {object} Object containing cloudinary URIs for various image sizes.
     * @memberof Components/AtkContent/PhotoUriFor
     */
    _makeScaledPhotos: function _makeScaledPhotos(type, photo) {
      // ATK seems to use these on most of their site images.
      var base = 'c_fill,dpr_2.0,f_auto';
      if (type === 'recipe') {
        base = base + ',q_auto:low';
        return {
          sm: this._makeCloudinaryURI(photo, base + ',ar_1:1,h_60'),
          md: this._makeCloudinaryURI(photo, base + ',ar_1:1,h_150'),
          md_wide: this._makeCloudinaryURI(photo, base + ',ar_2:1,h_150,g_center'),
          lg: this._makeCloudinaryURI(photo, base + ',ar_1:1,h_400'),
          lg_wide: this._makeCloudinaryURI(photo, base + ',ar_2:1,h_400')
        };
      } else if (type === 'guide') {
        base = base + ',q_auto:low';
        return {
          sm: this._makeCloudinaryURI(photo, base + ',w_60'),
          md: this._makeCloudinaryURI(photo, base + ',w_300'),
          lg: this._makeCloudinaryURI(photo, base + ',w_600')
        };
      }
    },

    /**
     * Construct the cloudinary URI with params.
     *
     * @function _makeCloudinaryURI
     * @param {string} uri Original cloudinary URI.
     * @param {string} params Cloudinary params.
     * @returns {string} Cloudinary URI with params.
     * @memberof Components/AtkContent/PhotoUriFor
     */
    _makeCloudinaryURI: function _makeCloudinaryURI(uri, params) {
      if (!(uri && uri.includes('cloudinary') && params)) return uri;
      // Parameters need to be insterted directly after the '/image/upload/' portion of the URI.
      var separator = '/image/upload/';

      var _uri$split = uri.split(separator),
          _uri$split2 = _slicedToArray(_uri$split, 2),
          base = _uri$split2[0],
          rest = _uri$split2[1];

      return '' + String(base) + separator + String(params) + '/' + String(rest);
    }
  });
});