define('mx/v2/components/share-summary/addon/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The URI of the addon image.
     *
     * @type {string}
     * @default null
     * @memberof Components/ShareSummary/Addon
     */
    image: null,
    /**
     * The name of the addon.
     *
     * @type {string}
     * @default null
     * @memberof Components/ShareSummary/Addon
     */
    name: null,
    /**
     * The price of the addon.
     *
     * @type {number}
     * @default null
     * @memberof Components/ShareSummary/Addon
     */
    price: null,
    /**
     * The quantity of the addon.
     *
     * @type {string}
     * @default null
     * @memberof Components/ShareSummary/Addon
     */
    quantity: null
  });
});