define('mx/components/fresh-pilot/marketing/faqs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    constants: Ember.inject.service('constants'),
    phone: Ember.computed.alias('constants.phone.mx'),
    /**
     * Whether or not to show the FAQ collapsible section.
     *
     * @argument showFaq
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Marketing/Faqs
     */
    showFaq: false
  });
});