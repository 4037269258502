define('mx/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'mx/config/environment'], function (exports, _unauthenticatedRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    metrics: Ember.inject.service('metrics'),
    waldenMember: Ember.inject.service('member'),
    beforeModel: function beforeModel() {
      this.init();

      var waldenMember = this.get('waldenMember');
      waldenMember.unload();

      this._super.apply(this, arguments);
    },
    init: function init() {
      this._super();
      var self = this;
    },
    sessionReset: Ember.on('activate', function () {
      try {
        localStorage.removeItem('ember_simple_auth-session');
        localStorage.removeItem('ember_simple_auth:session');
      } catch (err) {
        console.log(err);
      }
    }),
    setupController: function setupController(controller) {
      controller.set('errorMessage', null);
      try {
        this._unsetUserEventId();
      } catch (err) {
        console.log('unable to unset user event id');
      }
      if (_environment.default.environment === 'production') {
        controller.set('hideLogin', true);
      }
    },
    _unsetUserEventId: function _unsetUserEventId() {
      var metrics = this.get('metrics');
      metrics.identify('GoogleAnalytics', {
        distinctId: null
      });
    }
  });
});