define('mx/models/v2-member', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Attributes
    active: _emberData.default.attr('boolean'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    legacyId: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    stripeToken: _emberData.default.attr('string'),
    emailConfirmation: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    referralCode: _emberData.default.attr('string'),
    referralNote: _emberData.default.attr('string'),
    serviceType: _emberData.default.attr('number'),
    sms: _emberData.default.attr('boolean', { default: false }),

    // Associations
    addresses: _emberData.default.hasMany('address')
  });
});