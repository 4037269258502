define('mx/routes/members/contact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    setupController: function setupController(controller, model) {
      var _this = this;

      this.init();
      if (typeof this.get('session').session.content.authenticated.token !== 'undefined') {
        var token = this.get('session').session.content.authenticated.token;
        var tokenData = JSON.parse(atob(token.split('.')[1]));
        if (tokenData.failed) {
          $(function () {
            $('.informational').show();
          });
        }

        this.get('ajax').request('/members/' + tokenData.userId + '/share-configs/current', {
          method: 'GET',
          headers: {
            token: token
          }
        }).then(function (response) {
          _newArrowCheck(this, _this);

          if (response.share_config.legacy !== null) {
            $(function () {
              $('.migrated-msg').show();
            });
          }
        }.bind(this));
      }
    }
  });
});