define('mx/routes/old-members', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    parameters: {},
    beforeModel: function beforeModel(transition) {
      console.error('WHAT IS THIS?');
      this.parameters.success = transition.queryParams.success;
      if (transition.queryParams.success) {
        fbq('track', 'CompletedRegistration');
      }
      this.init();
    },
    didTransition: function didTransition() {},
    init: function init() {
      this._super();
      var self = this;

      $(function () {
        // self.send('checkUserStatus');
        // self.send('getPromoContent');
        // self.send('getShareEstimate');

        // if (self.parameters.success) {
        //   $('#dashboard-meta').hide();
        //   $('#dashboard-content').hide();
        //   $('#success-banner').show();
        // }

        if ($(window).width() > 992) {
          // bootstrap 'lg' (large) window size
          $('#dash-main').height($(window).outerHeight() - 2 * $('#navigation-container').outerHeight() - $('#subnav-wrap').outerHeight() + 9);
        } else {
          $('#dash-main').height('inherit');
        }

        window.onresize = function () {
          if ($(window).width() > 992) {
            // bootstrap 'lg' (large) window size
            $('#dash-main').height($(window).outerHeight() - 2 * $('#navigation-container').outerHeight() - $('#subnav-wrap').outerHeight() + 9);
          } else {
            $('#dash-main').height('inherit');
          }
        };
      });
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      $(function () {
        self.resizeIndex();
        window.addEventListener('resize', self.resizeIndex);
      });
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      this.init();
      var self = this;
      if (!this.get('session').session.content.authenticated.token) this.transitionTo('/login');

      if (typeof this.get('session').session.content.authenticated.token !== 'undefined') {
        var token = this.get('session').session.content.authenticated.token;
        var tokenData = JSON.parse(atob(token.split('.')[1]));
        if (tokenData.failed) {
          $(function () {
            $('.informational').show();
          });
        }

        this.get('ajax').request('/members/' + tokenData.userId + '/share-configs/current', {
          method: 'GET',
          headers: {
            token: token
          }
        }).then(function (response) {
          _newArrowCheck(this, _this);

          if (response.share_config.legacy !== null) {
            $(function () {
              $('.migrated-msg').show();
            });
          }
        }.bind(this));
      }

      // var memId = JSON.parse(atob(self.get('session').session.content.authenticated.token.split('.')[1]).member);
      // self.get('ajac').request('/members/')
    }
    // actions: {
    //   checkUserStatus: function() {
    //     var self = this;
    //     var token = self.get('session').session.content.authenticated.token.split('.')[1];
    //     var tokenData = JSON.parse(atob(token));
    //     self
    //       .get('ajax')
    //       .request('/members/' + tokenData.userId, {
    //         method: 'GET',
    //         headers: {
    //           token: self.get('session').session.content.authenticated.token
    //         }
    //       })
    //       .then((response) => {});
    //   },
    //   getPromoContent: function() {
    //     var self = this;
    //     self
    //       .get('ajax')
    //       .request('/promos', {
    //         headers: {
    //           token: self.get('session').session.content.authenticated.token
    //         }
    //       })
    //       .then((response) => {
    //         var activePromo = response.promos[response.promos.length - 1];
    //         $('#promo-title').html(activePromo.title);
    //         $('#promo-content-p').html(activePromo.content);
    //         if (!activePromo.button) $('#promo-btn').hide();
    //         $('#promo-btn').html(activePromo.button_text);
    //         $('#promo-btn').attr('href', activePromo.button_uri);
    //         $('.slug').html(activePromo.image_meta);
    //         $('#dashboard-meta').css('background-image', 'url("' + activePromo.image_uri + '")');
    //       });
    //   },
    //   getShareEstimate: function() {
    //     var self = this;
    //     var token = self.get('session').session.content.authenticated.token;
    //     var memberId = JSON.parse(atob(token.split('.')[1]))['userId'];
    //     self
    //       .get('ajax')
    //       .request('/members/' + memberId + '/delivery-estimate/', {
    //         method: 'GET',
    //         headers: {
    //           token: token
    //         }
    //       })
    //       .then((response) => {
    //         self
    //           .get('ajax')
    //           .request('/members/' + memberId, {
    //             method: 'GET'
    //           })
    //           .then((member) => {
    //             if (response.success && member.member.active) {
    //               if (response.estimate.confidence == 2) {
    //                 $('.est-date').html(response['estimate'].human.date);
    //               } else if (response.estimate.confidence == 1) {
    //                 $('.est-date').html(response.estimate.human.date + '+/- 1 day');
    //               } else {
    //                 $('.est-date').html('Your share is still being routed');
    //               }
    //             } else if (!member.member.active) {
    //               $('.est-date').html('Your account is currently canceled.');
    //               $('.rough-wording').hide();
    //             } else if (member.member.service_type == 2) {
    //               $('.est-date').html(
    //                 'Your delivery can be picked up any time Monday-Friday 8am-6pm after you receive an email that your share is ready'
    //               );
    //               $('.rough-wording').hide();
    //             }
    //           });
    //       });
    //   }
    // }
  });
});