define('mx/v2/components/account/tracking-for/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    metrics: Ember.inject.service('metrics'),
    actions: {
      /**
       * Track focus of a field.
       *
       * @function actions:trackFocus
       * @memberof Components/Account/TrackingFor
       */
      trackFocus: function trackFocus(field) {
        if (field) {
          var metrics = this.get('metrics');
          var payload = {};
          payload.category = 'account_information';
          payload.action = 'Focus Input';
          payload.label = field;
          metrics.trackEvent('GoogleAnalytics', payload);
        }
      },

      /**
       * Track click of an element.
       *
       * @function actions:trackClick
       * @memberof Components/Account/TrackingFor
       */
      trackClick: function trackClick(elem) {
        if (elem) {
          var metrics = this.get('metrics');
          var payload = {};
          payload.category = 'account_information';
          payload.action = 'Click';
          payload.label = elem;
          metrics.trackEvent('GoogleAnalytics', payload);
        }
      },

      /**
       * Track selection of an option.
       *
       * @function actions:trackSelect
       * @memberof Components/Account/TrackingFor
       */
      trackSelect: function trackSelect(field, opt) {
        if (field) {
          var metrics = this.get('metrics');
          var payload = {};
          payload.category = 'account_information';
          payload.action = 'Select ' + String(field);
          payload.label = opt || 0;
          metrics.trackEvent('GoogleAnalytics', payload);
        }
      },

      /**
       * Track a data point.
       *
       * @function actions:trackDataPoint
       * @memberof Components/Account/TrackingFor
       */
      trackDataPoint: function trackDataPoint(metric, data) {
        if (metric && data) {
          var metrics = this.get('metrics');
          var payload = {};
          payload.category = 'member_informatiton';
          payload.action = metric;
          payload.label = data;
          metrics.trackEvent('GoogleAnalytics', payload);
        }
      }
    }
  });
});