define('mx/helpers/isodd', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isodd = isodd;
  function isodd(params /*, hash*/) {
    return params[0] % 2;
  }

  exports.default = Ember.Helper.helper(isodd);
});