define('mx/v2/components/sign-up/step/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The instance of a sign-up step.
     *
     * @type {SignUpStep}
     * @default null
     * @memberof Components/SignUp/Step
     */
    step: null
  });
});