define('mx/models/service-date', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    calendar_date: _emberData.default.attr('string'),
    human_date: Ember.computed('calendar_date', function () {
      return (0, _moment.default)(this.get('calendar_date')).calendar();
    })
  });
});