define('mx/components/share-config/dietary-restriction-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     *  meatType model instance
     *
     *  @argument
     *  @type {Models/DietaryRestriction}
     *  @memberof Components/ShareConfig/DietaryRestrictionContainer
     */
    dietaryRestriction: {},
    /**
     *  selected state of the dietaryRestriction container
     *
     *  @argument
     *  @type {boolean}
     *  @memberof Components/ShareConfig/DietaryRestrictionContainer
     */
    selected: false,
    /**
     *  click closure action, to be called onClick
     *
     *  @argument
     *  @type {function|null}
     *  @memberof Components/ShareConfig/DietaryRestrictionContainer
     */
    onClick: null,
    tooltipOpts: {
      modifiers: {
        preventOverflow: {
          escapeWithReference: false
        }
      }
    },
    /**
     *  Handle click events
     *
     *  @override
     *  @function click
     *  @param {Event} e - dom event onClick
     *  @memberof COmponents/ShareConfig/DietaryRestrictionContainer
     */
    click: function click(e) {
      if (e) e.preventDefault();
      var onClick = Ember.get(this, 'onClick');
      if (onClick) onClick();
    }
  });
});