define('mx/helpers/moment-month', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  /**
   * Converts a date to a 'month' formatted string.
   * @param {Object[]} params - Helper params
   * @param {Object|string} params[].0 - Date to format
   * @returns {string} 'month' ('MMMM') formatted string
   * @memberof helpers
   * @example
   * // returns April
   * momentMonthYear('04-10-2018')
   * @example
   * // returns December
   * momentMonthYear(moment().year(2017).month(11))
   */
  var momentMonth = function (params /*, hash*/) {
    _newArrowCheck(undefined, undefined);

    return (0, _moment.default)().month(params[0]).format('MMMM');
  }.bind(undefined);

  exports.default = Ember.Helper.helper(momentMonth);
});