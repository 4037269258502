define('mx/serializers/walden-api', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadType) {
      payloadType = Ember.String.dasherize(payloadType.replace('v2', ''));
      payloadType = 'v2-' + String(payloadType);
      return this._super(payloadType);
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return Ember.String.underscore(modelName.replace('v2-', ''));
    },
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    keyForRelationship: function keyForRelationship(key, relationship, method) {
      return Ember.String.underscore(key);
    }
  });
});