define('mx/components/core/panel-full', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['wd-panel-full'],
    subId: null,
    shrinkTo: null,
    didInsertElement: function didInsertElement() {
      var self = this;

      $(document).ready(function () {
        self.setHeight(self.element);

        $(window).resize(function () {
          self.setHeight(self.element);
        });
      });
    },
    setHeight: function setHeight(element) {
      var wd_w = $(window).innerWidth();

      if (wd_w < 993) {
        $(element).css('min-height', '');
        $(element).css('height', String(this.get('shrinkTo')) + 'px');
      } else {
        var wd_h = $(window).innerHeight();
        var footer_ht = $('#footer').outerHeight();

        var nav = $('#main-nav');
        var nav_ht = nav.outerHeight();

        var header_ht = nav_ht;

        var new_ht = wd_h - header_ht - footer_ht;

        $(element).css('height', '');
        $(element).css('min-height', new_ht + 'px');
      }
    }
  });
});