define('mx/v2/components/mx/tab-manager/tab/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['tabStyles'],
    /**
     * The current styles for the tab depending on active state.
     *
     * @type {function}
     * @returns {string} Class names to apply to the element.
     * @memberof Components/Mx/TabManager/Tab
     */
    tabStyles: Ember.computed('tabId', 'selectedTabId', function () {
      return !Ember.get(this, 'selected') ? String(Ember.get(this, 'styleNamespace')) + '--state-inactive' : '';
    }).readOnly(),
    /**
     * Whether or not the tab is currently selected.
     *
     * @type {function}
     * @returns {boolean} Whether or not the tab is selected.
     * @memberof Components/Mx/TabManager/Tab
     */
    selected: Ember.computed('tabId', 'selectedTabId', function () {
      return Ember.get(this, 'tabId') === Ember.get(this, 'selectedTabId');
    }).readOnly(),
    /**
     * Closure to invoke when the tab is clicked.
     *
     * @type {function}
     * @default null
     * @memberof Components/Mx/TabManager/Tab
     */
    onClick: null,
    /**
     * Invoke the 'onClick' closure if present.
     *
     * @override
     * @function click
     * @memberof Components/Mx/TabManager/Tab
     */
    click: function click(e) {
      e.preventDefault();
      if (Ember.get(this, 'onClick')) Ember.get(this, 'onClick')();
    }
  });
});