define('mx/models/referral-lookup', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    first: _emberData.default.attr('string'),
    last: _emberData.default.attr('string'),
    code: _emberData.default.attr('string')
  });
});