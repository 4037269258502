define('mx/models/atk-recipe-instruction', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /** Attributes */
    // Contains the complete instruction text.
    content: (0, _attr.default)('string')
  });
});