define('mx/components/share-config/share-types-mobile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['share-types-mobile'],
    media: Ember.inject.service('media'),
    /**
     *  The index of the mobile shareType slider
     *
     *  @type {number}
     *  @memberof Components/ShareConfig/ShareTypesMobile
     */
    mobileSlideIndex: 0,
    /**
     *  Swiper Configuration Breakpoint options
     *
     *  @type {object}
     *  @memberof Components/ShareConfig/ShareTypesMobile
     */
    sliderBreakPoints: {
      767: {
        slidesPerView: 1
      },
      991: {
        slidesPerView: 3
      }
    },
    /**
     *  Set the mobile shareType slider index
     *  to the corresponding selected target index
     *
     *  @override
     *  @function lifecycle:didInsertElement
     *  @memberof Components/Shareconfig/ShareTypes
     */
    didInsertElement: function didInsertElement() {
      var target = Ember.get(this, 'target');
      Ember.set(this, 'mobileSlideIndex', Ember.get(target, 'id') - 1);
    },

    actions: {
      /**
       *  Update shareType onClick of swiper slides
       *
       *  @function actions:updateShareTypeMobile
       *  @param {Models/ShareType} type - shareType model instance
       *  @memberof Components/ShareConfig/ShareTypes
       */
      updateShareTypeMobile: function updateShareTypeMobile(type) {
        var typeSliderIndex = Ember.get(type, 'id') - 1;
        var updateShareType = Ember.get(this, 'updateShareType');

        Ember.set(this, 'mobileSlideIndex', typeSliderIndex);
        updateShareType(type);
      }
    }
  });
});