define('mx/v2/components/sign-up-review/tracking-for/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    actions: {
      /**
       * Track when the user is to be shown the billing info step.
       *
       * @function actions:paymentInfo
       * @memberof Components/SignUpReview/TrackingFor
       */
      paymentInfo: function paymentInfo() {
        this.get('metrics').trackEvent('FacebookPixel', {
          event: 'AddPaymentInfo'
        });
        this.get('metrics').trackEvent('GoogleAnalytics', {
          category: 'onboarding',
          action: 'add_payment_info'
        });
      }
    }
  });
});