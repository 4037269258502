define('mx/v2/components/atk-recipes/overview-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mx-atk-recipe-overview-card'],
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkRecipes/OverviewCard
     */
    author: null,
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkRecipes/OverviewCard
     */
    photo: null,
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkRecipes/OverviewCard
     */
    servings: null,
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkRecipes/OverviewCard
     */
    text: null,
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkRecipes/OverviewCard
     */
    title: null,
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkRecipes/OverviewCard
     */
    type: null
  });
});