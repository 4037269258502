define('mx/v2/components/x-recipe/instruction-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mx-x-recipe-instruction-list'],
    /**
     * A list of any type.
     * This gives the consumer complete control of what to display for each instruction.
     *
     * @type {Array.<*>}
     * @default null
     * @memberof Components/XRecipeIngredientList
     */
    instructions: null
  });
});