define('mx/v2/components/specials/nav-bar/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mx-specials-navbar-item'],
    classNameBindings: ['inactiveClass'],
    /**
     * The namespaced classes for an inactive nav item.
     *
     * @function inactiveClass
     * @returns {String} Namespaced class.
     * @memberof Components/Specials/NavBar/Item
     */
    inactiveClass: Ember.computed('active', function () {
      return !Ember.get(this, 'active') ? 'mx-specials-navbar-item-inactive' : '';
    })
  });
});