define('mx/controllers/sign-up/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend({
    // Services.
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    media: Ember.inject.service('media'),
    actions: {
      /**
       * Launch the remainder of sign-up after entering a serviceable zip.
       *
       * @function action:continueSignUp
       * @params zip {Models/Zip} The Zip model corresponding to the entered zip code.
       * @memberof Controllers/SignUp/Index
       */
      continueSignUp: function continueSignUp(zip) {
        // Attempt to store the entered zip code in localStorage.
        try {
          localStorage.setItem('walden_zip', JSON.stringify(zip.toJSON({ includeId: true })));
        } catch (err) {}
        // Ok if this fails, you'll still be able to enter your zip in the account page of sign-up.

        // Check if we can provide referral code query parameter.

        var _EmberGet$peekAll$to = Ember.get(this, 'store').peekAll('referral-lookup').toArray(),
            _EmberGet$peekAll$to2 = _slicedToArray(_EmberGet$peekAll$to, 1),
            referralData = _EmberGet$peekAll$to2[0];

        if (referralData) {
          this.transitionToRoute('sign-up.share', { queryParams: { referral: referralData.get('code') } });
        } else {
          this.transitionToRoute('sign-up.share');
        }
      }
    }
  });
});