define('mx/models/atk-recipe-tag', ['exports', 'ember-data/model', 'ember-data/relationships'], function (exports, _model, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /** Associations */
    tag: (0, _relationships.belongsTo)('atk-tag')
  });
});