define('mx/components/main-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    fresh: Ember.inject.service('fresh-pilot'),
    actions: {
      logout: function logout() {
        this.sendAction('logout');
        // this.get('session').invalidate();
        // this.transitionToRoute('/login');
      }
    }
  });
});