define('mx/router', ['exports', 'mx/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service('metrics'),
    session: Ember.inject.service('session'),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      var sessionData = null;
      try {
        var token = this.get('session').session.content.authenticated.token;
        var tokenData = JSON.parse(atob(token.split('.')[1]));
        sessionData = tokenData.userId;
        this.set('metrics.context.userName', sessionData);
      } catch (error) {}

      Ember.run.scheduleOnce('afterRender', this, function () {
        _newArrowCheck(this, _this);

        var page = this.get('url');
        var title = this.getWithDefault('currentRouteName', 'unknown');
        this.get('metrics').trackPage({ page: page, title: title });
      }.bind(this));
    }
  });

  Router.map(function () {
    this.route('login');
    this.route('forgot-password');
    this.route('sign-up', { path: '/sign-up' }, function () {
      this.route('share', { path: '/share' });
      this.route('account', { path: '/account' });
      this.route('share-loading');
      this.route('addons');
    });
    this.route('members', { path: '/' }, function () {
      this.route('next-share', { path: '/next-share' });
      this.route('preferences', { path: '/preferences' });
      this.route('monthly-addons', { path: '/monthly-addons' });
      this.route('account', { path: '/account' });
      this.route('contact', { path: '/contact' });
      this.route('turkey-tips', { path: '/turkey-tips' });
      this.route('waitlist-landing', { path: '/waitlist-landing' });
      this.route('seasonal', { path: '/seasonal' }, function () {
        this.route('build');
      });
      this.route('fresh', { path: '/fresh' }, function () {
        this.route('next-delivery', { path: '/next-delivery' });
      });
      this.route('preferences-loading');
      this.route('next-share-loading');
      this.route('recipes', { path: '/ci' }, function () {
        // Until there are separate pages for recipes and guides respectively,
        // this configuration is needed as to allow for routes of individual atk entities.
        this.route('recipe', { path: '/recipes/:recipe_id' });
        this.route('guide', { path: '/guides/:guide_id' });
      });
    });
    // Wildcard route to catch any attempts to access an unknown route.
    this.route('unknown', { path: '/*path' });
  });

  exports.default = Router;
});