define('mx/v2/components/x-recipe/ingredient-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mx-x-recipe-ingredient-list'],
    /**
     * A list of any type.
     * This gives the consumer complete control of what to display for each ingredient.
     *
     * @type {Array.<*>}
     * @default null
     * @memberof Components/XRecipe/IngredientList
     */
    ingredients: null
  });
});