define('mx/serializers/pilot-member', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    /**
     * Underscore all object keys for the outgoing payload.
     *
     * @override
     * @method keyForAttribute
     * @memberof Serializers/Pilot-Member
     * @returns {string} Attribute name.
     */
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.underscore(key);
    },

    /**
     * Underscore the model name in the payload.
     * The API expects 'pilot_member' instead of 'pilot-member' as the root
     * key of the payload body.
     *
     * @override
     * @method payloadKeyFromModelName
     * @memberof Serializers/Pilot-Member
     * @returns {string} Model name in root of payload.
     */
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return Ember.String.underscore(modelName);
    }
  });
});