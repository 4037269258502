define('mx/components/share-config/share-sizes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    _cleanupRoasts: function _cleanupRoasts(size) {
      var inclusions = Ember.get(this, 'inclusions');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = inclusions.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var inclusion = _step.value;

          if (Ember.get(inclusion, 'isRoast')) {
            inclusions.removeObject(inclusion);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },

    actions: {
      /**
       *  Update the passed 'target' to reflect the
       *  selected value
       *
       *  @function actions:updateShareSize
       *  @param {number} sizeId - ID if the selected shareSize
       *  @memberof Components/ShareConfig/ShareSizes
       */
      updateShareSize: function updateShareSize(selectedSize) {
        if (Ember.get(selectedSize, 'weightLower') < 10) {
          this._cleanupRoasts();
        }
        Ember.set(this, 'target', selectedSize);
      }
    }
  });
});