define('mx/v2/atk/service', ['exports', 'mx/v2/classes/atk-content-group', 'mx/v2/classes/atk-content-category'], function (exports, _atkContentGroup, _atkContentCategory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Service.extend({
    /**
     * The available content categories for a group.
     *
     * @type {Array.<AtkContentCategory>}
     * @default null
     * @memberof Services/Atk
     */
    contentCategories: null,
    /**
     * The atk content groups.
     * A content group pertains to a protein type, and may contain recipes and or guides.
     *
     * @type {Array.<AtkContentGroup>}
     * @default null
     * @memberof Services/Atk
     */
    groups: null,
    /**
     * The set of ATK-related images that have been loaded by this service.
     * This is used to determine if a route should be halted in to load an image before rendering.
     * This is also used to allow components to show a fallback image while loading an image.
     *
     * @type {Map}
     * @default null
     * @memberof Services/Atk
     */
    loadedImages: null,
    /**
     * The currently selected content category.
     *
     * @type {AtkContentCategory}
     * @default null
     * @memberof Services/Atk
     */
    selectedCategory: null,
    /**
     * The currently selected content group.
     *
     * @type {AtkContentGroup}
     * @default null
     * @memberof Services/Atk
     */
    selectedGroup: null,
    /**
     * Derives atk content groups from a provided set of atk recipes and cut guides.
     *
     * @function _makeRecipeGroups
     * @param {Array.<AtkRecipe>} recipes Atk recipes.
     * @param {Array.<AtkCutGuide>} guides Atk cut guides.
     * @returns {Array.<AtkContentGroup>} Derived content groups.
     * @memberof Services/Atk
     */
    _makeRecipeGroups: function _makeRecipeGroups(recipes, guides) {
      var _this = this;

      // Allowed recipe groups.
      var groupTitles = ['Beef', 'Pork', 'Chicken', 'Lamb', 'Fish'];
      // Create a map entry for each group and their associated entities.
      var groupEntries = groupTitles.map(function (title) {
        _newArrowCheck(this, _this);

        return [title, { recipes: [], guides: [] }];
      }.bind(this));
      // Use an ES6 Map for guarenteed key order instead of needing a display order prop on groups.
      var groupMap = new Map(groupEntries);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = recipes.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var recipe = _step.value;

          var groupLookup = Ember.get(recipe, 'proteinName');
          if (groupMap.get(groupLookup)) {
            groupMap.get(groupLookup).recipes.push(recipe);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = guides.toArray()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var guide = _step2.value;

          var groupLookup = Ember.get(guide, 'proteinName');
          if (groupMap.get(groupLookup)) {
            groupMap.get(groupLookup).guides.push(guide);
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return Array.from(groupMap).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            title = _ref2[0],
            entities = _ref2[1];

        _newArrowCheck(this, _this);

        return _atkContentGroup.default.create({
          guides: Ember.A(entities.guides),
          photo: this._makeImageUriForGroup(title),
          recipes: Ember.A(entities.recipes),
          title: title
        });
      }.bind(this));
    },

    /**
     * Derives an image URI from a content group's title.
     *
     * @function _makeImageUriForGroup
     * @param {String} title Group title.
     * @returns {String} Image URI.
     * @memberof Services/Atk
     */
    _makeImageUriForGroup: function _makeImageUriForGroup(title) {
      var _this2 = this;

      var makeImageUri = function (file) {
        _newArrowCheck(this, _this2);

        return '/assets/animals/' + String(file);
      }.bind(this);
      switch (title) {
        case 'Beef':
          return makeImageUri('WaldenCowold.svg');
        case 'Pork':
          return makeImageUri('WaldenPigold.svg');
        case 'Chicken':
          return makeImageUri('Waldenchickenold.svg');
        case 'Lamb':
          return makeImageUri('old.svg');
        case 'Fish':
          return makeImageUri('WaldenFish.svg');
        default:
          return null;
      }
    },

    /**
     * Resets the state of the service with a provided set of recipes and cut guides.
     *
     * @function resetGroups
     * @param {Array.<AtkRecipe>} recipes Atk recipes.
     * @param {Array.<AtkCutGuide>} guides Atk cut guides.
     * @memberof Services/Atk
     */
    resetGroups: function resetGroups(recipes, guides) {
      var groups = Ember.A(this._makeRecipeGroups(recipes, guides));
      var contentCategories = Ember.A([_atkContentCategory.default.create({ title: 'Summer Recipes', type: 'Recipes', queryParam: 'recipes' }), _atkContentCategory.default.create({ title: 'Cut Guides', type: 'Cut Guides', queryParam: 'guides' })]);
      var selectedCategory = contentCategories.objectAt(0);
      var selectedGroup = groups.objectAt(0);
      Ember.setProperties(this, { contentCategories: contentCategories, groups: groups, selectedCategory: selectedCategory, selectedGroup: selectedGroup });
    },

    /**
     * Set a selected content category from a provided query param.
     *
     * @function selectCategoryFromQueryParam
     * @param {string} param Query param.
     * @memberof Services/Atk
     */
    selectCategoryFromQueryParam: function selectCategoryFromQueryParam(param) {
      var categories = Ember.get(this, 'contentCategories');
      var category = categories.findBy('queryParam', param);
      if (!category) {
        category = categories.findBy('queryParam', 'recipes');
      }
      Ember.set(this, 'selectedCategory', category);
    },

    /**
     * Whether or not we've loaded a particular image yet.
     *
     * @function didLoadImage
     * @param {string} uri Image URI.
     * @returns {boolean} Whether or not the image has been loaded.
     * @memberof Services/Atk
     */
    didLoadImage: function didLoadImage(uri) {
      return Ember.get(this, 'loadedImages').has(uri);
    },

    /**
     * Whether or not we should load a particular image.
     *
     * @function shouldLoadImage
     * @param {string} uri Image URI.
     * @returns {boolean} Whether or not we should load the image.
     * @memberof Services/Atk
     */
    shouldLoadImage: function shouldLoadImage(uri) {
      return uri && Ember.get(uri, 'length') && !this.didLoadImage(uri);
    },

    /**
     * Load an image directly into the browser's cache.
     *
     * @function loadImage
     * @param {string} uri Image URI.
     * @returns {Promise} Resolves if the image was loaded, otherwise rejects.
     * @memberof Services/Atk
     */
    loadImage: function loadImage(uri) {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        _newArrowCheck(this, _this3);

        var i = new Image();
        i.onload = resolve;
        i.onerror = reject;
        i.src = uri;
      }.bind(this)).then(function () {
        _newArrowCheck(this, _this3);

        return Ember.get(this, 'loadedImages').set(uri, true);
      }.bind(this));
    },

    /**
     * @override
     * @function lifecycle:init
     * @memberof Services/Atk
     */
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'loadedImages', new Map());
    },

    actions: {
      /**
       * Set the selected content group category.
       *
       * @function actions:selectCategory
       * @param {AtkContentCategory} category Group item category.
       * @memberof Services/Atk
       */
      selectCategory: function selectCategory(category) {
        Ember.set(this, 'selectedCategory', category);
      },

      /**
       * Set the selected content group.
       *
       * @function actions:selectGroup
       * @param {AtkContentGroup} group Content group.
       * @memberof Servics/Atk
       */
      selectGroup: function selectGroup(group) {
        Ember.set(this, 'selectedGroup', group);
      },

      /**
       * View a recipe.
       *
       * @function actions:viewRecipe
       * @param {AtkRecipe} recipe Recipe to view.
       * @memberof Services/Atk
       */
      viewRecipe: function viewRecipe(recipe) {
        Ember.getOwner(this).lookup('router:main').transitionTo('members.recipes.recipe', recipe);
      },

      /**
       * View a cut guide.
       *
       * @function actions:viewGuide
       * @param {AtkCutGuide} guide Guide to view.
       * @memberof Services/Atk
       */
      viewGuide: function viewGuide(guide) {
        Ember.getOwner(this).lookup('router:main').transitionTo('members.recipes.guide', guide);
      }
    }
  });
});