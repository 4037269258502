define('mx/v2/components/specials/nav-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mx-specials-navbar'],
    /**
     * The list of Addon Groups to display.
     * TODO: Use store models instead of raw objects.
     *
     * @type {Array.<object>}
     * @default null
     * @memberof Components/Specials/NavBar
     */
    groups: null,
    /**
     * The currently selected addon group.
     *
     * @type {object}
     * @default null
     * @memberof Components/Specials/NavBar
     */
    selectedGroup: null,
    /**
     * Closure to invoke when a nav item is clicked.
     *
     * @type {function}
     * @default null
     * @memberof Components/Specials/NavBar
     */
    onClickGroup: null
  });
});