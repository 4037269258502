define('mx/v2/components/share-summary/addons/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * List of share addons to display.
     *
     * @type {Array.<Models/Addon>}
     * @default null
     * @memberof Components/ShareSummary/Addons
     */
    shareAddons: null
  });
});