define('mx/models/atk-recipe-ingredient-group', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /** Attributes */
    // E.g. 'Croutons'.
    title: (0, _attr.default)('string'),
    /** Associations */
    recipeIngredients: (0, _relationships.hasMany)('atk-recipe-ingredient')
  });
});