define('mx/components/share-config/cut-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     *  Handle cutGroup click event
     *
     *  @function click
     *  @param {Event} e - Natural DOM click event
     */
    click: function click(e) {
      if (e) e.preventDefault();
      var onChange = Ember.get(this, 'onChange');
      if (onChange) onChange();
    }
  });
});