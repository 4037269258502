define('mx/serializers/v2-addon', ['exports', 'mx/serializers/walden-api', 'ember-data'], function (exports, _waldenApi, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _waldenApi.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      children: { key: 'children', deserialize: 'records', serialize: 'records' }
    },
    // Taking some load off of the API for now in order to create this addon
    // parent child relationship
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var addons = payload.addons;

      var _loop = function _loop(addon) {
        if (addon.parent) {
          var parent = addons.filter(function (parentAddon) {
            return parentAddon.id === addon.parent;
          });
          if (parent.length) {
            parent[0].children = addon;
          }
        }
      };

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = addons[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var addon = _step.value;

          _loop(addon);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});