define('mx/components/sign-up/zip-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  var $ = Ember.$;

  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service('store'),
    metrics: Ember.inject.service('metrics'),
    captcha: {},
    zip: {},
    didInsertElement: function didInsertElement() {
      this.init();
      this.get('metrics').trackEvent('FacebookPixel', {
        event: 'Lead'
      });
      console.log('sentlead');
      this.get('metrics').trackEvent('GoogleAnalytics', {
        category: 'onboarding',
        action: 'lead'
      });
    },
    init: function init() {
      var self = this;
      this._super();

      $(document).on('click', '#get-started-continue', function (e) {
        if (e) e.preventDefault();
        self.zip.code = $('#zip-code-entry').val();
        self.send('validateZip');
      });

      $(document).on('click', '#get-started-email-notify', function (e) {
        if (e) e.preventDefault();
        self.send('notify');
      });

      $(function () {
        $('#zip-code-entry').keyup(function (e) {
          if (e) e.preventDefault();
          if (e) e.stopImmediatePropagation();
          if (e) e.stopPropagation();

          if (e.keyCode == '13') {
            e.preventDefault();
            self.send('validateZip');
          }

          return false;
        });

        $('#zip-code-email').keyup(function (e) {
          if (e) e.preventDefault();

          if (e.keyCode == '13') self.send('notify');
        });
      });
    },
    actions: {
      reEnterZip: function reEnterZip() {
        $('#get-started-zip-entry').show();
        $('#get-started-nonservice').hide();
        $('#zip-code-entry').val('');
        $('#zip-code-email').val('');
      },

      validateZip: function validateZip() {
        var _this = this;

        $('.loading').hide();
        var self = this;
        self.zip.code = $('#zip-code-entry').val();

        this.get('ajax').request('service/zips', {
          method: 'GET',
          data: {
            code: $('#zip-code-entry').val(),
            active: '1'
          }
        }).then(function (response) {
          _newArrowCheck(this, _this);

          console.log(response);

          if (response.meta.total !== 0) {
            localStorage.setItem('walden_zip', JSON.stringify(response.zips[0]));
            self.get('store').pushPayload(response);

            return self.send('validationResponse', true);
          } else {
            console.log('no zip');
            // self.get('gRecaptcha').resetReCaptcha();
            $('#get-started-zip-entry').hide();
            $('#get-started-nonservice').show();

            return self.send('validationResponse', false);
          }
        }.bind(this));
      },
      validationResponse: function validationResponse(success) {
        console.log('sending action: continue sign-up');
        if (success) this.sendAction('continueSignUp', success);
      },

      captchaSuccess: function captchaSuccess(token) {
        console.log('captcha');
        this.captcha.token = token;
        console.log('token' + token);
      },

      notify: function notify(email) {
        var _this2 = this;

        var self = this;
        // grecaptcha.execute();
        this.get('ajax').request('waitlist-guests', {
          method: 'POST',
          data: {
            email: $('#zip-code-email').val(),
            recaptcha: '', //self.captcha.token
            zip: self.zip.code,
            newsletter: true
          }
        }).then(function (response) {
          _newArrowCheck(this, _this2);

          $('#get-started-nonservice').hide();
          $('#get-started-nonservice-success').show();
        }.bind(this));

        $('#get-started-nonservice').hide();
        $('#get-started-nonservice-success').show();
      }
    }
  });
});