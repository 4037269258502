define('mx/v2/constants/service', ['exports', 'mx/v2/constants/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Service.extend({
    /**
     * The collection of client-facing phone numbers.
     *
     * @function
     * @returns {object} Contains a property for each Walden phone number.
     * @memberof Services/Constants
     */
    phone: Ember.computed({
      get: function get() {
        return { mx: _constants.default.MX_PHONE_NUMBER, butcher: _constants.default.BUTCHER_PHONE_NUMBER };
      }
    }).readOnly(),
    /**
     * The credit to display for ATK-related content.
     *
     * @function
     * @returns {string} ATK credit.
     * @memberof Services/Constants
     */
    atkCredit: Ember.computed({
      get: function get() {
        return _constants.default.ATK_RECIPE_CREDIT;
      }
    }).readOnly(),
    /**
     * The collection of S3 buckets that contain Walden content.
     *
     * @function
     * @returns {object} Contains a property for each bucket URL.
     * @memberof Services/Constants
     */
    s3: Ember.computed({
      get: function get() {
        var _this = this;

        var makeBucketUrl = function (bucket) {
          _newArrowCheck(this, _this);

          return 'https://' + String(bucket) + '.s3.amazonaws.com';
        }.bind(this);
        return { staticAssets: makeBucketUrl(_constants.default.S3_STATIC_ASSETS_BUCKET) };
      }
    }).readOnly(),
    /**
     * The location of the JSON file containing zip codes of the active mailer campaign.
     *
     * @function mailerZipsFile
     * @returns {string} File path.
     * @memberof Services/Constants
     */
    mailerZipsFile: Ember.computed({
      get: function get() {
        return String(Ember.get(this, 's3.staticAssets')) + '/mailer_zips.json';
      }
    }).readOnly(),
    /**
     * Promotional texts.
     *
     * @function promoTexts
     * @returns {object} Promo texts.
     * @memberof Service/Constants
     */
    promoTexts: Ember.computed({
      get: function get() {
        return {
          baconEggs: _constants.default.BACON_AND_EGGS_PROMO_TEXT,
          breakfastSausage: _constants.default.BREAKFAST_SAUSAGE_PROMO_TEXT
        };
      }
    }).readOnly(),
    /**
     * Thanksgiving images.
     *
     * @function thx
     * @returns {object} Image uris.
     * @memberof Services/Constants
     */
    thx: Ember.computed({
      get: function get() {
        return {
          bannerImage: String(Ember.get(this, 's3.staticAssets')) + '/images/HP_TurkeyImage_1200x574_REV1104.png'
        };
      }
    }).readOnly()
  });
});