define('mx/models/v2-addon', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Attributes
    active: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    discountMap: _emberData.default.attr('raw'),
    display: _emberData.default.attr('string'),
    displayOrder: _emberData.default.attr('number'),
    displaySection: _emberData.default.attr('number'),
    displayType: _emberData.default.attr('number'),
    imageUri: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    legacyName: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number', { defaultValue: 0 }),
    quantityMax: _emberData.default.attr('number'),
    recurring: _emberData.default.attr('boolean'),
    sublabel: _emberData.default.attr('string'),
    unitPlural: _emberData.default.attr('string'),
    unitSingular: _emberData.default.attr('string'),
    weighed: _emberData.default.attr('boolean'),

    // Associations
    // Reflexive Relationship
    // (https://guides.emberjs.com/v2.6.0/models/relationships/#toc_reflexive-relations)
    children: _emberData.default.belongsTo('v2-addon', { inverse: null, async: false })
  });
});