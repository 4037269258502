define('mx/helpers/eq', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  /**
   * Checks for equality between two values via implicit coercion.
   * @param {Object[]} params - Helper params
   * @param {string|number} params[].0 - First value
   * @param {string|number} params[].1 - Second value
   * @returns {boolean} Result of equality comparison
   * @memberof helpers
   * @example
   * // returns true
   * eq(25, '25')
   * @example
   * // returns false
   * eq('ok', 10)
   */
  var eq = function (params) {
    _newArrowCheck(undefined, undefined);

    return params[0] == params[1];
  }.bind(undefined);
  exports.default = Ember.Helper.helper(eq);
});