define('mx/components/fresh-pilot/pilot-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * Whether or not to show the marketing content for a waitlisted pilot member.
     *
     * @argument waitlised
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Pilot-Content
     */
    waitlised: false,
    /**
     * Loading state for the opt out button.
     *
     * @argument loading
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Pilot-Content
     */
    loading: false,
    /**
     * Whether or not an error occurred when attempting to opt out the pilot member.
     *
     * @argument error
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Pilot-Content
     */
    error: false,
    /**
     * The provided reason for opting out.
     *
     * @argument optOutReason
     * @type {string}
     * @default null
     * @memberof Components/Fresh-Pilot/Pilot-Content
     */
    optOutReason: null,
    /**
     * The marketing content to show. If the pilot member is waitlisted, the waitlisted
     * content shows. Otherwise, the active content shows.
     *
     * @computed
     * @type {string}
     * @returns {string} The name of the marketing content component to show.
     * @memberof Components/Fresh-Pilot/Pilot-Content
     */
    postOptInMarketing: Ember.computed('waitlisted', function () {
      var component = 'fresh-pilot/marketing/waitlist';
      if (!this.get('waitlisted')) component = 'fresh-pilot/marketing/active';
      return component;
    }),
    /**
     * Whether or not to show the skip button. The skip button should only be shown if the
     * pilot member is active.
     *
     * @computed
     * @type {boolean}
     * @returns {boolean} Whether or not to show the skip button.
     * @memberof Components/Fresh-Pilot/Pilot-Content
     */
    showSkip: Ember.computed('waitlisted', function () {
      return !this.get('waitlisted');
    }),
    /**
     * Instantiate the skip and opt out modal upon inserting this component into the DOM.
     *
     * @override
     * @method lifecycle:didInsertElement
     * @memberof Components/Fresh-Pilot/Pilot-Content
     */
    didInsertElement: function didInsertElement() {
      $('#optOutModal').modal({ show: false });
      $('#skipModal').modal({ show: false });
    },

    /**
     * Destroy the skip and opt out modals when this component gets destroyed.
     *
     * @override
     * @method lifecycle:didDestroyElement
     * @memberof Components/Fresh-Pilot/Pilot-Content
     */
    didDestroyElement: function didDestroyElement() {
      $('#optOutModal').modal('dispose');
      $('#skipModal').modal('dispose');
    },

    actions: {
      /**
       * Show the opt out modal.
       *
       * @method action:loadOptOutModal
       * @memberof Components/Fresh-Pilot/Pilot-Content
       */
      loadOptOutModal: function loadOptOutModal() {
        $('#optOutModal').modal('show');
      },

      /**
       * Show the skip modal.
       *
       * @method action:loadSkipModal
       * @memberof Components/Fresh-Pilot/Pilot-Content
       */
      loadSkipModal: function loadSkipModal() {
        $('#skipModal').modal('show');
      }
    }
  });
});