define('mx/serializers/atk', ['exports', 'ember-data/serializers/rest'], function (exports, _rest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _rest.default.extend({
    /**
     * Update the payload key to reflect the name of the corresponding ATK model.
     *
     * @override
     * @function modelNameFromPayloadKey
     * @param {string} payloadType The key in the payload.
     * @memberof Serializers/Atk
     */
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadType) {
      return this._super('atk-' + String(Ember.String.dasherize(payloadType)));
    },

    /**
     * @override
     * @memberof Serializers/Atk
     */
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },

    /**
     * @override
     * @memberof Serializers/Atk
     */
    keyForRelationship: function keyForRelationship(key, relationship, method) {
      return Ember.String.underscore(key);
    }
  });
});