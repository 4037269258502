define('mx/components/dashboard-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['wd-dashboard-content'],
    bgLoaded: false,
    didInsertElement: function didInsertElement() {
      var element = this.element;
      var self = this;
      $(document).ready(function () {
        var dash_img_url = 'https://s3.amazonaws.com/walden-static-assets/images/sign_up_welcome-min.png';

        $('<img/>').attr('src', dash_img_url).on('load', function () {
          $(this).remove(); // prevent memory leaks as @benweet suggested
          $('.wd-dashboard-content-inner').parent().css('background-image', 'url(' + dash_img_url + ')');
          self.set('bgLoaded', true);
        });

        self.resizeContent(element);

        $(window).resize(function () {
          self.resizeContent(element);
        });
      });
    },
    resizeContent: function resizeContent(ele) {
      // available height
      var wd_h = $(window).innerHeight();
      var wd_w = $(window).innerWidth();

      var avail_ht = wd_h;

      // nav height
      var nav = $('#main-nav');
      var nav_toggler = $('#main-nav-toggler');

      var nav_ht = nav.outerHeight();
      var nav_toggler_ht = nav_toggler.outerHeight();

      var header_ht = nav_ht;

      // footer height
      var footer_ht = $('#footer').outerHeight();

      if (wd_w < 993) {
        // mobile
        avail_ht = window.screen.availHeight; // use screen avail height on mobile devices (negates UI toolbars)
        if (wd_h > 400) {
          // if mobile, min height is 400
          if (nav_ht > nav_toggler_ht) {
            // nav is expanded still, or in mid collapse.
            // subtract the height of the mobile-expanded nav, that way when
            // re-collapsed, the content height will remain the same.
            header_ht = nav_ht - nav_toggler_ht;
          }
          var ht = this.getFullHeight(avail_ht, header_ht, footer_ht);
          $(ele).css('height', String(ht) + 'px');
        }
      } else {
        // desktop
        if (wd_h > 500) {
          // if desktop, min height is 500

          // include member nav in calculation (not in mobile calculation because
          // it becomes part of the main nav).
          var member_nav = $('#member-nav');
          if (member_nav.is(':visible')) {
            header_ht += member_nav.outerHeight();
          }

          var _ht = this.getFullHeight(avail_ht, header_ht, footer_ht);
          $(ele).css('height', String(_ht) + 'px');
        }
      }
    },
    getFullHeight: function getFullHeight(avail, header, footer) {
      return avail - header - footer;
    }
  });
});