define('mx/components/share-config/recurring-addon-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    waldenPrice: Ember.inject.service('waldenPrice'),
    /**
     *  Addon Model Instance
     *
     *  @argument
     *  @type {Models/Addon}
     *  @memberof Components/ShareConfig/RecurringAddonSelection
     */
    addon: null,
    /**
     *  The Array Containing Selected Addons
     *
     *  @argument
     *  @type {Array.<Models/Addon>}
     *  @memberof Components/ShareConfig/RecurringAddonSelection
     */
    target: null,
    /**
     *  Get the total price for a given addon and its quantity
     *
     *  @computed
     *  @function totalPrice
     *  @memberof Components/ShareConfig/RecurringAddonSelection
     *  @returns {number} formatted price in dollars
     */
    totalPrice: Ember.computed('addon.quantity', function () {
      var waldenPrice = Ember.get(this, 'waldenPrice');
      var addon = Ember.get(this, 'addon');
      var quantity = Ember.get(this, 'addon.quantity');
      return waldenPrice.getAddonPrice(addon, quantity, true);
    }),
    /**
     *  Update the target array's entry for this addon
     *  if an entry exists. Add to the array if quantity
     *  is posative and remove if quantity is 0
     *
     *  @function _updateConfigAddons
     *  @param {number} quantity - selected addon quantity
     *  @memberof Components/ShareConfig/RecurringAddonSelection
     */
    _updateConfigAddons: function _updateConfigAddons(quantity) {
      var addon = Ember.get(this, 'addon');
      var target = Ember.get(this, 'target');
      if (this._addonInConfig()) {
        if (quantity === 0) {
          var configAddon = target.findBy('id', Ember.get(addon, 'id'));
          target.removeObject(configAddon);
        }
      } else {
        if (quantity) {
          target.pushObject(addon);
        }
      }
    },

    /**
     *  Determine whether or not this addon exists
     *  in the target array
     *
     *  @function _addonInConfig
     *  @returns {Models/Addon|null} the existing model instance or null
     *  @memberof Components/ShareConfig/RecurringAddonSelection
     */
    _addonInConfig: function _addonInConfig() {
      var target = Ember.get(this, 'target');
      var addon = Ember.get(this, 'addon');
      return target.findBy('id', Ember.get(addon, 'id'));
    },

    actions: {
      /**
       *  Update the target to reflect addon quantity selection
       *
       *  @function action:updateRecurringAddon
       *  @param {number} quantity - the selected addon quantity
       *  @memberof Components/ShareConfig/RecurringAddonSelection
       */
      updateRecurringAddon: function updateRecurringAddon(quantity) {
        var addon = Ember.get(this, 'addon');
        quantity = parseInt(quantity);
        Ember.set(addon, 'quantity', quantity);
        this._updateConfigAddons(quantity);
      }
    }
  });
});