define('mx/v2/components/sign-up-entry/waitlist-form/component', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // Validations for the entered email address.
  var Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })],
      message: 'Please enter an email address.'
    }
  });
  /**
   * Email waitlist entry form during sign-up.
   *
   * @class Components/SignUpEntry/WaitlistForm
   * @extends Component
   */
  exports.default = Ember.Component.extend(Validations, {
    /**
     * The unservicable zip code entered in the zip-form.
     *
     * @type {string}
     * @default null
     * @memberof Components/SignUpEntry/WaitlistForm
     */
    code: null,
    /**
     * Currently entered email.
     *
     * @argument
     * @type {string}
     * @default null
     * @memberof Components/SignUpEntry/WaitlistForm
     */
    email: null,
    /**
     * Closure to invoke upon submission attempt.
     *
     * @argument
     * @type {function}
     * @default null
     * @memberof Components/SignUpEntry/WaitlistForm
     */
    onSubmitEmail: null
  });
});