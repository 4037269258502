define('mx/components/account/payment-info', ['exports', 'mx/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    metrics: Ember.inject.service('metrics'),
    stripe: {
      stripe: null,
      elements: null,
      element: null,
      card: null,
      number: null,
      cvc: null,
      expiry: null
    },
    actions: {
      showPane: function showPane() {
        if (this.maxStep >= 3) {
          this.set('currentStep', 3);
        }
      },
      getStripeToken: function getStripeToken() {
        var self = this;
        self.stripe.stripe.createToken(self.stripe.number).then(function (result) {
          if (result.error) {
            // Inform the user if there was an error
            $('#card-errors').show();
            var errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
          } else {
            // Send the token to your server
            self.sendAction('trackClick', 'payment_complete');
            self.set('currentStep', 4);
            self.set('maxStep', 4);

            // self.get('member').set('stripe_token', 'eee');
            self.get('member').set('stripe_token', result.token.id);
            self.set('cardInfo', result);

            $('#card-errors').hide();
          }
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      $(document).ready(function () {
        self.stripe.stripe = Stripe(_environment.default.stripe);

        // Create an instance of Elements
        // self.stripe.elements = self.stripe.stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        var style = {
          base: {
            color: '#32325d',
            lineHeight: '18px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        };

        // // Create an instance of the card Element
        // self.stripe.card = self.stripe.elements.create('card', {style: style});

        // // Add an instance of the card Element into the `card-element` <div>
        // self.stripe.card.mount('#card-element');

        // // Handle real-time validation errors from the card Element.
        // self.stripe.card.addEventListener('change', function(event) {
        //   var displayError = document.getElementById('card-errors');
        //   if (event.error) {
        //     displayError.textContent = event.error.message;
        //   } else {
        //     displayError.textContent = '';
        //     $('.billing-info-submit').removeClass('disabled');
        //   }
        // });

        function registerElements(elements, exampleName) {
          var formClass = '.' + exampleName;
          var example = document.querySelector(formClass);

          var form = example.querySelector('form');
          var resetButton = example.querySelector('a.reset');
          var error = form.querySelector('.error');
          // var errorMessage = error.querySelector('.message');

          function enableInputs() {
            Array.prototype.forEach.call(form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"), function (input) {
              input.removeAttribute('disabled');
            });
          }
        }

        self.stripe.element = self.stripe.stripe.elements({
          fonts: [{
            cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro'
          }],
          // Stripe's examples are localized to specific languages, but if
          // you wish to have Elements automatically detect your user's locale,
          // use `locale: 'auto'` instead.
          locale: window.__exampleLocale
        });

        // Floating labels
        var inputs = document.querySelectorAll('.cell.example.example2 .input');
        Array.prototype.forEach.call(inputs, function (input) {
          input.addEventListener('focus', function () {
            input.classList.add('focused');
          });
          input.addEventListener('blur', function () {
            input.classList.remove('focused');
          });
          input.addEventListener('keyup', function () {
            if (input.value.length === 0) {
              input.classList.add('empty');
            } else {
              input.classList.remove('empty');
            }
          });
        });

        var elementStyles = {
          base: {
            color: '#32325D',
            fontWeight: 500,
            fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            '::placeholder': {
              color: '#CFD7DF'
            },
            ':-webkit-autofill': {
              color: '#e39f48'
            }
          },
          invalid: {
            color: '#E25950',

            '::placeholder': {
              color: '#FFCCA5'
            }
          }
        };

        var elementClasses = {
          focus: 'focused',
          empty: 'empty',
          invalid: 'invalid'
        };

        self.stripe.number = self.stripe.element.create('cardNumber', {
          style: elementStyles,
          classes: elementClasses
        });
        self.stripe.number.mount('#example2-card-number');

        self.stripe.expiry = self.stripe.element.create('cardExpiry', {
          style: elementStyles,
          classes: elementClasses
        });
        self.stripe.expiry.mount('#example2-card-expiry');

        self.stripe.cvc = self.stripe.element.create('cardCvc', {
          style: elementStyles,
          classes: elementClasses
        });
        self.stripe.cvc.mount('#example2-card-cvc');

        registerElements([self.stripe.number, self.stripe.expiry, self.stripe.cvc], 'example2');
        // self.stripe.card = [cardNumber, cardExpiry, cardCvc];
      });
    }
  });
});