define('mx/v2/components/atk-guides/recipes-for/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    atk: Ember.inject.service('atk')
  });
});