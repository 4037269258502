define('mx/helpers/addon-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addonPrice = addonPrice;
  function addonPrice(params /*, hash*/) {
    var addon = params[0];
    var price = parseInt(addon.get('price'));
    var quantity = parseInt(addon.get('quantity'));

    if (addon.get('weighed')) {
      return '$' + String((price / 100).toFixed(2)) + ' / lb';
    } else {
      console.log('calcin-nonweighed', price, quantity);
      return '$' + String((price * quantity / 100).toFixed(2));
    }
  }

  exports.default = Ember.Helper.helper(addonPrice);
});