define('mx/components/contact/contact-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    constants: Ember.inject.service('constants'),
    phone: Ember.computed.alias('constants.phone')
  });
});