define('mx/validators/email-in-use', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  var EmailInUse = _base.default.extend({
    ajax: Ember.inject.service('ajax'),
    validate: function validate(value, options, model, attribute) {
      var _this = this;

      return this.get('ajax').request('email-lookup', {
        method: 'POST',
        data: {
          email: value
        }
      }).then(function (email) {
        _newArrowCheck(this, _this);

        if (email.exists === true) {
          return 'You already have an account!';
        } else {
          return true;
        }
      }.bind(this));
    }
  });

  exports.default = EmailInUse;
});