define('mx/models/v2-cut-category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Attributes
    active: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    display: _emberData.default.attr('string'),
    displayOrder: _emberData.default.attr('number'),
    hidden: _emberData.default.attr('boolean', { defaultValue: true }),

    // Associations
    cutGroups: _emberData.default.hasMany('v2-cut-group')
  });
});