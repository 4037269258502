define('mx/v2/components/x-recipe/image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mx-x-recipe-img'],
    /**
     * The URI of the image.
     *
     * @type {string}
     * @default null
     * @memberof Components/XRecipe/Image
     */
    src: null,
    /**
     * The description of the image.
     *
     * @type {string}
     * @default null
     * @memberof Components/XRecipe/Image
     */
    alt: null
  });
});