define('mx/v2/components/sign-up-review/step/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Closure to invoke when the step is completed.
     * This closure is yielded by this component's template.
     *
     * @type {function}
     * @default null
     * @memberof Components/SignUpReview/Step
     */
    onCompleteStep: null,
    /**
     * Closure to invoke when the step clicks the edit button.
     *
     * @type {function}
     * @default null
     * @memberof Components/SignUpReview/Step
     */
    onEditStep: null,
    /**
     * The step instance for this step.
     *
     * @type {Step}
     * @default null
     * @memberof Components/SignUpReview/Step
     */
    step: null
  });
});