define('mx/components/fresh-pilot/pilot-opt-in', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * Whether or not the terms of the pilot have been accepted.
     *
     * @argument acceptedTerms
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Pilot-Opt-In
     */
    acceptedTerms: false,
    /**
     * Whether or not the member cap has been reached. If reached, a message will be shown
     * above the opt in button denoting that the pilot has reached it's cap. The user
     * can still opt-in to the pilot.
     *
     * @argument capReached
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Pilot-Opt-In
     */
    capReached: false,
    /**
     * Loading state for the opt in button.
     *
     * @argument loading
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Pilot-Opt-In
     */
    loading: false,
    /**
     * Whether or not an error occurred while attempting to opt in the member.
     *
     * @argument error
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Pilot-Opt-In
     */
    error: false,
    /**
     * Construct the set of share frequency options once this component gets inserted into the DOM.
     *
     * @method lifecycle:didInsertElement
     * @returns {null}
     * @memberof Components/Fresh-Pilot/Pilot-Opt-In
     */
    didInsertElement: function didInsertElement() {
      this.set('frequencies', [
      /**
       * These objects are used for share frequency selection.
       *
       * The reason for providing boolean values on each object instead of using 'true' and 'false'
       * in the template is to prevent having to deal with a stringified booleans being passed to the action.
       *
       * This requires an additional step to look up the corresponding frequency type in order to acquire
       * the actual boolean, but better safe than sorry.
       */
      Ember.Object.create({
        name: 'Weekly',
        value: false
      }), Ember.Object.create({
        name: 'Every Other Week',
        value: true
      })]);
    },

    /**
     * Reset share frequency-related properties once this component gets destroyed.
     *
     * @method lifecycle:didDestroyElement
     * @returns {null}
     * @memberof Components/Fresh-Pilot/Pilot-Opt-In
     */
    didDestroyElement: function didDestroyElement() {
      this.setProperties({ didSelectFrequency: false, isBiWeekly: false });
    },

    /**
     * Whether or not a share frequency has been selected.
     *
     * @argument didSelectFrequency
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Pilot-Opt-In
     */
    didSelectFrequency: false,
    /**
     * Whether or not the share frequency selection is 'Bi-Weekly'.
     *
     * @argument isBiWeekly
     * @type {boolean}
     * @default false
     * @memberof Components/Fresh-Pilot/Pilot-Opt-In
     */
    isBiWeekly: false,
    actions: {
      /**
       * Handles changes made to share frequency selection.
       *
       * @method action:handleFrequencyChange
       * @param {string} name Name of the share frequency.
       * @returns {null}
       * @memberof Components/Fresh-Pilot/Pilot-Opt-In
       */
      handleFrequencyChange: function handleFrequencyChange(name) {
        // Look up the frequency type along with it's boolean value.
        var freq = this.get('frequencies').findBy('name', name);
        this.set('didSelectFrequency', true);
        this.set('isBiWeekly', freq.get('value'));
      }
    }
  });
});