define('mx/components/util/confirmation-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    title: 'Are You Sure?',
    buttonText: 'Save Changes',
    actions: {
      confirmAction: function confirmAction() {
        console.log('modal confirm');
        this.sendAction('actionToCall');
      }
    }
  });
});