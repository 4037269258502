define('mx/v2/components/member-dashboards/recipes/component', ['exports', 'mx/v2/util/cloudinary'], function (exports, _cloudinary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service('media'),
    /**
     * Base cloudinary image from one of ATK's recipes.
     *
     * @type function
     * @returns {string} Base image URI.
     * @memberof Components/MemberDashboards/Recipes
     */
    baseImage: Ember.computed(function () {
      return 'https://walden-static-assets.s3.amazonaws.com/images/recipes/Cooks_5_11+Image_A.jpg';
    }).readOnly(),
    /**
     * The transformed cloudinary image.
     * Transformation will reduce the file size and better fit various device dimensions.
     *
     * @type function
     * @returns {string} Transformed cloudinary image.
     * @memberof Components/MemberDashboard/Recipes
     */
    transformedImage: Ember.computed('media.isMobileLazy', function () {
      var images = (0, _cloudinary.makeCloudinaryUrisForType)('recipe', Ember.get(this, 'baseImage'));
      // We'll use the wider image on desktop to better match the design mockup.
      return Ember.get(this, 'media.isMobileLazy') ? images.lg : images.lg_wide;
    }).readOnly()
  });
});