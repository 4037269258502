define('mx/services/ajax', ['exports', 'ember-ajax/services/ajax', 'mx/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service('session'),
    contentType: 'application/json; charset=utf-8',
    // host: 'http://10.1.10.209:10010/'
    host: _environment.default.walden_api,
    headers: Ember.computed('session', {
      get: function get() {
        var headers = {};
        var authToken = this.get('session').session.content.authenticated.token;
        if (authToken) {
          headers['token'] = authToken;
        }
        return headers;
      }
    })
  });
});