define('mx/v2/classes/atk-content-category', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /**
   * This class is used to contain additional display text of a corresponding content type in an AtkContentGroup.
   *
   * @class AtkContentCategory
   * @extends EmberObject
   */
  var AtkContentCategory = Ember.Object.extend({
    /**
     * Category title, e.g. 'Summer Recipes'.
     *
     * @type {string}
     * @default null
     * @memberof AtkContentCategory
     */
    title: null,
    /**
     * Category  type, e.g. 'Recipes'.
     *
     * @type {string}
     * @default null
     * @memberof AtkContentCategory
     */
    type: null
  });

  exports.default = AtkContentCategory;
});