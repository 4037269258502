define('mx/serializers/cutgroup', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      cuts: { serialize: 'records', deserialize: 'records' }
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        'cut-groups': payload.cut_groups
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        cutgroups: payload.cut_groups
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});