define('mx/components/member/share-config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showRecurring: true,
    viewRules: Ember.inject.service('vrShareConfig')
  });
});