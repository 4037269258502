define('mx/models/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = _emberInflector.default.inflector;

  inflector.irregular('people', 'peoples');
  inflector.irregular('dozen', 'dozen');

  // Modules must have an export, so we just export an empty object here
  exports.default = {};
});