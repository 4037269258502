define('mx/components/mx/modal-window', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['modal', 'fade'],
    attributeBindings: ['id', 'role', 'dataBackdrop:data-backdrop'],
    id: '',
    role: 'dialog',
    canDismiss: true,
    dataBackdrop: 'static'
  });
});