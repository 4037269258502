define('mx/components/truncate-multiline', ['exports', 'ember-truncate/components/truncate-multiline'], function (exports, _truncateMultiline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _truncateMultiline.default;
    }
  });
});