define('mx/app', ['exports', 'mx/resolver', 'ember-load-initializers', 'mx/config/environment', '@sentry/browser', '@sentry/integrations', 'ember-concurrency-test-waiter/define-modifier', 'mx/models/custom-inflector-rules'], function (exports, _resolver, _emberLoadInitializers, _environment, _browser, _integrations, _defineModifier) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  (0, _defineModifier.default)();
  /**
   * Required for ember-concurrency tasks to force your tests to wait.
   *
   * @see {@link https://github.com/bendemboski/ember-concurrency-test-waiter#why}
   */


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  _browser.init({
    dsn: 'https://9fe6381c856546d4ad5731848a7b8438@sentry.io/1445045',
    integrations: [new _integrations.Ember()]
  });

  exports.default = App;
});