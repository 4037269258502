define('mx/models/share', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    member: _emberData.default.attr('number'),
    notes: _emberData.default.attr('string'),
    share_config: _emberData.default.belongsTo('share-config'),
    status: _emberData.default.attr('string'),
    service_date: _emberData.default.belongsTo('service-date'),
    delivered_at: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string'),
    transactions: _emberData.default.hasMany('transaction', { async: false }),
    service_partition: _emberData.default.attr('raw'),
    packed_weight: _emberData.default.attr('number'),
    legacy: _emberData.default.attr('raw'),
    addons: _emberData.default.hasMany('addons', { async: false }),
    partition_month: Ember.computed('service_partition', function () {
      return (0, _moment.default)().month(this.get('service_partition').month).format('MMMM');
    })
  });
});