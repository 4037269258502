define('mx/models/share-size', ['exports', 'ember-data', 'ember-data/model', 'ember-data/attr'], function (exports, _emberData, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    display: _emberData.default.attr('string'),
    display_order: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    weight_lower: _emberData.default.attr('number'),
    weight_upper: _emberData.default.attr('number'),
    share_type: _emberData.default.belongsTo('shareType', { embedded: 'always' }),
    selected: _emberData.default.attr('boolean', { defaultValue: false }),
    visible: _emberData.default.attr('boolean', { defaultValue: false })
  });
});