define('mx/v2/components/member-thx/turkey-recipe/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    recipeLink: Ember.computed(function () {
      return 'https://waldenlocalmeat.com/2019/10/08/pasture-raised-turkey-a-thanksgiving-tradition/';
    }).readOnly()
  });
});