define('mx/components/mx/modal-window/modal-title', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'h5',
    classNames: ['modal-title']
  });
});