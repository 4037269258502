define('mx/components/loader-ball-spin-fade-loader', ['exports', 'ember-cli-loaders/components/loader-ball-spin-fade-loader'], function (exports, _loaderBallSpinFadeLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loaderBallSpinFadeLoader.default;
    }
  });
});