define('mx/v2/components/member-dashboards/public-benefit/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    pbcLink: Ember.computed(function () {
      return 'https://s3.amazonaws.com/walden-static-assets/2020_PBC_Annual_Report_R6.pdf';
    }).readOnly()
  });
});