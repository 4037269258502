define('mx/validators/referral-note', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /**
   * Validator for a member's referral note.
   *
   * @class Validators/ReferralNote
   * @extends BaseValidator
   */
  var ReferralNote = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      if (Ember.get(model, 'referral_type.open_field') && Ember.isEmpty(value)) {
        return 'Please let us know who referred you';
      }
      return true;
    }
  });

  /**
   * This validator depends on the referral type of the corresponding
   * model we're validating against.
   */
  ReferralNote.reopenClass({
    getDependentsFor: function getDependentsFor() {
      return ['model.referral_type.open_field'];
    }
  });

  exports.default = ReferralNote;
});