define('mx/components/mx/checkbox-standalone', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes and Classes
    classNames: ['mx-checkbox-standalone'],
    classNameBindings: ['selected:checked'],
    /**
     *  The Checkbox's selected value
     *
     *  @argument
     *  @type {boolean}
     *  @memberof Components/MX/CheckboxStandalone
     */
    selected: false,
    /**
     *  Toggle the 'selected' property on click
     *  and call onChange action if it exists
     *
     *  @override
     *  @function click
     *  @memberof Components/MX/CheckboxStandalone
     */
    click: function click() {
      var onChange = Ember.get(this, 'onChange');

      // If an onChange action was passed, call
      // it with the 'selected' value
      if (onChange) {
        onChange();
      }
    }
  });
});