define('mx/models/v2-cut-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Attributes
    active: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    displaySection: _emberData.default.attr('number'),
    isRoast: _emberData.default.attr('boolean', { defaultValue: false }),
    name: _emberData.default.attr('string'),

    // Associations
    meatType: _emberData.default.belongsTo('v2-meat-type')
  });
});