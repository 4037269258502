define('mx/v2/components/account/billing-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The brand of the credit card.
     *
     * @type {string}
     * @default null
     * @memberof Components/Account/BillingSummary
     */
    brand: null,
    /**
     * The last 4 digits of credit card number.
     *
     * @type {number}
     * @default null
     * @memberof Components/Account/BillingSummary
     */
    last4: null
  });
});