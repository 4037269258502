define('mx/v2/components/sign-up/steps-for/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    signup: Ember.inject.service('sign-up'),
    tagName: ''
  });
});