define('mx/helpers/getUserInfo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    session: Ember.inject.service('session'),

    compute: function compute(params, hash) {

      var token = this.get('session').session.content.authenticated.token;
      var data = JSON.parse(atob(token.split('.')[1]));
      var value = data[params[0]];

      if (params[1]) {
        return params[1] + ', ' + value;
      }

      return value;
    }
  });
});