define('mx/models/share-people', ['exports', 'ember-data', 'ember-data/model', 'ember-data/attr'], function (exports, _emberData, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    display_order: _emberData.default.attr('number'),
    people_lower: _emberData.default.attr('number'),
    people_upper: _emberData.default.attr('number'),
    selected: _emberData.default.attr('boolean', { defaultValue: false })
  });
});