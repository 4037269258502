define('mx/v2/components/atk-recipes/instructions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The atk recipe instructions.
     *
     * @type {Array.<Models/AtkRecipeInstruction>}
     * @default null
     * @memberof Components/AtkRecipes/Instructions
     */
    instructions: null
  });
});