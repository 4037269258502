define('mx/routes/unknown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * Redirect to the 'members' route when an unknown route is entered.
     * If the user is not authenticated, the 'members' route will redirect to the 'login' route.
     *
     * @see {@link https://www.emberjs.com/api/ember/2.16/classes/Route/methods/redirect?anchor=redirect}
     * @override
     * @method redirect
     * @memberof Routes/Unknown
     */
    redirect: function redirect(transition) {
      // Implicity abort the transition and return the user to the dashboard.
      this.transitionTo('members');
    }
  });
});