define('mx/adapters/sticky-preference', ['exports', 'mx/adapters/application', 'ember-inflector'], function (exports, _application, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(type) {
      return _emberInflector.default.inflector.pluralize(Ember.String.dasherize(type));
    }
  });
});