define('mx/components/utility/step-wizard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    steps: null,
    step: 1,
    didInsertElement: function didInsertElement() {
      var _this = this;
      setTimeout(function () {
        console.log(_this.get('steps'));
      }, 3000);
      if (this.get('hasShare')) {
        this.set('step', this.get('steps').objectAt(2));
      } else {
        this.set('step', this.get('steps').objectAt(0));
      }
    }
  });
});