define('mx/models/atk-tag', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /** Attributes */
    // E.g. 'main_ingredient'.
    categoryTitle: (0, _attr.default)('string'),
    // E.g. 'main_ingredient > Beef'.
    editorialTitle: (0, _attr.default)('string'),
    // E.g. 'main_ingredient-beef'.
    slug: (0, _attr.default)('string'),
    // E.g. 'Beef'.
    title: (0, _attr.default)('string')
  });
});