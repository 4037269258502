define('mx/components/member-dashboards/referrals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The referral code of the current user (member).
     *
     * @argument referralCode
     * @type {string}
     * @default nulll
     * @memberof Components/MemberDashboards/Referrals
     */
    referralCode: null,
    /**
     * DOM ID of the modal window containing the referral link.
     *
     * @argument referralModalId
     * @type {string}
     * @default 'referralDashModal'
     * @memberof Components/MemberDashboards/Referrals
     */
    referralModalId: 'referralDashModal',
    /**
     * DOM ID of the modal label used for accessibility.
     *
     * @argument referralModalLabelId
     * @type {string}
     * @default 'referralDashModalLabel'
     * @memberof Components/MemberDashboards/Referrals
     */
    referralModalLabelId: 'referralDashModalLabel',
    /**
     * Whether or not to show the referral email recipient entry components.
     * Toggling this boolean will swap the modal body out with the components
     * for recipient entry.
     *
     * @argument showRecipientEntry
     * @type {boolean}
     * @default false
     * @memberof Components/MemberDashboards/Referrals
     */
    showRecipientEntry: false,
    /**
     * jQuery-friendly element ID used to access the modal object
     * with Bootstrap's modal API.
     *
     * @computed
     * @returns {string} Modal element ID.
     * @memberof Components/MemberDashboards/Referrals
     */
    modalRef: Ember.computed('referralModalId', function () {
      return '#' + String(Ember.get(this, 'referralModalId'));
    }),
    /**
     * Initialize the modal upon inserting this component into the DOM.
     *
     * @override
     * @method lifecycle:didInsertElement
     * @memberof Components/MemberDashboards/Referrals
     */
    didInsertElement: function didInsertElement() {
      $(Ember.get(this, 'modalRef')).modal({ show: false });
    },

    /**
     * Destroy the modal when this component is scheduled to be destroyed.
     *
     * @override
     * @method lifecycle:willDestroyElement
     * @memberof Components/MemberDashboards/Referrals
     */
    willDestroyElement: function willDestroyElement() {
      $(Ember.get(this, 'modalRef')).modal('dispose');
    },

    actions: {
      /**
       * Show the referral modal when the dashboard button is clicked.
       *
       * @method actions:handleShowModal
       * @memberof Components/MemberDashboards/Referrals
       */
      handleShowModal: function handleShowModal() {
        $(Ember.get(this, 'modalRef')).modal('show');
      },

      /**
       * Show the recipient entry components when the email button is clicked.
       *
       * @method actions:handleShowRecipientEntry
       * @memberof Components/MemberDashboards/Referrals
       */
      handleShowRecipientEntry: function handleShowRecipientEntry() {
        var show = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : !Ember.get(this, 'showRecipientEntry');

        Ember.set(this, 'showRecipientEntry', show);
      }
    }
  });
});