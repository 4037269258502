define('mx/v2/components/sign-up-addons/share-addons-for/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * The list of addon models.
     *
     * @type {Array.<Models/V2Addon>}
     * @default null
     * @memberof Components/SignUpAddons/ShareAddonsFor
     */
    addons: null,
    actions: {
      /**
       * Filters out share addons that are not 'top-level' display.
       *
       * @function actions:shouldDisplayAddon
       * @returns {boolean} Whether or not the addon is 'top-level' display.
       * @memberof Components/SignUpAddons/ShareAddonsFor
       */
      shouldDisplayAddon: function shouldDisplayAddon(addon) {
        return Ember.get(addon, 'displaySection') <= 2;
      }
    }
  });
});