define('mx/services/walden-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    waldenMember: Ember.inject.service('member'),
    /**
     *  Get the base price of a share by size
     *
     *  @function getShareSizePrice
     *  @param {Models/ShareSize} size - shareSize model instance
     *  @param {boolean} [formatted=false] - format to dollar amount
     *  @returns {number} price in pennies
     *  @memberof Services/WaldenPrice
     */
    getShareSizePrice: function getShareSizePrice(size) {
      var formatted = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var price = parseInt(Ember.get(size, 'price'));
      if (!formatted) return price;
      return this._toDollars(price);
    },

    /**
     *  Get the price of a single addon based on
     *  its quantity and discount map if needed
     *
     *  @function getAddonPrice
     *  @param {Models/Addon} addon - addon model instance
     *  @param {number} quantity - quantity of addon
     *  @param {boolean} [formatted=false] - format to dollar amount
     *  @returns {number} price in pennies
     *  @memberof Services/WaldenPrice
     */
    getAddonPrice: function getAddonPrice(addon, quantity) {
      var formatted = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var _addon$getProperties = addon.getProperties('price'),
          price = _addon$getProperties.price;

      var discountMap = Ember.get(addon, 'discountMap');

      price = parseInt(price);
      quantity = parseInt(quantity);

      var discount = discountMap ? this._getDiscount(discountMap, quantity) : 0;
      var total = price * quantity - discount;

      if (!formatted) return total;
      return this._toDollars(total);
    },

    /**
     *  Get the total price of a share based on shareConfig,
     *  disregarding addons
     *
     *  @function getSharePrice
     *  @param {Models/ShareConfig} shareConfig - shareConfig model instance
     *  @param {boolean} [formatted=false] - format to dollar amount
     *  @returns {number} price in pennies
     */
    getSharePrice: function getSharePrice(shareConfig) {
      var formatted = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var sharePrice = parseInt(Ember.get(shareConfig, 'shareSize.price'));
      var generationalDelta = this._getGenerationalShareDelta(shareConfig);
      sharePrice = sharePrice + generationalDelta;

      if (!formatted) return sharePrice;
      return this._toDollars(sharePrice);
    },

    /**
     *  Get the total price of a share and its addons
     *  based on a given share configuration
     *
     *  @function getTotalSharePrice
     *  @param {Models/ShareConfig} shareConfig - shareConfig model isntance
     *  @param {boolean} [formatted=false] - format to dollar amount
     *  @returns {number} price in pennies
     *  @memberof Services/WaldenPrice
     */
    getTotalSharePrice: function getTotalSharePrice(shareConfig) {
      var formatted = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var addons = Ember.get(shareConfig, 'shareAddons');
      var basePrice = this.getSharePrice(shareConfig);
      var addonPrice = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = addons.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var addon = _step.value;

          addonPrice += this.getAddonPrice(addon, Ember.get(addon, 'quantity'));
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var totalPrice = basePrice + addonPrice;
      if (!formatted) return totalPrice;
      return this._toDollars(totalPrice);
    },

    /**
     *  Get the price delta, be it posative or negative, for a
     *  given share based on the member's user generation
     *  (1st gen, 2nd gen, etc...)
     *
     *  @function getGenerationalShareDelta
     *  @param {Models/ShareConfig} shareConfig - shareConfig model instance
     *  @returns {number} delta +/- in pennies
     *  @memberof Services/WaldenPrice
     */
    _getGenerationalShareDelta: function _getGenerationalShareDelta(shareConfig) {
      var waldenMember = Ember.get(this, 'waldenMember');
      var generation = Ember.get(waldenMember, 'generation');

      if (generation === 1) {
        var shareType = Ember.get(shareConfig, 'shareType');
        if (Ember.get(shareType, 'name') === 'Custom') {
          return -500;
        }
      }

      if (generation === 2) {
        // do nothing
      }

      if (generation === 3) {
        var _shareType = Ember.get(shareConfig, 'shareType');
        if (['Custom', 'Complete'].includes(Ember.get(_shareType, 'name'))) {
          return 500;
        }
      }

      if (generation === 4) {
        var cutGroupInclusions = Ember.get(shareConfig, 'cutGroupInclusions');
        var meatExclusions = Ember.get(shareConfig, 'meatExclusions');

        var hasLambGroupInclusion = false;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = cutGroupInclusions.toArray()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var group = _step2.value;

            if (parseInt(Ember.get(group, 'meatType.id')) === 3) {
              hasLambGroupInclusion = true;
              break;
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        var lambProteinExcluded = meatExclusions.findBy('id', '3');
        if (hasLambGroupInclusion || !lambProteinExcluded) {
          return 500;
        }
      }

      return 0;
    },

    /**
     *  Convert pennies to dollars
     *
     *  @function _toDollars
     *  @param {number} price - a price in pennies
     *  @returns {number} price in dollars
     */
    _toDollars: function _toDollars(price) {
      var dollarValue = price / 100;
      return parseFloat(dollarValue).toFixed(2);
    },

    /**
     *  Determine discount value in pennies from a discount
     *  map and a quantity
     *
     *  @function _getDiscount
     *  @param {Object|string} map - addon specific discount map
     *  @param {number} quantity - addon quantity
     *  @memberof Service/WaldenPrice
     *  @returns {number} price in pennies
     */
    _getDiscount: function _getDiscount(map, quantity) {
      if (typeof map === 'string') {
        try {
          // if the map is a string, attempt to parse it as JSON
          map = JSON.parse(map);
        } catch (err) {
          // If we fail to do so, assume no discount
          return 0;
        }
      }

      var discount = map[quantity];

      // If the end result is not a number, no discount
      // Otherwise, return the discount
      return isNaN(discount) ? 0 : discount;
    }
  });
});