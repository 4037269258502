define('mx/components/share-config/dietary-restriction', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     *  The dietaryRestriction model instance
     *
     *  @argument
     *  @type {object}
     *  @memberof Components/ShareConfig/DietaryRestriction
     */
    restriction: null
  });
});