define('mx/serializers/atk-recipe-ingredient-group', ['exports', 'ember-data/serializers/embedded-records-mixin', 'mx/serializers/atk'], function (exports, _embeddedRecordsMixin, _atk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _atk.default.extend(_embeddedRecordsMixin.default, {
    /**
     * Embedded associations for an ATK recipe ingredient group payload.
     *
     * @type {object}
     * @memberof Serializers/AtkRecipeIngredientGroup
     */
    attrs: {
      recipeIngredients: { key: 'recipe_ingredients', embedded: 'always' }
    }
  });
});