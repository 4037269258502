define('mx/v2/components/x-recipe/servings/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The servings to display.
     *
     * @type {string}
     * @default null
     * @memberof Components/XRecipe/Servings
     */
    servings: null
  });
});