define('mx/components/share-config/share-type-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    viewRules: Ember.inject.service('vrShareConfig'),
    /**
     * The share type.
     *
     * @type {Models/V2ShareType}
     * @default null
     * @memberof Components/ShareConfig/ShareTypeContainer
     */
    type: null,
    /**
     * Whether or not this share type is disabled.
     *
     * @type {function}
     * @returns {boolean} Returns true if the share type is disabled, otherwise false.
     * @memberof Components/ShareConfig/ShareTypeContainer
     */
    disabled: Ember.computed('viewRules.{isChickenOnly,isChickenAndLambOnly}', function () {
      return !Ember.get(this, 'viewRules').isShareTypeAvailable(Ember.get(this, 'type'));
    }),
    /**
     * The description for this share type.
     *
     * @type {function}
     * @returns {string} Share type description.
     * @memberof Components/ShareConfig/ShareTypeContainer
     */
    description: Ember.computed('viewRules.{isChickenOnly,isChickenAndLambOnly}', function () {
      return Ember.get(this, 'viewRules').descriptionForShareType(Ember.get(this, 'type'));
    })
  });
});