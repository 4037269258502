define('mx/components/mx/selected-proxy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes and Classes
    tagName: '',
    /**
     *  Array to modify to reflect selected items
     *
     *  @argument
     *  @type {Array.<Ember.Object|Object>}
     *  @memberof Components/MX/SelectedProxy
     */
    target: null,
    /**
     *   Set of objects to be conditionally
     *  added or removed to/from the 'target' array
     *
     *  @argument
     *  @type {Array.<Ember.Object|Object>}
     *  @memberof Components/MX/SelectedProxy
     */
    models: null,
    /**
     *  Array of objects, each wrapping a
     *  'models' object, introducing a 'selected'
     *  boolean property
     *
     *  @argument
     *  @type {Array.<Ember.Object|Object>}
     *  @memberof Components/MX/SelectedProxy
     */
    items: null,
    /**
     *  Construct the 'items' array, and set
     *  appropriate initial 'selected' values.
     *
     *  @override
     *  @function lifecycle:didInsertElement
     *  @memberof Components/MX/SelectedProxy
     */
    didInsertElement: function didInsertElement() {
      var items = [];
      var models = Ember.get(this, 'models');
      var target = Ember.get(this, 'target');

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = models.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var model = _step.value;

          // Add all models to the items array
          // If the model already exists in the target
          // array, set selected: true
          items.pushObject({
            model: model,
            selected: target.findBy('id', Ember.get(model, 'id')) ? true : false
          });
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      Ember.set(this, 'items', items);
    },

    actions: {
      /**
       *  Update the target array to reflect the
       *  selected values of the items array
       *
       *  @function actions:updateProxy
       *  @param {boolean} selected - selected value of 'item'
       *  @param {Ember.Object|Object} model - the object being represented by 'item'
       *  @memberof Components/MX/SelectedProxy
       */
      updateProxy: function updateProxy(selected, model) {
        var target = Ember.get(this, 'target');
        if (selected) {
          target.addObject(model);
        } else {
          target.removeObject(model);
        }
      }
    }
  });
});