define('mx/components/share-config/share-types', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service('media'),
    isMobile: Ember.computed.or('media.{isMobile,isTablet}'),
    /**
     * Closure to invoke when a share type is selected.
     *
     * @type {function}
     * @default null
     * @memberof Components/ShareConfig/ShareTypes
     */
    onSelectShareType: null,
    /**
     *  The object in which to contain selected shareType
     *
     *  @argument
     *  @type {Models/ShareType}
     *  @memberof Components/ShareConfig/ShareTypes
     */
    target: null,
    /**
     *  The target shareSize to modify on shareType changes
     *  Each shareType has its own shareSizes, so on shareType
     *  change, we need to set the shareSize to a supported value
     *
     *  @argument
     *  @type {Models/ShareSize}
     *  @memberof Components/ShareConfig/ShareTypes
     */
    targetSize: null,
    /**
     * The target sharePeoples (portions) to modify on shareType changes
     * Eache shareType has its own sharePeoples, so on shareType change,
     * we need to set the sharePeople to a supported value
     */
    targetPortion: null,
    /**
     *  The array of shareType model instances
     *
     *  @argument
     *  @type {Array.<Models/ShareType>}
     *  @memberof Components/Shareconfig/ShareTypes
     */
    shareTypes: [],
    actions: {
      /**
       *  Update the shareType based on the
       *  mobile shareType slider's current index
       *
       *  @function actions:updateShareTypeByIndex
       *  @memberof Components/ShareConfig/ShareTypes
       */
      updateShareTypeByIndex: function updateShareTypeByIndex() {
        var shareIndex = Ember.get(this, 'mobileSlideIndex') + 1;
        var shareTypes = Ember.get(this, 'shareTypes');
        var typeAtIndex = shareTypes.findBy('id', shareIndex.toString());
        Ember.get(this, 'onSelectShareType')(typeAtIndex);
      }
    }
  });
});