define('mx/v2/util/cloudinary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * Utility functions for working with atk-content cloudinary images.
   *
   * @module cloudinary
   */

  /**
   * Construct a cloudinary URI with params.
   * @see {@link https://cloudinary.com/documentation/image_transformation_reference}
   *
   * @function makeCloudinaryUri
   * @param {string} uri Original cloudinary URI.
   * @param {string} params Cloudinary params.
   * @returns {string} Cloudinary URI with params.
   */
  function makeCloudinaryUri(uri, params) {
    if (!(uri && uri.includes('cloudinary') && params)) return uri;
    // Parameters need to be insterted directly after the '/image/upload/' portion of the URI.
    var separator = '/image/upload/';

    var _uri$split = uri.split(separator),
        _uri$split2 = _slicedToArray(_uri$split, 2),
        base = _uri$split2[0],
        rest = _uri$split2[1];

    return '' + String(base) + separator + String(params) + '/' + String(rest);
  }
  /**
   * Create a set of scaled photos using cloudinary params.
   * @see {@link https://cloudinary.com/documentation/image_transformation_reference}
   *
   * @param {string} type ATK content type.
   * @param {string} photo Photo URI.
   * @returns {object} Object containing cloudinary URIs for various image sizes.
   */
  function makeCloudinaryUrisForType(type, photo) {
    // ATK seems to use these on most of their site images.
    var base = 'c_fill,dpr_2.0,f_auto';
    if (type === 'recipe') {
      base = base + ',q_auto:low';
      return {
        sm: makeCloudinaryUri(photo, base + ',ar_1:1,h_60'),
        md: makeCloudinaryUri(photo, base + ',ar_1:1,h_150'),
        md_wide: makeCloudinaryUri(photo, base + ',ar_2:1,h_150,g_center'),
        lg: makeCloudinaryUri(photo, base + ',ar_1:1,h_400'),
        lg_wide: makeCloudinaryUri(photo, base + ',ar_2:1,h_400')
      };
    } else if (type === 'guide') {
      base = base + ',q_auto:low';
      return {
        sm: makeCloudinaryUri(photo, base + ',w_60'),
        md: makeCloudinaryUri(photo, base + ',w_150'),
        lg: makeCloudinaryUri(photo, base + ',w_400')
      };
    }
  }

  exports.makeCloudinaryUrisForType = makeCloudinaryUrisForType;
  exports.makeCloudinaryUri = makeCloudinaryUri;
});