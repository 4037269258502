define('mx/components/old-next-share', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    shareConfig: null,
    sharePresets: null,
    sharePeoples: null,
    shareSizes: null,
    currentOrder: null,
    position: 0,
    currentId: null,
    init: function init() {
      this._super();
      var self = this;

      self.checkMemberStatus();

      $(function () {
        $('.orders-past').click(function () {
          $(this).hide();
          $('.previous-orders').show();
        });
      });
      self.send('getPresets');
      self.send('getPeoples');
      self.send('getSizes');
      self.send('getCurrentOrderInfo');
      self.send('getAvailableDates');
      $(document).on('click', '.change-date', function () {
        $('#newDateModal').modal('show');
        $('.confirm-date-change').removeClass('change').removeClass('skip');
        $('#modal-change-date, #modal-skip-share').show();
        $('.date-warn, .date-warn-change, .date-warn-skip').hide();
      });

      $(document).on('click', '.eligible-date', function () {
        $('.eligible-date').removeClass('active-date');
        $('.active-elig').hide();
        $(this).addClass('active-date');
        $(this).find('.active-elig').show();
        $('.new-deliv-date').html($(this).html().split('<')[0]);

        if ($(this).attr('id') !== 'current') {
          $('#modal-change-date').removeClass('disabled').removeAttr('disabled');
        } else {
          $('#modal-change-date').addClass('disabled').attr('disabled', 'disabled');
        }
      });

      $(document).on('click', '#modal-change-date', function () {
        if (!$(this).hasClass('disabled')) {
          $('#modal-change-date, #modal-skip-share').hide();
          $('.date-warn').fadeIn(300);
          $('.date-warn-change').fadeIn(300);
          $('.confirm-date-change').addClass('change');
        }
      });

      $(document).on('click', '#modal-skip-share', function () {
        if (!$(this).hasClass('disabled')) {
          $('#modal-change-date, #modal-skip-share').hide();
          $('.date-warn').fadeIn(300);
          $('.date-warn-skip').fadeIn(300);
          $('.confirm-date-change').addClass('skip');
        }
      });

      $(document).on('click', '.change', function () {
        self.send('changeOrderDate', $('.active-date').attr('id'));
      });

      $(document).on('click', '.skip', function () {
        self.send('skipOrder');
      });
    },
    actions: {
      showPastOrders: function showPastOrders(orders) {
        var self = this;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = orders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var order = _step.value;

            var date = '';
            var weight = '';

            if (order.legacy) {
              var legacyData = JSON.parse(order.legacy);
              // if (legacyData.createdAt) {
              //   date = moment(legacyData.createdAt).format('MMMM, YYYY');
              // } else if (order.delivered_at) {
              //   date = moment(order.delivered_at).format('MMMM, YYYY');
              // }

              if (legacyData.packedWeight) {
                weight = legacyData.packedWeight + ' lbs';
              } else {
                weight = '0 lbs';
              }
            } else {
              weight = (order.packedWeight || 0) + ' lbs';
            }

            var status = order.status;
            if (order.status === 'cancelled') {
              status = 'skipped';
            }

            if (order.delivered_at) {
              date = (0, _moment.default)(order.delivered_at).format('MMMM, YYYY');
            } else if (order.service_partition) {
              date = (0, _moment.default)(order.service_partition.month).format('MMMM') + ', ' + order.service_partition.year;
            } else {
              date = (0, _moment.default)(order.updated_at).format('MMMM, YYYY');
            }
            if (parseInt(order.id) !== parseInt(self.currentId)) {
              $('#past-shares').append('\n        <div class="row" style="margin: 2em 0em; border: 1px solid #c7c7c7">\n         \n          <div class="col-12 col-md-4 p-3 text-center bg-light">' + String(date) + '</div>\n          <div class="col-12 col-md-4 p-3 text-center">' + String(status) + '</div>\n          <div class="col-12 col-md-4 p-3 text-center bg-light">' + weight + '</div>\n          </div>\n        ');
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      },
      changeOrderDate: function changeOrderDate(serviceDay) {
        var _this = this;

        var self = this;
        var token = self.get('session').session.content.authenticated.token;
        var tokenData = JSON.parse(atob(token.split('.')[1]));

        self.get('ajax').request('/members/' + tokenData.userId + '/move-to-makeup', {
          method: 'POST',
          headers: {
            token: token
          },
          data: {
            day: parseInt(serviceDay)
          }
        }).then(function (response) {
          _newArrowCheck(this, _this);

          location.reload(true);
        }.bind(this));
      },
      skipOrder: function skipOrder() {
        var _this2 = this;

        var self = this;
        var token = self.get('session').session.content.authenticated.token;
        var tokenData = JSON.parse(atob(token.split('.')[1]));

        self.get('ajax').request('/members/' + tokenData.userId + '/skip-share', {
          method: 'POST',
          headers: {
            token: token
          },
          data: {
            months: 1
          }
        }).then(function (response) {
          _newArrowCheck(this, _this2);

          location.reload(true);
        }.bind(this));
      },
      getAvailableDates: function getAvailableDates() {
        var _this3 = this;

        var self = this;
        var token = self.get('session').session.content.authenticated.token;
        var tokenData = JSON.parse(atob(token.split('.')[1]));

        self.get('ajax').request('/members/' + tokenData.userId + '/makeup-days', {
          method: 'GET',
          headers: {
            token: token
          }
        }).then(function (response) {
          _newArrowCheck(this, _this3);

          for (var i = 0; i < response.eligible.days.length; i++) {
            $('.dates-avail').append('<li id="' + response.eligible.days[i].day + '" class="eligible-date">' + (response.eligible.days[i].variance !== 0 ? 'Around ' : '') + response.eligible.days[i].date + '<i style="display: none" class="fa active-elig float-right fa-check-circle-o" aria-hidden="true"></i></li>');
          }

          if (response.eligible.days.length == 0) {
            $('.change-date').hide();
          }
        }.bind(this));
      },
      getPresets: function getPresets() {
        var _this4 = this;

        var self = this;
        self.get('ajax').request('shareconfig/share-presets', {
          method: 'GET',
          headers: {
            token: self.get('session').session.content.authenticated.token
          }
        }).then(function (response) {
          _newArrowCheck(this, _this4);

          this.sharePresets = response.share_presets;
        }.bind(this));
      },
      getPeoples: function getPeoples() {
        var _this5 = this;

        var self = this;
        self.get('ajax').request('shareconfig/share-peoples', {
          method: 'GET',
          headers: {
            token: self.get('session').session.content.authenticated.token
          }
        }).then(function (response) {
          _newArrowCheck(this, _this5);

          this.sharePeoples = response.share_peoples;
        }.bind(this));
      },
      getSizes: function getSizes() {
        var _this6 = this;

        var self = this;
        self.get('ajax').request('shareconfig/share-sizes', {
          method: 'GET',
          headers: {
            token: self.get('session').session.content.authenticated.token
          }
        }).then(function (response) {
          _newArrowCheck(this, _this6);

          this.shareSizes = response.share_sizes;
        }.bind(this));
      },
      getCurrentOrderInfo: function getCurrentOrderInfo() {
        var _this7 = this;

        var self = this;
        var token = self.get('session').session.content.authenticated.token;
        var memberId = JSON.parse(atob(token.split('.')[1]))['userId'];
        self.get('ajax').request('/members/' + memberId + '/orders/current/', {
          method: 'GET',
          headers: {
            token: token
          }
        }).then(function (response) {
          _newArrowCheck(this, _this7);

          self.shareConfig = response.order.share_config;
          // self.currentOrder = response.order;
          self.currentId = response.order.id;
          // $('.order-status').html(response.order.status);

          self.get('ajax').request('/members/' + memberId + '/delivery-estimate', {
            method: 'GET',
            headers: {
              token: token
            }
          }).then(function (resp) {
            _newArrowCheck(this, _this7);

            if (resp.success) {
              if ((0, _moment.default)(resp.estimate.latest).diff((0, _moment.default)(), 'days') > 30) {
                $('.change-date').hide();
              }
              if (resp.estimate.confidence == 2) {
                $('.order-date').html(resp['estimate'].human.date.replace(' - ', ' -<br />'));
              } else if (resp.estimate.confidence == 1) {
                $('.order-date').html(resp.estimate.human.date + ' +/- 1 day');
              } else {
                $('.order-date').html('Your share is still being routed');
              }
              $('.dates-avail').prepend('<li id="current" class="active-date eligible-date">' + resp['estimate'].human.date + '<i class="fa active-elig float-right fa-check-circle-o" aria-hidden="true"></i></li>');
            }
          }.bind(this));

          self.send('getCurrentConfig');
        }.bind(this)).catch(function (err) {
          _newArrowCheck(this, _this7);

          $('.order').first().html('\n            <div class="row mx-0 justify-content-center">\n              <div class="col text-center py-4">\n                <p class="m-0">You do not have any orders yet. Check back soon!</p>\n              </div>\n            </div>\n            ');
          $('.order-loader').hide();
          $('.order').fadeIn(300);
        }.bind(this));
      },
      getCurrentConfig: function getCurrentConfig() {
        var _this8 = this;

        var self = this;
        var token = self.get('session').session.content.authenticated.token;
        var memberId = JSON.parse(atob(token.split('.')[1]))['userId'];
        self.get('ajax').request('/members/' + memberId + '/share-configs/current', {
          method: 'GET',
          headers: {
            token: token
          }
        }).then(function (response) {
          _newArrowCheck(this, _this8);

          var self = this;
          var sc = response.share_config;
          var type = sc.share_type == 1 ? 'Basic Share: ' : 'Full Share: ';
          var addonCount = 0;
          var addonTotal = 0;
          // if (sc.share_type !== 1) {
          if (sc.share_preset) {
            for (var i = 0; i < self.sharePresets.length; i++) {
              if (self.sharePresets[i].id == sc.share_preset) {
                $('.current-order-title').html(self.sharePresets[i]);
              }
            }
          } else {
            var t = sc.share_type;
            var types = ['Just Grind', 'Basics', 'Complete', 'Custom'];
            $('.current-order-title').html(types[sc.share_type - 1]);
          }
          // } else {
          // $('.current-order-title').html(type);
          // }
          // $('.current-order-title').html(type + self.sharePresets[i].name);
          if (sc.share_people) {
            for (var i = 0; i < self.sharePeoples.length; i++) {
              if (self.sharePeoples[i].id == sc.share_people) {
                $('.order-people').html(self.sharePeoples[i].people_lower);
              }
            }
          } else {
            $('.order-people-wrapper').hide();
          }

          var price = 0;

          for (var i = 0; i < self.shareSizes.length; i++) {
            if (self.shareSizes[i].id == sc.share_size) {
              $('.order-size').html(self.shareSizes[i].weight_lower + 'lbs');
              price = self.shareSizes[i].price;

              // $('.order-price').html('$'+self.shareSizes[i].price/100)
            }
          }

          for (var i = 0; i < self.currentOrder.addons.length; i++) {
            console.log('curr orer:', self.currentOrder);
            if (self.currentOrder.addons[i].order_addons.quantity > 0 && addonCount <= 1) {
              var newMS = $('<li>');
              var unit = self.currentOrder.addons[i].order_addons.quantity == 1 ? self.currentOrder.addons[i].unit_singular || 'item' : self.currentOrder.addons[i].unit_plural || 'items';
              newMS.html(self.currentOrder.addons[i].display + ' - ' + self.currentOrder.addons[i].order_addons.quantity + ' ' + unit);
              $('.order-specials').append(newMS);
              if (self.currentOrder.addons[i].order_addons.quantity > 0) {
                price += self.currentOrder.addons[i].order_addons.quantity * self.currentOrder.addons[i].price;
              }
              addonCount++;
            }
            if (self.currentOrder.addons[i].order_addons.quantity > 0) addonTotal++;
          }
          $('.order-price').html('$' + price / 100);

          if (addonTotal - 2 > 0) $('.directive').html('+' + (addonTotal - 2) + ' More - View Specials');

          for (var i = 0; i < response.share_config.recurring_addons.length; i++) {
            if (response.share_config.recurring_addons[i].share_addons.quantity !== 0) {
              var curAdd = response.share_config.recurring_addons[i];
              var unit = curAdd.share_addons.quantity == 1 ? curAdd.unit_singular : curAdd.unit_plural;
              $('.order-monthly-addons').prepend('<li>' + curAdd.display + ' - ' + curAdd.share_addons.quantity + ' ' + unit + '</li>');
              if (response.share_config.recurring_addons[i].discount_map) {
                var dm = JSON.parse(response.share_config.recurring_addons[i].discount_map);
                price += response.share_config.recurring_addons[i].price * response.share_config.recurring_addons[i].share_addons.quantity - dm[response.share_config.recurring_addons[i].share_addons.quantity];
              } else {
                price += response.share_config.recurring_addons[i].price * response.share_config.recurring_addons[i].share_addons.quantity;
              }
            }
            var _token = this.get('session').session.content.authenticated.token;
            var tokenData = JSON.parse(atob(_token.split('.')[1]));
            this.get('ajax').request('/members/' + tokenData.userId, {
              method: 'GET',
              data: {
                token: _token
              }
            }).then(function (memresponse) {
              _newArrowCheck(this, _this8);

              if (response.share_config.share_type == 4 && memresponse.member.legacy_id) {
                price = price - 500;
              }
              $('.order-price').html('$' + price / 100);
            }.bind(this));
          }
          $('.order-loader').hide();
          $('.order').fadeIn(300);
        }.bind(this));
      }
    },
    checkMemberStatus: function checkMemberStatus() {
      var _this9 = this;

      var token = this.get('session').session.content.authenticated.token;
      var tokenData = JSON.parse(atob(token.split('.')[1]));
      this.get('ajax').request('/members/' + tokenData.userId, {
        method: 'GET',
        data: {
          token: token
        }
      }).then(function (response) {
        _newArrowCheck(this, _this9);

        if (!response.member.active) {
          $('.master-order').html('Your account is currently on hold.');
          $('.master-order').addClass('text-center p-3');
        } else if (response.member.service_type == 2) {
          $('.master-order .order-status').hide();
          $('.master-order .order-date').addClass('pickup-date').removeClass('order-date').html('Your delivery can be picked up any time Monday-Friday 8am-6pm after you recieve an email that your share is ready.');
        }
      }.bind(this));
    }
  });
});