define('mx/models/addon', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data'], function (exports, _model, _attr, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    display: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    available: _emberData.default.attr('number'),
    image_uri: _emberData.default.attr('string'),
    unit_plural: _emberData.default.attr('string'),
    unit_singular: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    quantity_max: _emberData.default.attr('number'),
    recurring: _emberData.default.attr('boolean'),
    freezable: _emberData.default.attr('boolean'),
    featured: _emberData.default.attr('boolean'),
    quantity: _emberData.default.attr('number'),
    global_limit: _emberData.default.attr('boolean'),
    perMonth: _emberData.default.attr('number', { defaultValue: 0 }),
    share_addon: _emberData.default.belongsTo('share-addon', { async: false }),
    parent: _emberData.default.attr('number', { defaultValue: null }),
    label: _emberData.default.attr('string'),
    sublabel: _emberData.default.attr('string'),
    discount_map: _emberData.default.attr('raw', { allowNull: true }),
    display_order: _emberData.default.attr('number'),
    display_type: _emberData.default.attr('number'),
    weighed: _emberData.default.attr('boolean'),
    children: _emberData.default.hasMany('addon'),
    unit_weight_upper: _emberData.default.attr('number'),
    unit_weight_lower: _emberData.default.attr('number'),
    showIngredients: _emberData.default.attr('boolean', { defaultValue: false }),
    ingredients: _emberData.default.attr('string', { defaultValue: null }),
    richDescription: Ember.computed('description', function () {
      return this.get('description').htmlSafe();
    }),
    price_range: Ember.computed('quantity', function () {
      var priceLow = 0;
      var priceHigh = 0;
      var weightLow = parseInt(this.get('unit_weight_lower'));
      var weightHigh = parseInt(this.get('unit_weight_upper'));
      var quantity = parseInt(this.get('quantity'));
      var price = parseInt(this.get('price'));

      if (this.get('unit_weight_upper') && this.get('unit_weight_lower')) {
        priceLow = (price * weightLow * quantity / 100).toFixed(2);
        priceHigh = (price * weightHigh * quantity / 100).toFixed(2);

        return '$' + String(priceLow) + ' - $' + String(priceHigh);
      } else {
        return '$' + String((price * quantity / 100).toFixed(2));
      }
    }),
    requested_quantity: _emberData.default.attr('number', { defaultValue: 1 })
  });
});