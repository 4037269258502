define('mx/v2/components/x-recipe/card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * A component to display the author of the recipe.
     * Provides greater customization that simple text.
     *
     * @type {Component}
     * @default null
     * @extends XRecipe/Card
     */
    author: null,
    /**
     * The maximum number of characters the cards text should
     * contain before being truncated.
     *
     * @type {number}
     * @default null
     * @memberof Components/XRecipe/Card
     */
    limit: null,
    /**
     * The URI of the card's photo.
     *
     * @type {string}
     * @default null
     * @memberof Components/XRecipe/Card
     */
    photo: null,
    /**
     * The text to display in the body of the card.
     *
     * @type {string}
     * @default null
     * @memberof Components/XRecipe/Card
     */
    text: null,
    /**
     * The title of the recipe.
     *
     * @type {string}
     * @default null
     * @memberof Components/XRecipe/Card
     */
    title: null
  });
});