define('mx/models/newuser', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    member: _emberData.default.belongsTo('member'),
    address: _emberData.default.belongsTo('address'),
    share_config: _emberData.default.belongsTo('v2-share-config')
  });
});