define('mx/controllers/sign-up/addons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    media: Ember.inject.service(),
    /**
     * The V2 Share Config model to mutate.
     *
     * @type {Models/V2ShareConfig}
     * @memberof Controllers/SignUp/Addons
     */
    shareConfig: Ember.computed.alias('model.shareConfig'),
    /**
     * The list of Addon models for use with recurring addon selection.
     *
     * @type {Array.<{Models/Addons>}}
     * @memberof Controllers/SignUp/Addons
     */
    addons: Ember.computed.alias('model.addons')
  });
});