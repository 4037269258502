define('mx/models/v2-share-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Attributes
    description: _emberData.default.attr('string'),
    displayGrind: _emberData.default.attr('number', { defaultValue: null }),
    displayOrder: _emberData.default.attr('number'),
    imageUri: _emberData.default.attr('string'),
    isBasic: _emberData.default.attr('boolean'),
    isComplete: _emberData.default.attr('boolean'),
    isCustom: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),

    // Associations
    cutTypes: _emberData.default.hasMany('v2-cut-type', { async: false }),
    sharePeoples: _emberData.default.hasMany('v2-share-people'),
    shareSizes: _emberData.default.hasMany('v2-share-size')
  });
});