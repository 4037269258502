define('mx/v2/components/atk-guides/overview-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkGuides/OverviewCard
     */
    author: null,
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkGuides/OverviewCard
     */
    photo: null,
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkGuides/OverviewCard
     */
    text: null,
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkGuides/OverviewCard
     */
    title: null,
    /**
     * @type {String}
     * @default null
     * @memberof Components/AtkGuides/OverviewCard
     */
    type: null
  });
});