define('mx/serializers/meat-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        'meat-types': payload.meat_types
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});