define('mx/v2/classes/atk-content-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /**
   * This class is used to group atk recipes and cut guides with a particular protein,
   * as there is no representative model that comes down from the API.
   *
   * @class AtkContentGroup
   * @extends EmberObject
   */
  var AtkContentGroup = Ember.Object.extend({
    /**
     * The list of cut guides for this group.
     *
     * @type {Array.<AtkCutGuide>}
     * @default null
     * @memberof AtkContentGroup
     */
    guides: null,
    /**
     * The image URI of the logo for this group.
     *
     * @type {String}
     * @default null
     * @memberof AtkContentGroup
     */
    photo: null,
    /**
     * The list of recipes for this group.
     *
     * @type {Array.<Models/AtkRecipe>}
     * @default null
     * @memberof AtkContentGroup
     */
    recipes: null,
    /**
     * The title of the recipe group.
     *
     * @type {String}
     * @default null
     * @memberof AtkContentGroup
     */
    title: null
  });

  exports.default = AtkContentGroup;
});