define('mx/components/mx/container-sm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mx-container-sm', 'border'],
    classNameBindings: ['selected:border-primary:border'],
    /**
     *  Selected value of container, for stylistic purposes
     *
     *  @argument
     *  @type {boolean}
     *  @memberof Components/MX/ContainerSM
     */
    selected: false,
    click: function click(e) {
      if (e) e.preventDefault();
      var onChange = this.get('onChange');
      if (onChange) onChange(!this.get('selected'));
    }
  });
});