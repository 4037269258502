define('mx/mixins/route-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Mixin.create({
    actions: {
      /**
       * Force the window to scroll top.
       *
       * @function actions:didTransition
       */
      didTransition: function didTransition() {
        var _this = this;

        $(document).ready(function () {
          _newArrowCheck(this, _this);

          $(window).scrollTop(0);
          // The nav doesn't want to collapse on mobile for some reason after
          // scrolling to the top. Let's force it to.
          $('#main-nav-toggler').collapse('hide');
        }.bind(this));
      }
    }
  });
});