define('mx/components/member-dashboards/referrals/recipient-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * List of recipient emails to display.
     *
     * @argument recipients
     * @type {Ember.Array}
     * @default null
     * @memberof Components/MemberDashboards/Referrals/RecipientList
     */
    recipients: null,
    /**
     * Closure to invoke upon attempt to remove recipient from list.
     *
     * @argument onRemoveRecipient
     * @type {function}
     * @default null
     * @memberof Components/MemberDashboards/Referrals/RecipientList
     */
    onRemoveRecipient: null
  });
});