define('mx/components/share-config/cut-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    viewRules: Ember.inject.service('vrShareConfig'),
    classNames: ['cut-selection']
  });
});