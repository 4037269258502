define('mx/v2/components/sign-up-addons/share-addons/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The list of Addon models to display.
     *
     * @type {Array.<Models/Addon>}
     * @default null
     * @memberof Components/SignUpAddons/ShareAddons
     */
    addons: null,
    /**
     * Whether or not the share config has Beef selected.
     *
     * @function hasBeef
     * @returns {boolean} Beef included.
     * @memberof Components/SignUpAddons/ShareAddons
     */
    hasBeef: Ember.computed('shareConfig.{meatExclusions.[]}', function () {
      var meatExclusions = Ember.get(this, 'shareConfig.meatExclusions');
      return !meatExclusions.findBy('name', 'Beef');
    }),
    /**
     * The V2 Share Config model to mutate.
     *
     * @type {Models/V2ShareConfig}
     * @default null
     * @memberof Components/SignUpAddons/ShareAddons
     */
    shareConfig: null
  });
});