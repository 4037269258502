define('mx/controllers/forgot-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    actions: {
      authenticateUser: function authenticateUser(credentials) {
        var _this = this;

        var authenticator = 'authenticator:jwt';
        var self = this;
        this.get('session').authenticate(authenticator, credentials).then(function () {
          _newArrowCheck(this, _this);

          self.transitionToRoute('/');
        }.bind(this));
      }
    }
  });
});