define('mx/components/mx/input-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes and Classes
    tagName: 'label',
    classNames: 'mx-checkbox-container',
    /**
     *  The Checkbox's selected value
     *
     *  @argument
     *  @type {boolean}
     *  @memberof Components/MX/InputCheckbox
     */
    selected: false,
    /**
     *  Send new selected value onChange, if the
     *  action is passed
     *
     *  @override
     *  @function click
     *  @memberof Components/MX/InputCheckbox
     */
    click: function click() {
      var selected = Ember.get(this, 'selected');
      var onChange = Ember.get(this, 'onChange');

      if (onChange) {
        onChange(!selected);
      }
    }
  });
});