define('mx/v2/components/atk-content/content-for/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    atk: Ember.inject.service('atk'),
    groups: Ember.computed.readOnly('atk.groups'),
    contentCategories: Ember.computed.readOnly('atk.contentCategories'),
    selectedCategory: Ember.computed.readOnly('atk.selectedCategory'),
    selectedGroup: Ember.computed.readOnly('atk.selectedGroup')
  });
});