define('mx/adapters/walden-api', ['exports', 'ember-data', 'mx/config/environment', 'ember-inflector', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _emberInflector, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:token',
    /**
     *  host - Walden API host
     *
     *  @type string
     *  @memberof adapter/WaldenAPIAdapter
     */
    host: _environment.default.walden_api,
    /**
     *  namespace - prepend /v2/ to all requests
     *
     *  @type String
     *  @memberof adapters/WaldenAPIAdapter
     *  @example
     *  '<API_URL>/endpoint' => '<API_URL>/v2/endpoint'
     */
    namespace: 'v2',
    /**
     *  remove v2- from key names
     *
     *  @override
     *  @method pathForType
     *  @param {string} type - key name for model type
     *  @returns {string} modified type key name
     *  @memberof adapters/WaldenAPIAdapter
     *  @example
     *  // Returns 'meat-types'
     *  pathForType('v2-meat-type');
     */
    pathForType: function pathForType(type) {
      // Remove the v2- prefix from the adapter path,
      // and pluralize the result
      type = type.replace('v2-', '');
      return (0, _emberInflector.pluralize)(type);
    }
  });
});