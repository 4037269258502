define('mx/v2/components/mx/x-step/card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * Shows 'disabled' styles if false.
     *
     * @type {boolean}
     * @default false
     * @memberof Components/Mx/XStep/Card
     */
    enabled: false
  });
});