define('mx/controllers/members/seasonal/build', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      shareCancelled: function shareCancelled() {
        this.transitionToRoute('members.next-share');
      }
    }
  });
});