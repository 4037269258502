define('mx/routes/members/recipes/guide', ['exports', 'mx/mixins/atk-route-image'], function (exports, _atkRouteImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_atkRouteImage.default, {
    /**
     * @override
     * @function lifecycle:model
     * @memberof Routes/Members/Recipes/Guide
     */
    model: function model(params) {
      return this.store.findRecord('atk-cut-guide', params.guide_id);
    }
  });
});