define('mx/components/mx/content-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     *  Whether or not the toggle area is
     *  open/visible
     *
     *  @argument
     *  @type {boolean}
     *  @memberof Components/MX/ContentToggle
     */
    isOpen: false,
    actions: {
      /**
       *  Show or hide the toggle area
       *
       *  @function actions:toggle
       *  @memberof Components/MX/ContentToggle
       */
      toggle: function toggle() {
        this.toggleProperty('isOpen');
      }
    }
  });
});