define('mx/components/mx/selected-proxy-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes and Classes
    tagName: '',
    /** @type {Ember.Object}
     *  @memberof Components/MX/SelectedProxyItem
     */
    item: null,
    actions: {
      /**
       *  Send change action informing of the items
       *  selected state
       *
       *  @function actions:onChange
       *  @param {boolean} selected - inverse selected
       *  @memberof Components/MX/SelectedProxyItem
       */
      onChange: function onChange() {
        var selected = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : !Ember.get(this, 'item.selected');

        var _EmberGet = Ember.get(this, 'item'),
            model = _EmberGet.model;

        var updateState = Ember.get(this, 'onChange');
        Ember.set(this, 'item.selected', selected);
        updateState(selected, model);
      }
    }
  });
});