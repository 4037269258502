define('mx/components/share-config/share-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Description of the share type.
     * Expected to be an HTML string.
     *
     * @type {string}
     * @default null
     * @memberof Components/ShareConfig/ShareType
     */
    description: null,
    /**
     * The image URI for the share type.
     *
     * @type {string}
     * @default null
     * @memberof Components/ShareConfig/ShareType
     */
    image: null,
    /**
     * The name of the share type.
     *
     * @type {string}
     * @default null
     * @memberof Components/ShareConfig/ShareType
     */
    name: null
  });
});