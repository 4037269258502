define('mx/routes/members/fresh/next-delivery', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Route.extend({
    fresh: Ember.inject.service('fresh-pilot'),
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);
      // If the member is not yet able to access the 'Fresh Delivery' tab, send them
      // back to the dashboard.
      if (!this.get('fresh.showNextDelivery')) {
        this.transitionTo('members.index');
      } else {
        // Otherwise, attempt to load their most recent pilot share config (Wufoo submission).
        return this.get('fresh').loadFreshWufooSubmissions().catch(function () {
          _newArrowCheck(this, _this);

          return;
        }.bind(this));
      }
    }
  });
});