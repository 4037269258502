define('mx/components/loader-ball-zig-zag-deflect', ['exports', 'ember-cli-loaders/components/loader-ball-zig-zag-deflect'], function (exports, _loaderBallZigZagDeflect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loaderBallZigZagDeflect.default;
    }
  });
});