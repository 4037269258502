define('mx/helpers/weight-round', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.weightRound = weightRound;
  function weightRound(params /*, hash*/) {
    return Math.round(params[0] * 10) / 10;
  }

  exports.default = Ember.Helper.helper(weightRound);
});