define('mx/components/member-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['wd-member-nav'],
    fresh: Ember.inject.service('fresh-pilot')
  });
});