define('mx/components/share-config/dietary-restrictions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     *  The array in which to store selected dietaryRestrictions
     *
     *  @argument
     *  @type {Array.<Models/DietaryRestriction>}
     *  @memberof Components/ShareConfig/DietaryRestrictions
     */
    target: [],
    /**
     *  The array of dietaryRestriction model instances
     *
     *  @argument
     *  @type {Array.<Models/DietaryRestriction>}
     *  @memberof Components/ShareConfig/DietaryRestrictions
     */
    dietaryRestrictions: []
  });
});