define('mx/breakpoints', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    tiny: '(max-width: 575.98px)',
    mobile: '(max-width: 767px)',
    tablet: '(min-width: 768px) and (max-width: 991px)',
    desktop: '(min-width: 992px) and (max-width: 1200px)',
    mobileLazy: '(max-width: 991px)', // Equivalent to 'isMobile or isTablet'.
    desktopLazy: '(min-width: 992px)' // Equivalent to 'isDesktop or isJumbo'.
  };
});