define('mx/v2/components/mx/validated-form/control/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['form-group'],
    /**
     * Optional component to show in place of the validation message
     * for a particular validation type.
     *
     * @default null
     * @type {object}
     * @param {string} messageComponent.component Component to show.
     * @param {string} type Validation error type for the component.
     * @memberof Components/Mx/ValidatedForm/Control
     */
    messageComponent: null,
    /**
     * The unique ID for this form control element.
     *
     * @type {string}
     * @memberof Components/Mx/ValidatedForm/Control
     */
    uid: Ember.computed(function () {
      return 'control-' + String(Ember.guidFor(this));
    }),
    /**
     * Whether or not our form control has a value.
     *
     * @type {boolean}
     * @memberof Components/Mx/ValidatedForm/Control
     */
    hasContent: Ember.computed.notEmpty('value').readOnly(),
    /**
     * An async validation is running.
     *
     * @type {boolean}
     * @memberof Components/Mx/ValidatedForm/Control
     */
    isValidating: Ember.computed.alias('validation.isValidating'),
    /**
     * No async validations are running.
     *
     * @type {boolean}
     * @memberof Components/Mx/ValidatedForm/Control
     */
    notValidating: Ember.computed.not('isValidating').readOnly(),
    /**
     * Whether or not we should display validations.
     * This mainly replaces the need to have a variable set on the 'focus-out' action of a two-way input.
     *
     * @type {boolean}
     * @memberof Components/Mx/ValidatedForm/Control
     */
    shouldDisplayValidations: Ember.computed.or('showValidations', 'didValidate', 'hasContent').readOnly(),
    /**
     * Whether or not to show the validation error message.
     *
     * @type {boolean}
     * @memberof Components/Mx/ValidatedForm/Contol
     */
    showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid', 'notValidating').readOnly(),
    /**
     * Show a custom message component instead of the validation message.
     * The component is shown only if the type of validation error type equals
     * that provided in the messageComponent argument.
     *
     * @type {boolean}
     * @memberof Components/Mx/ValidatedForm/Control
     */
    showMessageComponent: Ember.computed('showErrorMessage', 'messageComponent', function () {
      var msg = Ember.get(this, 'messageComponent');
      if (msg) {
        var component = msg.component,
            type = msg.type;

        if (component && type && type === Ember.get(this, 'validation.error.type')) {
          return true;
        }
      }
      return false;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      // The path of the value to validate relative the provided model.
      var valuePath = this.get('valuePath');

      // Define the derived validation and value properties.
      // Since we're using computed propery macros here, we utilize 'defineProperty'
      // to construct a dependentKey string that relys on this component's arguments.
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + String(valuePath)));
      Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + String(valuePath)));
    }
  });
});