define('mx/helpers/addon-range-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addonRangePrice = addonRangePrice;
  function addonRangePrice(params /*, hash*/) {
    var addon = params[0];
    var priceLow = 0;
    var priceHigh = 0;
    var weightLow = parseInt(addon.get('unit_weight_lower'));
    var weightHigh = parseInt(addon.get('unit_weight_upper'));
    var quantity = parseInt(addon.get('quantity'));
    var price = parseInt(addon.get('price'));

    priceLow = (price * weightLow * quantity / 100).toFixed(2);
    priceHigh = (price * weightHigh * quantity / 100).toFixed(2);

    return '$' + String(priceLow) + ' - $' + String(priceHigh);
  }

  exports.default = Ember.Helper.helper(addonRangePrice);
});