define('mx/components/holiday-share', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    estimatedDate: Ember.computed(function () {
      var share = this.get('share');
      if (share.service_day_details) {
        var estimate = (0, _moment.default)(share.service_day_details.calendar_date).format('MMM, DD YYYY');
        return estimate;
      } else {
        return 'TBD';
      }
    })
  });
});