define('mx/models/dietary-restriction', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data'], function (exports, _model, _attr, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    hidden: _emberData.default.attr('boolean'),
    selected: _emberData.default.attr('boolean', { defaultValue: false })
  });
});