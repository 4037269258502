define('mx/v2/components/sign-up-review/referral-promo-for/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Component.extend({
    /**
     * Available set of promotional texts to provied for the eligible
     * referral campaign.
     * Supports 'mailer', 'radio' and 'referral' texts.
     *
     * @type {object}
     * @default null
     * @memberof Components/SignUpReview/ReferralPromoFor
     */
    campaignTexts: null,
    /**
     * The zip code to lookup in the set of mailer zips.
     *
     * @type {string}
     * @default null
     * @memberof Components/SignUpReview/ReferralPromoFor
     */
    code: null,
    /**
     * The set of zip codes for various mailer campaigns.
     *
     * @type {object}
     * @default null
     * @memberof Components/SignUpReview/ReferralPromoFor
     */
    mailerZips: null,
    /**
     * The referral type of the user attempting to sign-up.
     *
     * @type {string}
     * @default null
     * @memberof Components/SignUpReview/ReferralPromoFor
     */
    referralDisplay: null,
    /**
     * The promotional text for the referral campaign.
     *
     * The supported referral campaigns are:
     * 1. Mailer Campaign: Referral type is 'Post Card' and their zip code is within the set of zips for the mailer campaign.
     * 2. Radio Campaign: Referral type is '98.5 The Sports Hub'
     * 3. Member Referral Campaign: Referral type is 'Referred by Current Member'
     * 4. Mailer Breakfast Sausages Campaign: Referral type is 'Post Card' and their zip code is within the set of zips for the mailer campaign.
     *
     * @function text
     * @returns {string} Promo text.
     * @memberof Components/SignUpReview/ReferralPromoFor
     */
    text: Ember.computed('campaignTexts', 'code', 'mailerZips', 'referralDisplay', function () {
      var _this = this;

      var _EmberGetProperties = Ember.getProperties(this, 'campaignTexts', 'code', 'mailerZips', 'referralDisplay'),
          campaignTexts = _EmberGetProperties.campaignTexts,
          code = _EmberGetProperties.code,
          mailerZips = _EmberGetProperties.mailerZips,
          referralDisplay = _EmberGetProperties.referralDisplay;

      var text = void 0;
      var isEligibleCode = function (c, cds) {
        _newArrowCheck(this, _this);

        return Array.isArray(cds) && cds.includes(c);
      }.bind(this);
      var breakfastPackCodes = mailerZips.zip_codes,
          sausageCodes = mailerZips.sausage_zip_codes;


      if (referralDisplay === 'Post Card') {
        if (isEligibleCode(code, breakfastPackCodes)) {
          text = Ember.get(campaignTexts, 'mailer');
        } else if (isEligibleCode(code, sausageCodes)) {
          text = Ember.get(campaignTexts, 'mailer-sausage');
        }
      } else if (referralDisplay === '98.5 The Sports Hub') {
        /*
         *text = get(campaignTexts, 'radio');
         */
      } else if (referralDisplay === 'Referred by Current Member') {
        /*
        text = get(campaignTexts, 'referral');
        */
      } else if (referralDisplay === 'Facebook/Instagram') {
        /*
         *text = get(campaignTexts, 'referral');
         */
      }
      return text;
    }).readOnly()
  });
});