define('mx/controllers/members/next-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    metrics: Ember.inject.service('metrics'),
    credits: Ember.computed.alias('model.credits'),
    waldenMember: Ember.inject.service('member'),
    showPastShares: false,
    loadingDateChange: false,
    loadingSkip: false,
    pastShares: null,
    itemOffset: 0,
    pageSize: 6,

    /**
     *  Helper function to access member status
     *
     *  @computed
     *  @function cancelCheck()
     *  @returns {boolean} whether or not to show credit display
     *  @memberof Controllers/Members/NextShare
     */
    cancelCheck: Ember.computed('waldenMember', function () {
      var waldenMember = Ember.get(this, 'waldenMember');
      var member = Ember.get(waldenMember, 'member');
      var cancelled = member.get('active') === false;
      // return true
      return cancelled ? true : false;
    }),
    /**
     *  Credit balance should be shown if > 0
     *
     *  @computed
     *  @function showCredit()
     *  @returns {boolean} whether or not to show credit display
     *  @memberof Controllers/Members/NextShare
     */
    showCredit: Ember.computed('credits', function () {
      var credits = Ember.get(this, 'credits');
      return credits ? true : false;
    }),
    actions: {
      /**
       * Creates a 'paginated' list of past shares to view.
       * Each page is added onto the current list of past shares,
       * effectively creating a single page that continuously grows.
       * Each time this actions is fired, 6 additional past shares
       * are added to the list, until there are no more shares to view.
       */
      viewPastShares: function viewPastShares() {
        var _EmberGetProperties = Ember.getProperties(this, 'metrics', 'itemOffset', 'pageSize'),
            metrics = _EmberGetProperties.metrics,
            itemOffset = _EmberGetProperties.itemOffset,
            pageSize = _EmberGetProperties.pageSize;

        var shares = Ember.get(this, 'model.shares');
        var offset = itemOffset + pageSize; // increase offset by next 'page' size
        var page = shares.toArray().slice(0, offset);
        Ember.set(this, 'itemOffset', offset);
        Ember.set(this, 'pastShares', page);
        Ember.set(this, 'showPastShares', true);
        metrics.trackEvent('GoogleAnalytics', {
          category: 'next-share',
          action: 'click',
          label: 'show_past_shares'
        });
      },
      /**
       * Changes the delivery date for the current share.
       * @param {number} date - ID of the delivery date.
       */
      changeDeliveryDate: function changeDeliveryDate(date) {
        var _this = this;

        this.set('loadingDateChange', true);
        var token = this.get('session').session.content.authenticated.token;
        var tokenData = JSON.parse(atob(token.split('.')[1]));
        var memberId = tokenData.userId;

        this.get('ajax').request('/members/' + memberId + '/move-to-makeup', {
          method: 'POST',
          headers: {
            token: token
          },
          data: {
            day: date
          }
        }).then(function () {
          _newArrowCheck(this, _this);

          this.get('metrics').trackEvent('GoogleAnalytics', {
            category: 'next-share',
            action: 'click',
            label: 'change_date'
          });
        }.bind(this)).finally(function () {
          _newArrowCheck(this, _this);

          this.set('loadingDateChange', false);
          this.send('reloadPage');
        }.bind(this));
      },

      /**
       * Skips the current share.
       */
      skipShare: function skipShare() {
        var _this2 = this;

        this.set('loadingSkip', true);
        var token = this.get('session').session.content.authenticated.token;
        var tokenData = JSON.parse(atob(token.split('.')[1]));
        var memberId = tokenData.userId;

        this.get('ajax').request('/members/' + memberId + '/skip-share', {
          method: 'POST',
          headers: {
            token: token
          },
          data: {
            months: 1
          }
        }).then(function () {
          _newArrowCheck(this, _this2);

          this.get('metrics').trackEvent('GoogleAnalytics', {
            category: 'next-share',
            action: 'click',
            label: 'skip share'
          });
        }.bind(this)).finally(function () {
          _newArrowCheck(this, _this2);

          this.set('loadingSkip', false);
          this.send('reloadPage');
        }.bind(this));
      }
    }
  });
});