define('mx/v2/components/x-recipe/list-subtitle/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'p',
    classNames: ['text-underline']
  });
});