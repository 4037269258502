define('mx/v2/components/atk-recipes/recipe-provider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * The atk recipe.
     *
     * @type {Models/AtkRecipe}
     * @default null
     * @memberof Components/AtkRecipes/RecipeProvider
     */
    recipe: null
  });
});