define('mx/routes/members/fresh', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    fresh: Ember.inject.service('fresh-pilot'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      // If there's no fresh pilot, send the member back to the dashboard.
      if (!this.get('fresh.pilot')) {
        this.transitionTo('members.index');
      }
    }
  });
});