define('mx/adapters/addon', ['exports', 'mx/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = _application.default.extend({
    url: '/cut-listings',
    // pathForType: '/cut-listings',
    ajax: Ember.inject.service('ajax'),
    findAll: function findAll(modelName, query) {
      var _this = this;

      return this.get('ajax').request('/shareconfig/addons?count=9999', {
        method: 'GET'
      }).then(function (response) {
        _newArrowCheck(this, _this);

        return response;
      }.bind(this));
    }
    // query: function(modelName, query) {
    //   console.log('query-----------------', query;
    //   return this.get('ajax')
    //     .request('/cut-listings?count=9999', {
    //       method: 'GET'
    //     })
    //     .then((response) => {
    //       return response;
    //     });
    // }
  });
});