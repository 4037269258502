define('mx/components/share-config/price-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    waldenPrice: Ember.inject.service('waldenPrice'),
    sharePrice: Ember.computed('shareConfig.{shareSize,meatExclusions.[],cutGroupInclusions.[]}', function () {
      var waldenPrice = Ember.get(this, 'waldenPrice');
      var shareConfig = Ember.get(this, 'shareConfig');
      return Math.floor(waldenPrice.getSharePrice(shareConfig, true));
    })
  });
});