define('mx/models/atk-ingredient', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /** Attributes **/
    // E.g. 'Produce' or 'Panytry/Dry Goods'.
    kind: (0, _attr.default)('string'),
    // E.g. 'baguettes'.
    pluralTitle: (0, _attr.default)('string'),
    // E.g. 'baguette'.
    title: (0, _attr.default)('string')
  });
});