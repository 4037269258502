define('mx/components/share-config/recurring-addon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     *  The Addon Model Instance
     *
     *  @argument
     *  @type {Models/Addon}
     *  @memberof Components/ShareConfig/RecurringAddon
     */
    addon: null,
    /**
     *  The Array Managing Selected Addons
     *
     *  @argument
     *  @type {Array.<Models/Addon>}
     *  @memberof Components/ShareConfig/RecurringAddon
     */
    target: null
  });
});