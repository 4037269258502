define('mx/helpers/moment-month-year', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  /**
   * Converts a date to a 'month, year' ('MMMM, YYYY') formatted string.
   * @param {Object[]} params - Helper params
   * @param {Object|string} params[].0 - Date to format
   * @returns {string} 'month, year' ('MMMM, YYYY') formatted string
   * @memberof helpers
   * @example
   * // returns April, 2018
   * momentMonthYear('04-10-2018')
   * @example
   * // returns December, 2017
   * momentMonthYear(moment().year(2017).month(11))
   */
  var momentMonthYear = function (params /*, hash*/) {
    _newArrowCheck(undefined, undefined);

    return (0, _moment.default)(params[0]).format('MMMM, YYYY');
  }.bind(undefined);

  exports.default = Ember.Helper.helper(momentMonthYear);
});