define('mx/helpers/gt', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  /**
   * Determines if a value is greater than another.
   * @param {Object[]} params - Helper params
   * @param {number} params[].0 - Value to compare with
   * @param {number} params[].1 - Value to compare against
   * @returns {boolean} Result of greater-than comparison
   * @memberof helpers
   * @example
   * // returns true
   * gt(10, 1)
   * @example
   * // returns false
   * gt(1, 5)
   */
  var gt = function (params) {
    _newArrowCheck(undefined, undefined);

    return params[0] > params[1];
  }.bind(undefined);

  exports.default = Ember.Helper.helper(gt);
});