define('mx/v2/components/atk-content/groups-nav-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Whether or not the nav item is active.
     *
     * @type {Boolean}
     * @default false
     * @memberof Components/AtkContent/GroupsNavItem
     */
    active: false,
    /**
     * The atk content group.
     *
     * @type {AtkContentGroup}
     * @default null
     * @memberof Components/AtkContent/GroupsNavItem
     */
    group: null
  });
});