define('mx/components/forgot-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    positionalParams: ['token'],
    token: null,
    didInsertElement: function didInsertElement() {
      var self = this;
      if (self.get('token') && self.get('token').length > 1) {
        $('.reset-info').hide();
        $('.reset-success').hide();
        $('.new-password-info').show();
      }
    },
    actions: {
      submitNewRequest: function submitNewRequest() {
        var self = this;
        var error = [];
        $('.password-error').html('');
        if ($('#new-password').val().length < 5) {
          error.push('Password must be at least 6 characters');
        }

        if ($('#new-password').val() !== $('#confirm-new-password').val()) {
          error.push('Passwords do not match');
        }

        if (error.length !== 0) {
          for (var i = 0; i < error.length; i++) {
            $('.password-error').append(error[i] + '<br />');
          }
        } else {
          self.send('confirmResetPassword');
        }
      },
      captchaSuccess: function captchaSuccess(token) {
        var self = this;
        self.send('resetPassword', token);
      },
      resetPassword: function resetPassword(token) {
        var _this = this;

        $('.not-found').html('');
        var self = this;
        var captcha = '';
        var email = $('#email-reset').val();
        self.get('ajax').request('password-reset', {
          method: 'POST',
          data: {
            recaptcha: captcha,
            email: email
          }
        }).then(function (response) {
          _newArrowCheck(this, _this);

          if (response.success) {
            $('.reset-info').hide();
            $('.reset-success').show();
          }
        }.bind(this)).catch(function (error) {
          _newArrowCheck(this, _this);

          var details = error.payload.message;
          var array_details = error.payload.errors;
          var message = void 0;
          if (details != null) {
            message = 'there was a problem accessing your account: ' + String(error.payload.message);
          } else if (array_details[0] != null) {
            message = 'there was a problem accessing your account: ' + String(array_details[0]);
          } else {
            message = 'there was a problem accessing your account: ' + String(error.message);
          }
          $('.not-found').html(message);
          self.get('ajax').request('logPasswordFailure', {
            method: 'POST',
            data: {
              email: email,
              message: message
            }
          });
        }.bind(this));
      },
      confirmResetPassword: function confirmResetPassword() {
        var _this2 = this;

        var self = this;
        $('.password-error').html('');
        var tokenInfo = JSON.parse(atob(self.token.split('.')[1]));
        self.get('ajax').request('members/' + tokenInfo.userId + '/password', {
          method: 'POST',
          data: {
            new_password: $('#new-password').val()
          },
          headers: {
            token: self.token
          }
        }).then(function (response) {
          _newArrowCheck(this, _this2);

          self.sendAction('authenticateUser', { identification: tokenInfo.user, password: $('#new-password').val() });
        }.bind(this)).catch(function (error) {
          _newArrowCheck(this, _this2);

          //TODO FIX
          var details = error.payload.message;
          var array_details = error.payload.errors;
          var message = void 0;
          if (details != null) {
            message = 'there was a problem saving your password: ' + String(error.payload.message);
          } else if (array_details[0] != null) {
            message = 'there was a problem saving your password: ' + String(array_details[0]);
          } else {
            message = 'there was a problem saving your password: ' + String(error.message);
          }
          $('.password-error').html(message);
          self.get('ajax').request('logPasswordFailure', {
            method: 'POST',
            data: {
              email: '' + String(tokenInfo),
              message: message
            }
          });
        }.bind(this));
      }
    }
  });
});