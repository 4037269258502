define('mx/components/mx/validated-form-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * The unique ID for this form control element.
     *
     * @computed
     * @type {string}
     * @memberof Components/Mx/ValidatedFormControl
     */
    uid: Ember.computed(function () {
      return 'control-' + String(Ember.guidFor(this));
    }),
    /**
     * Whether or not our form control has a value.
     *
     * @computed
     * @type {boolean}
     * @memberof Components/Mx/ValidatedFormControl
     */
    hasContent: Ember.computed.notEmpty('value').readOnly(),
    /**
     * Whether or not our validations are currently running.
     * Mainly useful with async validation (i.e. duplicate email lookup).
     *
     * @computed
     * @type {boolean}
     * @memberof Components/Mx/ValidatedFormControl
     */
    notValidating: Ember.computed.not('validation.isValidating').readOnly(),
    /**
     * Whether or not we should display validations.
     * This mainly replaces the need to have a variable set on the 'focus-out' action of a two-way input.
     *
     * Note: A use case for the 'didValidate' prop is to defer showing
     * validation errors until after a form submission attempt is made
     * (this is used for the login form in belgian-blue).
     *
     * @computed
     * @type {boolean}
     * @memberof Components/Mx/ValidatedFormControl
     */
    shouldDisplayValidations: Ember.computed.or('showValidations', 'didValidate', 'hasContent').readOnly(),
    /**
     * Whether or not to show the validation error message.
     *
     * @computed
     * @type {boolean}
     * @memberof Components/Mx/ValidatedFormContol
     */
    showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      // The path of the value to validate relative the provided model.
      var valuePath = this.get('valuePath');

      // Define the derived validation and value properties.
      // Since we're using computed propery macros here, we utilize 'defineProperty'
      // to construct a dependentKey string that relys on this component's arguments.
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + String(valuePath)));
      Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + String(valuePath)));
    }
  });
});