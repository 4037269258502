define('mx/adapters/pilot-member', ['exports', 'mx/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(type) {
      return String(Ember.String.dasherize(type)) + 's';
    }
  });
});