define('mx/components/member-dashboards/referrals/recipient-input', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Validations for the recipient email address.
   */
  var Validations = (0, _emberCpValidations.buildValidations)({
    recipient: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],
      message: 'Please enter a valid email address.'
    }
  });

  /**
   * Referral email recipient input.
   *
   * @class Components/MemberDashboards/Referrals/RecipientInput
   * @extends Ember.Component
   */
  exports.default = Ember.Component.extend(Validations, {
    /**
     * Recipient email address from input.
     *
     * @argument recipient
     * @type {string}
     * @default null
     * @memberof Components/MemberDashboards/Referrals/RecipientInput
     */
    recipient: null,
    /**
     * Closure to invoke with the current recipient email from the input.
     *
     * @argument onAddRecipient
     * @type {function}
     * @default null
     * @memberof Components/MemberDashboards/Referrals/RecipientInput
     */
    onAddRecipient: null,
    /**
     * Whether or not to force disable the 'Add Email' button.
     *
     * @argument disableAdd
     * @type {boolean}
     * @default false
     * @memberof Components/MemberDashboards/Referrals/RecipientInput
     */
    disableAdd: false,
    /**
     * Clean up recipient input value when this component gets destroyed.
     *
     * @override
     * @method lifecycle:didDestroyElement
     * @memberof Components/MemberDashboards/Referrals/RecipientInput
     */
    didDestroyElement: function didDestroyElement() {
      this.setProperties({ recipient: null });
    },

    actions: {
      /**
       * Clear the currently entered recipient after invoking the supplied closure.
       *
       * @method actions:handleAddRecipient
       * @memberof Components/MemberDashboards/Referrals/RecipientInput
       */
      handleAddRecipient: function handleAddRecipient() {
        var _getProperties = this.getProperties('recipient', 'onAddRecipient'),
            recipient = _getProperties.recipient,
            onAddRecipient = _getProperties.onAddRecipient;

        if (onAddRecipient) {
          onAddRecipient(recipient);
          Ember.set(this, 'recipient', null);
        }
      }
    }
  });
});