define('mx/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    userInfo: Ember.computed.alias('applicationController.userInfo'),
    media: Ember.inject.service(),
    actions: {
      authenticate: function authenticate(credentials) {
        var _this = this;

        var authenticator = 'authenticator:jwt';

        return this.get('session').authenticate(authenticator, credentials).then(function () {
          _newArrowCheck(this, _this);

          this.transitionToRoute('/');
        }.bind(this));
      }
    }
  });
});