define('mx/serializers/pilot', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    /**
     * Underscore all object keys in the outgoing payload.
     *
     * @override
     * @method keyForAttribute
     * @memberof Serializers/Pilot
     */
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.underscore(key);
    }
  });
});