define('mx/serializers/atk-cut-guide', ['exports', 'ember-data/serializers/embedded-records-mixin', 'mx/serializers/atk'], function (exports, _embeddedRecordsMixin, _atk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _atk.default.extend(_embeddedRecordsMixin.default, {
    /**
     * Embedded associations on an ATK cut guide payload.
     *
     * @type {Object}
     * @memberof Serializers/AtkCutGuide
     */
    attrs: {
      recipes: { embedded: 'always' }
    }
  });
});