define('mx/serializers/atk-recipe-ingredient', ['exports', 'ember-data/serializers/embedded-records-mixin', 'mx/serializers/atk'], function (exports, _embeddedRecordsMixin, _atk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _atk.default.extend(_embeddedRecordsMixin.default, {
    /**
     * Embedded associations for an ATK recipe ingredient payload.
     *
     * @type {object}
     * @memberof Serializers/AtkRecipeIngredient
     */
    attrs: {
      ingredient: { embedded: 'always', key: 'ingredient_details' }
    }
  });
});