define('mx/routes/forgot-password', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    token: null,
    beforeModel: function beforeModel(transition) {
      this.token = transition.queryParams.token;
      this._super.apply(this, arguments);
    },
    model: function model() {
      return this.token;
    }
  });
});