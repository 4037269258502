define('mx/components/share-config/meat-types', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    viewRules: Ember.inject.service('vrShareConfig'),
    /**
     * Closure to invoke when a meat type is selected.
     *
     * @argument
     * @type {function}
     * @default null
     * @memberof Components/ShareConfig/MeatTypes
     */
    onSelectMeatType: null,
    /**
     *  The array in which to manage selected meatTypes
     *
     *  @argument
     *  @type {Array.<Models/MeatType>}
     *  @memberof Components/ShareConfig/MeatTypes
     */
    target: [],
    /**
     *  The array of meatType model instances
     *
     *  @argument
     *  @type {Array.<Models/MeatType>}
     *  @memberof Components/ShareConfig/MeatTypes
     */
    meatTypes: []
  });
});