define('mx/components/mx/mx-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: 'btn',
    attributeBindings: ['disabled']
  });
});