define('mx/v2/components/sign-up-addons/share-addon/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The Addon model to display.
     *
     * @type {Models/Addon}
     * @default null
     * @memberof Components/SignUpAddons/ShareAddon
     */
    addon: null,
    /**
     * The array of Addon models to mutate when the addon's selection changes.
     *
     * @type {Array.<Models/Addon>}
     * @default null
     * @memberof Components/SignUpAddons/ShareAddon
     */
    target: null,
    /**
     * The V2 Share Size model of the share config used for the additional ground beef display.
     *
     * @type {Models/V2ShareSize}
     * @default null
     * @memberof Components/SignUpAddons/ShareAddon
     */
    shareSize: null
  });
});