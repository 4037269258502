define('mx/v2/components/sign-up-review/steps/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * The Address model to mutate.
     *
     * @type {Models/Address}
     * @default null
     * @memberof Components/SignUpReview/Steps
     */
    address: null,
    /**
     * The Member model to mutate.
     *
     * @type {Models/Member}
     * @default null
     * @memberof Components/SignUpReview/Steps
     */
    member: null,
    /**
     * The Referral Lookup model that's present if the user is attempting
     * to sign-up using a referral code.
     *
     * @type {Models/ReferralLookup}
     * @default null
     * @memberof Components/SignUpReview/Steps
     */
    referralInfo: null,
    /**
     * The list of Referral Type models to show in the profile step.
     *
     * @type {Array.<Models/ReferralType>}
     * @default null
     * @memberof Components/SignUpReview/Steps
     */
    referralTypes: null,
    /**
     * The Stripe Token to mutate.
     *
     * @type {object}
     * @default null
     * @memberof Components/SignUpReview/Steps
     */
    stripeToken: null
  });
});