define('mx/components/share-config/share-portions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     *  The number of meals a given arrangement of shareSize
     *  and sharePortion would yield
     *
     *  @function
     *  @computed
     *  @returns {number} the number of meals
     *  @memberof Components/ShareConfig/SharePortions
     */
    numberOfMeals: Ember.computed('target', 'shareSize', function () {
      var sharePeople = Ember.get(this, 'target.peopleLower');
      var shareWeight = Ember.get(this, 'shareSize.weightLower');
      var individualPortion = 2;

      // Calculate number of meals reflecting 1/2lb portions
      var numberOfMeals = individualPortion * shareWeight / sharePeople;

      // Always show whole numbers
      numberOfMeals = Math.floor(numberOfMeals);

      // If the math says less that 1 meal, show 1 meal
      return numberOfMeals > 1 ? numberOfMeals : 1;
    })
  });
});