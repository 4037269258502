define('mx/v2/components/x-recipe/credit/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The author of the recipe.
     *
     * @type {String}
     * @default null
     * @memberof Components/XRecipe/Credit
     */
    author: null,
    /**
     * The type of the attribution.
     * E.g. 'Recipe'.
     *
     * @type {String}
     * @default null
     * @memberof Components/XRecipe/Credit
     */
    type: null
  });
});