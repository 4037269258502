define('mx/v2/components/atk-content/credit-link/component', ['exports', 'mx/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * The URI for the component's anchor element.
     *
     * @function _internalLink
     * @returns {string} The 'credit' URI.
     * @memberof Components/AtkContent/CreditLink
     */
    _internalUri: Ember.computed('', function () {
      // Using an environment variable because we don't want to use ATK's GA tracked link in development environments.
      return Ember.get(_environment.default, 'atk.credit_uri');
    }).readOnly()
  });
});