define('mx/components/member-dashboards/referrals/referral-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * The referral code to include in the referral link.
     *
     * @argument code
     * @type {string}
     * @default null
     * @memberof Components/MemberDashboards/Referrals/ReferralLink
     */
    code: null,
    /**
     * The complete referral link.
     *
     * @computed
     * @returns {string} Referral link.
     * @memberof Components/MemberDashboards/Referrals/ReferralLink
     */
    referralLink: Ember.computed('code', function () {
      return 'https://members.waldenlocalmeat.com/sign-up?referral=' + String(Ember.get(this, 'code'));
    })
  });
});