define('mx/v2/components/sign-up-review/steps-provider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     * The Address model to derive validity from.
     *
     * @type {Models/Address}
     * @default null
     * @memberof Components/SignUpReview/StepsProvider
     */
    address: null,
    /**
     * The Member model to derive validity from.
     *
     * @type {Models/Member}
     * @default null
     * @memberof Components/SignUpReview/StepsProvider
     */
    member: null,
    /**
     * The Stripe Token to derive validity from.
     *
     * @type {object}
     * @default null
     * @memberof Components/SignUpReview/StepsProvider
     */
    stripeToken: null,
    /**
     * Whether or not the billing information is valid.
     *
     * @type {boolean}
     * @memberof Components/SignUpReview/StepsProvider
     */
    isBillingValid: Ember.computed.gt('stripeToken.id.length', 0),
    /**
     * Whether or not the delivery information is valid.
     *
     * @type {boolean}
     * @memberof Components/SignUpReview/StepsProvider
     */
    isDeliveryValid: Ember.computed('address.validations.isValid', 'address.zip', function () {
      return Ember.get(this, 'address.validations.isValid') && Ember.get(this, 'address').belongsTo('zip').value() !== null;
    }),
    /**
     * Whether or not the profile information is valid.
     *
     * @type {boolean}
     * @memberof Components/SignUpReview/StepsProvider
     */
    isProfileValid: Ember.computed.alias('member.validations.isValid')
  });
});