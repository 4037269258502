define('mx/components/share-config/meat-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    /**
     *  The selected state of the meat type
     *
     *  @argument
     *  @type {boolean}
     *  @memberof Components/ShareConfig/MeatType
     */
    selected: false,
    /**
     *  The meatType model instance
     *
     *  @argument
     *  @type {Models/MeatType}
     *  @memberof Components/ShareConfig/MeatType
     */
    type: null
  });
});