define('mx/helpers/walden-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.extend({
    waldenPrice: Ember.inject.service('waldenPrice'),

    /**
     *  Compute hook for component accessing a service
     *  Expose service functions, and pass parameters
     *
     *  @function compute
     *  @param {string} entity - the function to access
     *  @param {Array.<*>} rest - remaining destructured args
     *  @returns {number} dollar formatted price
     */
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref),
          entity = _ref2[0],
          rest = _ref2.slice(1);

      var waldenPrice = Ember.get(this, 'waldenPrice');
      var returnValue = 0;
      switch (entity) {
        case 'addon-quantity':
          var _rest = _slicedToArray(rest, 3),
              addon = _rest[0],
              quantity = _rest[1],
              _rest$ = _rest[2],
              addonOffset = _rest$ === undefined ? null : _rest$;

          returnValue = waldenPrice.getAddonPrice(addon, quantity, true);
          if (addonOffset) returnValue = waldenPrice._toDollars(returnValue + addonOffset);
          break;

        case 'share-size':
          var _rest2 = _slicedToArray(rest, 2),
              shareSize = _rest2[0],
              _rest2$ = _rest2[1],
              sizeOffset = _rest2$ === undefined ? null : _rest2$;

          returnValue = waldenPrice.getShareSizePrice(shareSize, true);
          if (sizeOffset) returnValue = waldenPrice._toDollars(returnValue * 100 + sizeOffset);
          break;

        case 'share-total':
          var _rest3 = _slicedToArray(rest, 1),
              shareConfig = _rest3[0];

          returnValue = waldenPrice.getTotalSharePrice(shareConfig, true);
          break;

        case 'share':
          var _rest4 = _slicedToArray(rest, 1),
              sc = _rest4[0];

          returnValue = waldenPrice.getSharePrice(sc, true);
          break;

        case 'to-dollars':
          var _rest5 = _slicedToArray(rest, 2),
              price = _rest5[0],
              priceOffset = _rest5[1];

          returnValue = waldenPrice._toDollars(price);
          if (priceOffset) returnValue = waldenPrice._toDollars(price + priceOffset);
          break;

        default:
          returnValue = 0;
      }
      return returnValue;
    }
  });
});