define('mx/serializers/atk-recipe-tag', ['exports', 'ember-data/serializers/embedded-records-mixin', 'mx/serializers/atk'], function (exports, _embeddedRecordsMixin, _atk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _atk.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      tag: { embedded: 'always', key: 'tag_details' }
    }
  });
});