define('mx/components/account/summary-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      showPane: function showPane() {
        if (this.maxStep == 4) {
          this.set('currentStep', 4);
        }
      },
      join: function join() {
        this.sendAction('trackClick', 'join_complete');
        Ember.run.debounce(this, this.saveAuth, 500);
      }
    },
    saveAuth: function saveAuth() {
      this.sendAction('saveAndAuthenticate');
    }
  });
});