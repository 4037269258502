define('mx/components/share-config/recurring-addons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     *  Array of Addon Model Instances
     *
     *  @argument
     *  @type {Array.<Models/Addon>}
     *  @memberof Components/ShareConfig/RecurringAddons
     */
    addons: null,
    /**
     *  Target Array to Store Selected Addons
     *
     *  @argument
     *  @type {Array.<Models/Addon>}
     *  @memberof Components/ShareConfig/RecurringAddons
     */
    target: null
  });
});