define('mx/controllers/sign-up/share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Model Aliasing
    shareConfig: Ember.computed.alias('model.shareConfig'),
    meatTypes: Ember.computed.alias('model.meatTypes'),
    dietaryRestrictions: Ember.computed.alias('model.dietaryRestrictions'),
    stickyPrefs: Ember.computed.alias('model.stickyPrefs'),
    shareTypes: Ember.computed.alias('model.shareTypes'),
    sharePeoples: Ember.computed.alias('model.sharePeoples'),
    addons: Ember.computed.alias('model.addons'),
    shareFrequencies: Ember.computed.alias('model.shareFrequencies'),
    viewRules: Ember.inject.service('vrShareConfig')
  });
});