define('mx/components/loader-base', ['exports', 'ember-cli-loaders/components/loader-base'], function (exports, _loaderBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loaderBase.default;
    }
  });
});