define('mx/helpers/ctod', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _newArrowCheck(innerThis, boundThis) {
    if (innerThis !== boundThis) {
      throw new TypeError("Cannot instantiate an arrow function");
    }
  }

  /**
   * Converts a number of cents to a dollar formatted string.
   * Subtracts 5 dollars from the end value if the calculation
   * is for a legacy member.
   * @param {Object[]} params - Helper params
   * @param {number} params[].0 - The number of cents
   * @param {boolean} params[].1 - Member is a legacy user
   * @returns {string} Dollar formatted string
   * @memberof helpers
   * @example
   * // returns $1.00
   * ctod(100)
   * @example
   * // returns $75.00
   * ctod(8000, 1)
   */
  var ctod = function (params) {
    _newArrowCheck(undefined, undefined);

    var value = (parseInt(params[0]) / 100).toFixed(2);
    if (params[1]) {
      value = (value - 5).toFixed(2);
    }
    if (value % 1 === 0) {
      value = Number(value).toFixed(0);
    }
    return '$' + value;
  }.bind(undefined);
  exports.default = Ember.Helper.helper(ctod);
});